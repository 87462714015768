import { createUseStyles } from 'react-jss';
import { mixins } from '@/styles/mixins';
import { DEKSTOP_FONT_SIZES, DESKTOP_SIZES_BREAKPOINT, GLOBAL_LETTER_SPACING, MOBILE_FONT_SIZES, } from '@/styles/themes/components';
import { withAlpha } from '@/styles/utils';
const OFFERS_COLUMNS_GAP = 40;
export const useListStyles = createUseStyles((theme) => ({
    offers: {
        margin: '0 15px 15px',
        display: 'flex',
        flexDirection: 'column',
        gap: 12,
        width: 890,
    },
    notMatchingTypeLabel: {
        fontWeight: 400,
        marginTop: 6,
        color: theme.colors.textPrimary,
        fontSize: MOBILE_FONT_SIZES.main,
        [theme.breakpoints.up(DESKTOP_SIZES_BREAKPOINT)]: {
            fontSize: DEKSTOP_FONT_SIZES.main,
        },
    },
    offersWrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: 12,
        paddingTop: 2,
    },
    offer: {
        backgroundColor: theme.colors.cloud,
        borderRadius: 12,
        padding: 12,
        boxShadow: `0px 4px 27px ${withAlpha(theme.colors.sky, 0.1)}`,
    },
    offerHeader: {
        display: 'flex',
        gap: 10,
    },
    offerImage: {
        height: 32,
        width: 32,
        objectFit: 'contain',
    },
    offerTitles: {
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        flexGrow: 1,
    },
    offerTitle: {
        color: theme.colors.sky,
        fontSize: MOBILE_FONT_SIZES.main,
        width: 'max-content',
        [theme.breakpoints.up(DESKTOP_SIZES_BREAKPOINT)]: {
            fontSize: DEKSTOP_FONT_SIZES.main,
        },
    },
    offerDescription: {
        fontSize: DEKSTOP_FONT_SIZES.small,
        color: '#718299',
        width: 'max-content',
        '&[data-warning="true"]': {
            color: theme.colors.warning,
        },
        '& span': {
            color: '#718299',
        },
    },
    offerButtons: {
        height: 24,
        display: 'flex',
        alignItems: 'center',
        gap: 12,
    },
    offerServices: {
        marginTop: 16,
        display: 'flex',
        gap: OFFERS_COLUMNS_GAP,
    },
    offerLimitations: {
        paddingTop: 12,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        fontSize: DEKSTOP_FONT_SIZES.main,
        color: theme.colors.sky,
        paddingLeft: 6,
        '& span': {
            color: theme.colors.sky,
            marginBottom: 2,
        },
    },
    offerLimitation: {
        display: 'flex',
        gap: 5,
        '& svg': {
            flexShrink: 0,
            transform: 'translateY(2px)',
        },
    },
    offerServicesColumn: {
        width: `calc(50% - ${OFFERS_COLUMNS_GAP / 2}px)`,
        height: 'min-content',
        display: 'flex',
        flexDirection: 'column',
    },
    missingSelectedLabel: {
        fontSize: DEKSTOP_FONT_SIZES.main,
        color: theme.colors.textPrimary,
        marginBlock: '8px 4px',
    },
    tag: {
        width: '100%',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        gap: 5,
        padding: '3px 8px 4px',
        borderRadius: 7,
        fontSize: DEKSTOP_FONT_SIZES.main,
        fontWeight: 400,
        letterSpacing: GLOBAL_LETTER_SPACING,
        color: theme.colors.sky,
        userSelect: 'none',
        whiteSpace: 'nowrap',
        transition: 'all 0.15s ease',
        '& span': {
            fontWeight: 700,
            whiteSpace: 'nowrap',
        },
        '& svg': {
            flexShrink: 0,
        },
        '& .missing': {
            width: 11,
            height: 11,
            marginTop: 1,
        },
        '&[data-active="true"]': {
            color: theme.colors.success,
            backgroundColor: withAlpha(theme.colors.success, 0.15),
            '&[data-disadvantage="true"]': {
                color: theme.colors.error,
                backgroundColor: withAlpha(theme.colors.error, 0.15),
            },
        },
    },
    tagDashing: {
        alignSelf: 'flex-end',
        marginBottom: 4,
        flexGrow: 1,
        borderBottom: `1px dashed ${theme.colors.textTertiary}`,
        transition: 'all 0.15s ease',
        '&[data-active="true"]': {
            borderColor: theme.colors.textPrimary,
            opacity: 0.4,
        },
    },
    tagQuestionIcon: {
        flexShrink: 0,
        width: 16,
        height: 16,
        paddingRight: 0.5,
        fontWeight: 500,
        borderRadius: 50,
        color: {
            light: theme.colors.textTertiary,
            dark: withAlpha(theme.colors.textPrimary, 0.8),
        }[theme.mode],
        border: `1px solid currentColor`,
        fontSize: 13,
        ...mixins.centeredContent(),
        userSelect: 'none',
        transition: theme.transition.global,
        cursor: 'pointer',
        '&[data-selected="true"]': {
            color: theme.colors.textPrimary,
            opacity: {
                light: 0.6,
                dark: 1,
            }[theme.mode],
        },
        '&:hover': {
            color: theme.colors.sky,
            opacity: 1,
        },
    },
}), { name: 'comparing-list' });
