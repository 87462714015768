import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@che-ins-ui/button';
import { ChevronIcon } from '@che-ins-ui/icons/ChevronIcon';
import Title from '@/components/common/Title';
import { scrollToTop } from '@/libs/helpers';
import { useCalculationQueryString } from '@/libs/hooks/useCalculationQueryString';
import { RESTORABLE_QS_PARAMS } from '@/libs/constants';
import { useCheTheme } from '@/styles/themes';
import { ResetButton } from './ResetButton';
import { useStyles } from './styles';
const FiltersPanelHeader = () => {
    const classes = useStyles();
    const theme = useCheTheme();
    const queryString = useCalculationQueryString({
        removeParams: [RESTORABLE_QS_PARAMS.mobileFilters],
    });
    return (_jsxs("header", { className: classes.header, children: [_jsxs(Button, { onClick: () => scrollToTop({ behavior: 'auto' }), baseAppearance: "text", passAppearancesToComponent: false, component: Link, to: { search: queryString }, children: [_jsx(ChevronIcon, { stroke: theme.colors.brand }), _jsx("span", { style: { marginLeft: 5 }, children: "\u041D\u0430\u0437\u0430\u0434" })] }), _jsx(Title, { className: classes.headerTitle, tag: "h4", children: "\u0424\u0438\u043B\u044C\u0442\u0440" }), _jsx(ResetButton, {})] }));
};
export default memo(FiltersPanelHeader);
