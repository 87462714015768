import { createUseStyles } from 'react-jss';
import { mixins } from '@/styles/mixins';
import { DEKSTOP_FONT_SIZES, DESKTOP_SIZES_BREAKPOINT, GLOBAL_LETTER_SPACING, MOBILE_FONT_SIZES, } from '@/styles/themes/components';
import { withAlpha } from '@/styles/utils';
import { COMPARING_BREAKPOINT } from './const';
export const useComparingButtonStylesStyles = createUseStyles((theme) => ({
    filtersButton: {
        zIndex: 1,
        border: 'none',
        position: 'relative',
        width: '100%',
        height: 42,
        ...mixins.centeredContent(),
        display: 'none',
        borderRadius: 12,
        backgroundColor: theme.colors.cloud,
        boxShadow: `0px 4px 16px ${withAlpha(theme.colors.black, 0.05)}`,
        padding: 12,
        marginBottom: 8,
        overflow: 'hidden',
        userSelect: 'none',
        fontWeight: 500,
        fontSize: MOBILE_FONT_SIZES.main,
        color: theme.colors.textPrimary,
        [theme.breakpoints.up(DESKTOP_SIZES_BREAKPOINT)]: {
            fontSize: DEKSTOP_FONT_SIZES.main,
        },
        [theme.breakpoints.up(COMPARING_BREAKPOINT)]: {
            display: 'flex',
        },
        '&[data-disabled="true"]': {
            cursor: 'default',
        },
    },
    filtersButtonTitle: {
        color: theme.colors.brand,
        fontSize: 13,
        fontWeight: 500,
        letterSpacing: GLOBAL_LETTER_SPACING,
    },
    filtersButtonNoOffersText: {
        color: theme.colors.textPrimary,
    },
    filtersButtonArrowWrapper: {
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& svg': {
            width: 9,
            transform: 'rotate(180deg)',
            '& path': {
                stroke: theme.colors.brand,
            },
        },
    },
    offersButton: {
        position: 'sticky',
        overflow: 'hidden',
        zIndex: 1,
        bottom: 20,
        width: 432,
        height: 42,
        padding: 12,
        borderRadius: 12,
        cursor: 'pointer',
        alignItems: 'center',
        justifyContent: 'space-between',
        display: 'none',
        background: `linear-gradient(103deg, ${theme.colors.accent} 17.4%, ${theme.colors.brand} 77.89%)`,
        border: 'none',
        [theme.breakpoints.up(COMPARING_BREAKPOINT)]: {
            display: 'flex',
        },
    },
    offersButtonTitle: {
        position: 'absolute',
        color: theme.colors.white,
        fontSize: 13,
        fontWeight: 500,
        letterSpacing: GLOBAL_LETTER_SPACING,
    },
    offersButtonArrowWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& svg': {
            width: 9,
            transform: 'rotate(180deg)',
            '& path': {
                stroke: theme.colors.white,
            },
        },
    },
    logos: {
        position: 'relative',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
    },
    logo: {
        position: 'absolute',
        width: 28,
        height: 28,
        backgroundColor: theme.colors.white,
        borderRadius: 8,
        ...mixins.centeredContent(),
        outline: `2px solid ${theme.colors.tertiary}`,
    },
}), { name: 'comparing' });
