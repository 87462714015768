import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { nanoid } from '@reduxjs/toolkit';
import { tabsActions, ConnectedTabs } from '@che-ins-ui/tabs';
import { isSomeModalOpenSelector, modalActions } from '@che-ins-ui/modal';
import { notify } from '@che-ins-ui/notification';
import { Container } from '@che-ins-ui/container';
import { useScrollbarWidth, useTitle } from 'react-use';
import { calculationIsLoadingSelector, calculationSelector, } from '@/models/calculation/selectors';
import { basketActions } from '@/models/basket';
import { basketLastChangesIdSelector, basketProductIsLoadingSelector, basketProductIsMissingSelector, basketProductSelector, } from '@/models/basket/selectors';
import { useInitCurrency } from '@/libs/hooks/useInitCurrency';
import { ORDER_ID_QUERY_PARAM } from '@/libs/constants';
import { getSharedOrder } from '@/models/process/getSharedOrder';
import { isFrameSelector, requestIsCurrencyInitedSelector, } from '@/models/request/selectors';
import { analyticsActions } from '@/libs/analytics';
import { useIsModalScreenSize } from '@/styles/themes/components/hooks';
import { fetchUpsell } from '@/models/upsell/fetchUpsell';
import { upsellIsLoadingSelector } from '@/models/upsell/selectors';
import { isFormValidatedSelector } from '@/models/forms/selectors';
import { ROUTES } from '../routes';
import { overlayLoaderModalOpen } from '../common/OverlayLoaderModal';
import { ShareLinkModal } from '../common/Hint';
import { EmailConfirmationModal } from '../common/EmailConfirmationModal';
import { useStyles } from './styles';
import { CALCULATION_ERROR_HINT, INSURANCE_TABS_NAME, OFFER_IS_MISSING_MODAL_NAME, TABS_PANELS_NAMES, } from './consts';
import { useActivePanelName, useAsideTabs } from './components/hooks';
import { OfferIsMissingModal } from './components/OfferIsMissingModal';
const Insurance = () => {
    const isModalTooltip = useIsModalScreenSize();
    const isModalTooltipRef = useRef(isModalTooltip);
    isModalTooltipRef.current = isModalTooltip;
    const [search] = useSearchParams();
    const navigate = useNavigate();
    const product = useSelector(basketProductSelector);
    const classes = useStyles();
    const isFormValidated = useSelector(isFormValidatedSelector);
    const basketLastChangesId = useSelector(basketLastChangesIdSelector);
    const isLoadingCalculation = useSelector(calculationIsLoadingSelector);
    const isLoadingProduct = useSelector(basketProductIsLoadingSelector);
    const isLoadingUpsell = useSelector(upsellIsLoadingSelector);
    const isLoadingCalculationRef = useRef(isLoadingCalculation);
    const isSomeModalOpen = useSelector(isSomeModalOpenSelector);
    const scrollbarWidth = useScrollbarWidth();
    isLoadingCalculationRef.current = isLoadingCalculation;
    const isFrame = useSelector(isFrameSelector);
    const productIsMissing = useSelector(basketProductIsMissingSelector);
    const isLoading = isLoadingCalculation || isLoadingProduct;
    const openedOrderId = search.get(ORDER_ID_QUERY_PARAM);
    const isCurrencyInited = useSelector(requestIsCurrencyInitedSelector);
    const { missingOffers, partiallyOffers, matchedOffers } = useSelector(calculationSelector);
    const activePanelName = useActivePanelName();
    const asideTabs = useAsideTabs();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(analyticsActions.fillingPersonalData());
    }, []);
    useEffect(() => {
        const defaultPanelName = activePanelName || asideTabs[0].panelName;
        dispatch(tabsActions.setActiveTab({
            tabsName: INSURANCE_TABS_NAME,
            activePanelName: openedOrderId
                ? asideTabs[1].panelName
                : defaultPanelName,
        }));
    }, [openedOrderId]);
    useEffect(() => {
        if (openedOrderId) {
            dispatch(overlayLoaderModalOpen());
            dispatch(basketActions.setProductIsLoading(true));
            if (isCurrencyInited) {
                dispatch(getSharedOrder(openedOrderId));
            }
        }
    }, [openedOrderId, isCurrencyInited]);
    useInitCurrency();
    useEffect(() => {
        if (!product || isLoading)
            return;
        const newProduct = [...partiallyOffers, ...matchedOffers].find((offer) => {
            return (offer.calculation.productId ===
                product.calculation.productId);
        });
        if (newProduct) {
            dispatch(basketActions.changeProduct({
                product: newProduct,
                lastChangesId: nanoid(),
            }));
        }
        else {
            dispatch(basketActions.setProductIsMissing({
                value: true,
                lastChangesId: nanoid(),
            }));
        }
    }, [missingOffers, partiallyOffers, matchedOffers, product, isLoading]);
    useEffect(() => {
        if (!product && !isLoading) {
            navigate(ROUTES.calculation);
        }
    }, [product, isLoading]);
    useEffect(() => {
        if (isLoadingCalculationRef.current)
            return;
        if (productIsMissing) {
            if (isModalTooltipRef.current) {
                dispatch(modalActions.open({
                    name: OFFER_IS_MISSING_MODAL_NAME,
                }));
            }
            else {
                notify.info({
                    title: CALCULATION_ERROR_HINT.title,
                    message: CALCULATION_ERROR_HINT.message,
                });
            }
        }
    }, [productIsMissing, basketLastChangesId]);
    useTitle('Заполнение данных страхового полиса');
    const asideNavigationTabs = [
        {
            panelName: 'SHOWCASE',
            label: 'Выбор страховки',
        },
        ...asideTabs,
    ];
    const redirectToCalculation = () => navigate(ROUTES.calculation);
    const isDisabledAsideTab = (panelName) => {
        if (isLoadingUpsell) {
            return true;
        }
        switch (panelName) {
            case TABS_PANELS_NAMES.OPTIONS:
                return !isFormValidated;
            case TABS_PANELS_NAMES.UPSELL: {
                const excludedPanelNames = [
                    TABS_PANELS_NAMES.OPTIONS,
                    TABS_PANELS_NAMES.UPSELL,
                ];
                return !excludedPanelNames.includes(activePanelName);
            }
            default:
                return false;
        }
    };
    const handleAsideTabClick = (panelName) => {
        if (panelName === 'SHOWCASE') {
            redirectToCalculation();
        }
        if (panelName === TABS_PANELS_NAMES.UPSELL) {
            dispatch(fetchUpsell());
            dispatch(tabsActions.setActiveTab({
                tabsName: INSURANCE_TABS_NAME,
                activePanelName: asideTabs[2].panelName,
            }));
        }
    };
    return (_jsxs("div", { style: {
            paddingRight: isSomeModalOpen ? scrollbarWidth + 'px' : '0px',
        }, children: [_jsx(ConnectedTabs.TabsContext, { tabsName: INSURANCE_TABS_NAME, defaultActivePanelName: asideTabs[0].panelName, children: _jsxs(Container, { baseAppearance: "page", appearance: isFrame ? 'page-in-frame' : undefined, children: [_jsx(ConnectedTabs.TabsList, { appearance: "verticalTabList", children: asideNavigationTabs.map(({ panelName, label }) => {
                                return (_jsx(ConnectedTabs.Tab, { label: label, panelName: panelName, disabled: isDisabledAsideTab(panelName), isScrollIntoViewOnClick: false, appearance: "verticalTabList", onClick: () => {
                                        handleAsideTabClick(panelName);
                                    } }, panelName));
                            }) }), _jsxs("div", { className: classes.insuranceBlock, children: [asideTabs.map(({ Component, panelName }) => (_jsx(ConnectedTabs.TabPanel, { panelName: panelName, children: _jsx(Component, {}) }, panelName))), _jsx(OfferIsMissingModal, {})] })] }) }), _jsx(ShareLinkModal, {}), _jsx(EmailConfirmationModal, {})] }));
};
export default Insurance;
