import { createAsyncThunk } from '@reduxjs/toolkit';
import { appState } from '@/store';
import { toErrorWithMessage, transformToJson } from '@/libs/helpers';
import { captureError } from '@/libs/helpers/utils';
import { transformUpsell, } from '@/libs/transformers/transformerUpsell';
import { COMPANY_CODES_FOR_MOTO_UPSELL, COUNTRY_CODES_FOR_MOTO_UPSELL, } from '@/components/insurance/components/Upsell/consts';
import { touristsForApiSelector } from '../forms/selectors';
import { getNextMedicineSum } from './helpers';
import { upsellActions } from './index';
export const generateUpsellOffer = async (payload, nextActiveRest, nextMedicineSum, nextMoto) => {
    const { dispatch, getState } = appState;
    const { basket } = getState();
    const basketCompanyId = basket.product?.company.id;
    const basketPriceRub = basket.product?.calculation.priceRub;
    if (!basketCompanyId || !basketPriceRub) {
        return;
    }
    const { calculations } = await fetch(`${__VZR_API_URL__}calculate`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
    }).then(transformToJson);
    const updatedOffer = calculations.find((offer) => offer.companyId === basketCompanyId);
    if (updatedOffer?.priceRub) {
        dispatch(upsellActions.addOffer({
            activeRest: nextActiveRest,
            medicine: nextMedicineSum,
            moto: nextMoto,
            price: updatedOffer?.priceRub,
            productId: updatedOffer?.productId,
        }));
    }
};
export const fetchUpsell = createAsyncThunk('upsell/fetchUpsell', async (_, thunkApi) => {
    try {
        thunkApi.dispatch(upsellActions.reset());
        thunkApi.dispatch(upsellActions.setIsLoading(true));
        const state = thunkApi.getState();
        const tourists = touristsForApiSelector(state);
        const { request, basket } = state;
        const basketMedicineSum = basket.product?.calculation.serviceProduct.medicine;
        const { sports: chosenSports, countries, countryGroups } = request;
        const basketCompanyCode = basket.product?.company?.code;
        const areEmptyChosenSports = Array.isArray(chosenSports) && chosenSports.length === 0;
        const shouldFetchUpsellMoto = areEmptyChosenSports &&
            basketCompanyCode &&
            COMPANY_CODES_FOR_MOTO_UPSELL.includes(basketCompanyCode) &&
            [...countries, ...countryGroups].some((code) => COUNTRY_CODES_FOR_MOTO_UPSELL.includes(code));
        const nextMedicineSum = basketMedicineSum && getNextMedicineSum(basketMedicineSum);
        const shouldFetchUpsellActiveRest = areEmptyChosenSports;
        const upsellOptions = [
            {
                nextActiveRest: true,
                nextMedicineSum: basketMedicineSum,
                nextMoto: false,
                shouldFetch: shouldFetchUpsellActiveRest,
            },
            {
                nextActiveRest: false,
                nextMedicineSum: nextMedicineSum,
                nextMoto: false,
                shouldFetch: basketMedicineSum && nextMedicineSum,
            },
            {
                nextActiveRest: false,
                nextMedicineSum: basketMedicineSum,
                nextMoto: true,
                shouldFetch: shouldFetchUpsellMoto,
            },
            {
                nextActiveRest: true,
                nextMedicineSum: nextMedicineSum,
                nextMoto: false,
                shouldFetch: shouldFetchUpsellActiveRest && nextMedicineSum,
            },
            {
                nextActiveRest: false,
                nextMedicineSum: nextMedicineSum,
                nextMoto: true,
                shouldFetch: nextMedicineSum && shouldFetchUpsellMoto,
            },
            {
                nextActiveRest: true,
                nextMedicineSum: basketMedicineSum,
                nextMoto: true,
                shouldFetch: shouldFetchUpsellActiveRest && shouldFetchUpsellMoto,
            },
            {
                nextActiveRest: true,
                nextMedicineSum: nextMedicineSum,
                nextMoto: true,
                shouldFetch: shouldFetchUpsellActiveRest &&
                    nextMedicineSum &&
                    shouldFetchUpsellMoto,
            },
        ];
        await Promise.all(upsellOptions.map(async ({ nextActiveRest, nextMedicineSum, nextMoto, shouldFetch, }) => {
            try {
                if (shouldFetch) {
                    const nextUpsellRequest = transformUpsell({
                        request,
                        tourists,
                        nextActiveRest,
                        nextMedicineSum: nextMedicineSum,
                        nextMoto,
                    });
                    await generateUpsellOffer(nextUpsellRequest, nextActiveRest, nextMedicineSum, nextMoto);
                }
            }
            catch (err) {
                captureError(toErrorWithMessage(err), 'generateUpsellOffer');
            }
        }));
    }
    catch (error) {
        captureError(toErrorWithMessage(error), 'upsell/fetchUpsell');
        console.error(error);
        throw new Error('upsell/fetchUpsell');
    }
    finally {
        thunkApi.dispatch(upsellActions.setIsLoading(false));
    }
});
