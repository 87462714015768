import { createUseStyles } from 'react-jss';
import { mixins } from '@/styles/mixins';
import { DEKSTOP_FONT_SIZES, DESKTOP_SIZES_BREAKPOINT, MOBILE_FONT_SIZES, } from '@/styles/themes/components';
export const useStyles = createUseStyles((theme) => ({
    hintQuestionMark: {
        ...mixins.centeredContent(),
        ...mixins.square(16),
        borderRadius: '100%',
        background: 'transparent',
        color: {
            light: theme.colors.textTertiary,
            dark: theme.colors.textPrimary,
        }[theme.mode],
        border: '1px solid currentColor',
        fontSize: theme.rem(13),
        userSelect: 'none',
        minWidth: '16px',
        cursor: 'pointer',
        marginLeft: '5px',
        '&:hover': {
            color: theme.colors.white,
            border: `1px solid ${theme.colors.brand}`,
            background: theme.colors.brand,
        },
    },
    hintLabel: {
        fontWeight: 400,
        color: theme.colors.brand,
        display: 'inline-block',
        fontSize: MOBILE_FONT_SIZES.small,
        [theme.breakpoints.up(DESKTOP_SIZES_BREAKPOINT)]: {
            fontSize: DEKSTOP_FONT_SIZES.small,
        },
    },
    modalTitleIcon: {
        marginBottom: '20px',
    },
    modalTitle: {
        fontWeight: 700,
        fontFamily: theme.font.secondary,
        letterSpacing: '0.45px',
        marginBottom: '6px',
        color: theme.colors.sky,
        fontSize: MOBILE_FONT_SIZES.hintModalTitle,
        [theme.breakpoints.up('xl')]: {
            fontSize: DEKSTOP_FONT_SIZES.hintModalTitle,
        },
    },
    modalHintText: {
        fontSize: MOBILE_FONT_SIZES.main,
        [theme.breakpoints.up(DESKTOP_SIZES_BREAKPOINT)]: {
            fontSize: DEKSTOP_FONT_SIZES.main,
        },
    },
    services: {
        display: 'flex',
        gap: '8px',
        justifyContent: 'center',
    },
    companyNotFoundImage: {
        width: 101,
        height: 122,
        marginBottom: 28,
    },
    calculationExpiredImage: {
        width: 175,
        height: 130,
        marginBottom: 28,
    },
    serviceInfo: {
        width: 90,
        height: 138,
        marginBottom: 28,
    },
    moreExpensiveTomorrowImage: {
        width: 101,
        height: 122,
        marginBottom: 28,
    },
    missingOptionsImage: {
        width: 150,
        height: 112,
        marginBottom: 28,
    },
}), { name: 'hint' });
