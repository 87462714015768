import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox } from '@/components/common/checkbox';
import { requestActions } from '@/models/request';
import { requestSelector } from '@/models/request/selectors';
import { Hint } from '../common/Hint';
import { MultiValueAccordion } from './MultiValueAccordion';
import { useStyles } from './styles';
const Label = ({ code, name, description, valueAfterAddService, withServiceInfoImage, }) => {
    const classes = useStyles();
    return (_jsxs(_Fragment, { children: [_jsx("span", { className: classes.serviceName, children: name }), _jsx(Hint, { title: name, id: code, actionType: "toggle-service", serviceCode: code, value: valueAfterAddService, postfixModalName: "-filters", withServiceInfoImage: withServiceInfoImage, children: description })] }));
};
export const FiltersList = ({ list, withServiceInfoImage, }) => {
    const dispatch = useDispatch();
    const { services } = useSelector(requestSelector);
    const handleChangeService = (code) => (value) => {
        dispatch(requestActions.setServices({ [code]: Number(value) }));
    };
    return (_jsx(_Fragment, { children: list.map(({ code, name, description, serviceValues, subDescription, }) => {
            const isChecked = !!services[code];
            const isMulti = serviceValues.length > 2;
            const label = (_jsx(Label, { code: code, name: name, description: description, valueAfterAddService: isMulti ? serviceValues[0] : 1, withServiceInfoImage: withServiceInfoImage }));
            if (isMulti) {
                return (_jsx(MultiValueAccordion, { code: code, name: name, isChecked: isChecked, handleChangeService: handleChangeService, serviceValues: serviceValues, label: label, subDescription: subDescription }, code));
            }
            return (_jsx(Checkbox, { value: isChecked, onChange: handleChangeService(code), label: label, appearance: "filters" }, code));
        }) }));
};
