import { createUseStyles } from 'react-jss';
import { MOBILE_FONT_SIZES, DESKTOP_SIZES_BREAKPOINT, DEKSTOP_FONT_SIZES, } from '@/styles/themes/components';
export const useStyles = createUseStyles((theme) => ({
    tabTitle: {
        marginBottom: '2px',
        fontSize: MOBILE_FONT_SIZES.main,
        color: theme.colors.sky,
        [theme.breakpoints.up(DESKTOP_SIZES_BREAKPOINT)]: {
            fontSize: DEKSTOP_FONT_SIZES.main,
        },
    },
    tabText: {
        color: theme.colors.textPrimary,
        fontSize: MOBILE_FONT_SIZES.main,
        [theme.breakpoints.up(DESKTOP_SIZES_BREAKPOINT)]: {
            fontSize: DEKSTOP_FONT_SIZES.main,
        },
    },
    downloadLink: {
        display: 'flex',
        fontSize: MOBILE_FONT_SIZES.main,
        '& span > svg > rect': {
            fill: theme.colors.brand,
        },
        [theme.breakpoints.up(DESKTOP_SIZES_BREAKPOINT)]: {
            fontSize: DEKSTOP_FONT_SIZES.main,
        },
    },
    downloadLinkIconWrapper: {
        marginRight: '12px',
    },
    downloadLinkText: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: '2px 0px 4px 0px',
    },
    downloadLinkFilename: {
        color: theme.colors.sky,
        [theme.breakpoints.down('sm')]: {
            marginTop: -3,
        },
    },
    downloadLinkDownloadText: {
        color: theme.colors.brand,
    },
}), { name: 'product-tabs' });
