import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { memo } from 'react';
import { useSelector } from 'react-redux';
import { requestSelector } from '@/models/request/selectors';
import { MustReadNepal } from './MustReadNepal';
import { MustReadRussia } from './MustReadRussia';
import { MustReadIsrael, MustReadLebanon } from './MustReadIsraelLebanon';
const countryComponents = {
    nepal: MustReadNepal,
    russia: MustReadRussia,
    israel: MustReadIsrael,
    lebanon: MustReadLebanon,
};
export const MustReadInfo = memo(() => {
    const { countries } = useSelector(requestSelector);
    return (_jsx(_Fragment, { children: countries.map((country) => {
            const Component = countryComponents[country];
            return Component ? _jsx(Component, {}, country) : null;
        }) }));
});
