import { createUseStyles } from 'react-jss';
import { HEADER_HEIGHT } from '@/components/common/Header/styles';
import { withAlpha } from '@/styles/utils';
import { DEKSTOP_FONT_SIZES, DESKTOP_FILTERS_BREAKPOINT, DESKTOP_SIZES_BREAKPOINT, BLOCK_BOX_SHADOW, MOBILE_FONT_SIZES, } from '@/styles/themes/components';
export const FILTERS_PANEL_BOTTOM = 40;
export const useStyles = createUseStyles((theme) => ({
    '@keyframes loading': {
        from: {
            width: '0%',
        },
        to: {
            width: '100%',
        },
    },
    wrapper: {
        paddingTop: `${HEADER_HEIGHT}px`,
        flexShrink: 0,
        [theme.breakpoints.up(DESKTOP_FILTERS_BREAKPOINT)]: {
            paddingTop: '0px',
            width: '320px',
        },
    },
    header: {
        height: '52px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        position: 'fixed',
        top: '0px',
        left: '0px',
        right: '0px',
        background: theme.colors.cloud,
        zIndex: 1,
        [theme.breakpoints.up(DESKTOP_FILTERS_BREAKPOINT)]: {
            display: 'none',
        },
    },
    headerTitle: {
        fontSize: MOBILE_FONT_SIZES.title,
        color: theme.colors.sky,
        [theme.breakpoints.up(DESKTOP_SIZES_BREAKPOINT)]: {
            fontSize: DEKSTOP_FONT_SIZES.title,
        },
    },
    filtersSkeletonDesktop: {
        display: 'none',
        justifyContent: 'center',
        alignItems: 'center',
        width: '320px',
        height: '600px',
        borderRadius: '10px',
        background: theme.colors.cloud,
        flexDirection: 'column',
        '& > span': {
            fontSize: DEKSTOP_FONT_SIZES.main,
            color: theme.colors.brand,
        },
        boxShadow: BLOCK_BOX_SHADOW,
        [theme.breakpoints.up(DESKTOP_FILTERS_BREAKPOINT)]: {
            display: 'flex',
        },
    },
    headerSkeletonMobile: {
        padding: '15px',
    },
    filtersSkeletonMobile: {
        display: 'flex',
        flexDirection: 'column',
        padding: '15px',
        background: theme.colors.cloud,
        overflow: 'hidden',
        gap: '20px',
        [theme.breakpoints.up(DESKTOP_FILTERS_BREAKPOINT)]: {
            display: 'none',
        },
    },
    filtersSkeletonMobileRow: {
        overflow: 'hidden',
        borderRadius: '7px',
    },
    loader: {
        width: '190px',
        height: '2px',
        marginBottom: '10px',
        background: theme.colors.brandTertiary,
        '& > div': {
            height: '2px',
            background: theme.colors.brand,
            animation: '$loading 1.5s 0s infinite',
        },
    },
    blockWrapper: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        background: theme.colors.cloud,
        marginBottom: '8px',
        borderRadius: '12px',
        '&:last-child': {
            marginBottom: '0px',
        },
        '& > *': {
            padding: '6px 0',
            '&:first-of-type': {
                borderBottom: `1px solid ${withAlpha(theme.colors.textPrimary, 0.2)}`,
                padding: 12,
            },
        },
        [theme.breakpoints.up(DESKTOP_FILTERS_BREAKPOINT)]: {
            boxShadow: BLOCK_BOX_SHADOW,
            maxWidth: 320,
        },
    },
    firstBlockWrapper: {
        borderRadius: '0px 0px 12px 12px',
        [theme.breakpoints.up(DESKTOP_FILTERS_BREAKPOINT)]: {
            borderRadius: '12px',
        },
    },
    lastBlockWrapper: {
        marginBottom: '0px',
        '&:after': {
            content: '""',
            display: 'block',
            height: '55px',
            background: theme.colors.cloud,
        },
        [theme.breakpoints.up(DESKTOP_FILTERS_BREAKPOINT)]: {
            marginBottom: '8px',
            '&:after': {
                content: '""',
                display: 'none',
            },
        },
    },
    lastBlockChildren: {
        paddingBottom: '14px',
    },
    medicineBlockWrapper: {},
    medicineBlockHeader: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    medicineBlockHintList: {
        padding: '0px 0 1.5px 1.5px',
        display: 'flex',
        flexDirection: 'column',
        gap: '4px',
        [theme.breakpoints.up('xl')]: {
            padding: '1.5px 0 1.5px 1.5px',
            gap: '5px',
        },
    },
    medicineBlockHintListItem: {
        textAlign: 'left',
        display: 'flex',
        fontSize: MOBILE_FONT_SIZES.small,
        [theme.breakpoints.up(DESKTOP_SIZES_BREAKPOINT)]: {
            fontSize: DEKSTOP_FONT_SIZES.small,
        },
    },
    medicineHintCheckIcon: {
        marginRight: 7.5,
        marginTop: '3px',
        flexShrink: 0,
        '& path': {
            fill: theme.colors.success,
            [theme.breakpoints.up('lg')]: {
                fill: theme.colors.white,
            },
        },
    },
    blockTitle: {
        fontWeight: 500,
        fontSize: MOBILE_FONT_SIZES.main,
        color: theme.colors.sky,
        [theme.breakpoints.up(DESKTOP_SIZES_BREAKPOINT)]: {
            fontSize: DEKSTOP_FONT_SIZES.main,
        },
    },
    subDescription: {
        color: theme.colors.textPrimary,
        fontSize: MOBILE_FONT_SIZES.small,
        [theme.breakpoints.up(DESKTOP_SIZES_BREAKPOINT)]: {
            fontSize: DEKSTOP_FONT_SIZES.small,
        },
    },
    serviceName: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    accordionTitleText: {
        display: 'flex',
        alignItems: 'center',
        fontSize: MOBILE_FONT_SIZES.main,
        color: theme.colors.sky,
        [theme.breakpoints.up(DESKTOP_SIZES_BREAKPOINT)]: {
            fontSize: DEKSTOP_FONT_SIZES.main,
        },
    },
    accordionServicesCount: {
        marginLeft: '5px',
        color: theme.colors.brand,
    },
}), { name: 'filter-layout' });
