import { createUseStyles } from 'react-jss';
export const useStyles = createUseStyles((theme) => ({
    buttonWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 4,
        margin: '-4px 0',
        position: 'relative',
        cursor: 'pointer',
        border: 'none',
        backgroundColor: 'transparent',
        borderRadius: 4,
        '-webkit-tap-highlight-color': 'transparent',
        '-webkit-user-select': 'none',
        userSelect: 'none',
        '&:active': {
            '& $childWrapper': {
                transform: 'scale(0.97)',
            },
        },
        '&:hover': {
            '& $childWrapper': {
                transform: 'scale(1.08)',
            },
        },
    },
    childWrapper: {
        transition: theme.transition.global,
    },
    tooltipWrapper: {
        display: 'none',
        position: 'absolute',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.up('lg')]: {
            display: 'flex',
        },
    },
    tooltip: {
        pointerEvents: 'none',
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        top: -18,
        '&[data-position="bottom"]': {
            top: 18,
        },
    },
    tooltipBody: {
        position: 'absolute',
        top: 0,
        whiteSpace: 'nowrap',
        fontSize: 14,
        borderRadius: 4,
        padding: '2px 6px 3px',
        backgroundColor: theme.colors.sky,
        color: theme.colors.cloud,
        transform: 'translateY(-23px)',
        '&[data-position="bottom"]': {
            transform: 'translateY(-1px)',
        },
    },
    tooltipArrow: {
        position: 'absolute',
        backgroundColor: theme.colors.sky,
        width: 10,
        height: 10,
        borderRadius: '0px 100px 0px 24px',
        transform: 'rotate(-45deg) skew(2deg, 2deg)',
        '&[data-position="bottom"]': {
            transform: 'rotate(135deg) skew(2deg, 2deg)',
        },
    },
}), { name: 'button-with-tooltip' });
