import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { modalActions } from '@che-ins-ui/modal';
import { useTitle } from 'react-use';
import Page from '@/components/common/Page';
import FiltersPanel from '@/components/filters/Filters';
import Showcase, { MoreExpensiveTomorrowAlert } from '@/components/Showcase';
import { calculationLastRequestIdSelector } from '@/models/calculation/selectors';
import { CalculationExpiredModal, CALCULATION_EXPIRED_MODAL_NAME, ShareLinkModal, } from '@/components/common/Hint';
import { EXPIRES_CALCULATION_TIME, IS_DEVELOPMENT } from '@/libs/constants';
import { useIsMobileFilters } from '@/libs/hooks';
import { getCalculationQueryString } from '@/libs/helpers/utils';
import { useInitCurrency } from '@/libs/hooks/useInitCurrency';
import { isShowExpiredCalculationModalSelector } from '@/models/request/selectors';
import { MoreOffersTomorrowHint } from '@/components/Showcase/components/MoreOffersTomorrowHint';
import { formsActions } from '@/models/forms';
const CalculationPage = () => {
    const dispatch = useDispatch();
    const isShowExpiredCalculationModal = useSelector(isShowExpiredCalculationModalSelector);
    const calculationLastRequestId = useSelector(calculationLastRequestIdSelector);
    const isMobileFilters = useIsMobileFilters();
    const state = useSelector((state) => state, () => true);
    const [_, setSearch] = useSearchParams();
    useEffect(() => {
        const queryString = getCalculationQueryString(state);
        setSearch(queryString);
        dispatch(formsActions.setFormDirty(false));
    }, []);
    useInitCurrency();
    useEffect(() => {
        if (IS_DEVELOPMENT || !isShowExpiredCalculationModal)
            return;
        const timer = setTimeout(() => {
            dispatch(modalActions.open({ name: CALCULATION_EXPIRED_MODAL_NAME }));
        }, EXPIRES_CALCULATION_TIME);
        return () => {
            timer && clearTimeout(timer);
        };
    }, [calculationLastRequestId, isShowExpiredCalculationModal]);
    useTitle('Расчёт стоимости страховки');
    if (isMobileFilters) {
        return _jsx(FiltersPanel, {});
    }
    return (_jsxs("div", { children: [_jsx(Page, { aside: _jsx(FiltersPanel, {}), main: _jsx(Showcase, {}) }), _jsx(CalculationExpiredModal, {}), _jsx(ShareLinkModal, {}), _jsx(MoreExpensiveTomorrowAlert, {}), _jsx(MoreOffersTomorrowHint, {})] }));
};
export default CalculationPage;
