import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { useDispatch } from 'react-redux';
import { motion } from 'framer-motion';
import { CheckIcon } from '@che-ins-ui/icons/CheckIcon';
import { CrossIcon } from '@che-ins-ui/icons/CrossIcon';
import { useCheTheme } from '@/styles/themes';
import { comparingActions } from '@/models/comparing';
import { ComparingTagTooltip } from './ComparingTagTooltip';
import { useListStyles } from './listStyles';
export const ComparingListOfferServiceTag = memo(({ service, postfix, description, isDisadvantage, isActive, isClicked, appearsFromBelow, isHidden, }) => {
    const theme = useCheTheme();
    const dispatch = useDispatch();
    const classes = useListStyles();
    const onHover = () => dispatch(comparingActions.setHoveredService(service));
    const onLeave = () => dispatch(comparingActions.setHoveredService(null));
    const onClick = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setTimeout(() => dispatch(comparingActions.toggleServiceToClicked(service)));
    };
    const variants = {
        hidden: {
            opacity: 0,
            scale: 0,
            height: 0,
            y: appearsFromBelow ? 5 : 0,
            padding: '0px 8px 0px',
        },
        visible: {
            opacity: 1,
            scale: 1,
            height: 25,
            y: 0,
            padding: '3px 8px 4px',
        },
    };
    const isHightlighted = isActive || isClicked;
    return (_jsxs(motion.div, { variants: variants, initial: "hidden", animate: isHidden ? 'hidden' : 'visible', whileTap: { scale: 0.98 }, transition: {
            duration: 0.05,
            type: 'ease',
        }, className: classes.tag, "data-active": isActive || isClicked, onPointerEnter: onHover, onPointerLeave: onLeave, onClick: onClick, "data-disadvantage": isDisadvantage, "data-hovered": isActive, children: [!isDisadvantage ? (_jsx(CheckIcon, { fill: theme.colors.success })) : (_jsx(CrossIcon, { fill: theme.colors.error, className: "missing" })), service, description && (_jsx(ComparingTagTooltip, { service: service, description: description, selected: isHightlighted })), _jsx("div", { className: classes.tagDashing, "data-active": isHightlighted }), _jsx("span", { children: postfix })] }));
});
