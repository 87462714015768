import { createElement as _createElement } from "react";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useEffect, useMemo, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMeasure } from 'react-use';
import { motion, AnimatePresence } from 'framer-motion';
import classNames from 'classnames';
import { Button } from '@che-ins-ui/button';
import { Input } from '@che-ins-ui/input';
import { Checkbox } from '@/components/common/checkbox';
import { isInsurerInRussianSelector } from '@/models/basket/selectors';
import { MAX_EMAILS_AMOUNT } from '@/libs/constants';
import { Hint } from '@/components/common/Hint';
import { InputSuggestions } from '@/components/common/InputSuggestions';
import { InputError } from '@/components/common/InputError';
import { InputDropdpownPostfix } from '@/components/common/InputSuggestions/InputDropdpownPostfix';
import { getInsurerSuggestionsInRussian, getInsurerSuggestions, } from '@/models/notebook/helpers';
import { personsSelector } from '@/models/notebook/selectors';
import { openNotebookForm } from '@/models/notebook/openNotebookForm';
import { notebookActions } from '@/models/notebook';
import { emailsSelector, insurerFormsSelector, isFormDirtySelector, touristsFormsSelector, } from '@/models/forms/selectors';
import { formsActions } from '@/models/forms';
import { InsurerField, NameField, } from '@/models/forms/interface';
import { setInsurerName } from '@/models/forms/setInsurerName';
import { setInsurerBirthday } from '@/models/forms/setInsurerBirthday';
import { formatNameOnBlur } from '@/models/forms/helpers';
import { inputLabels, inputNames, placeholders, whoCanBayHintText, } from '../consts';
import { InsurerEmails } from './InsurerEmails';
import { useIsMobileInputSuggestions, useIsMobileViewInsuranceForm, } from './hooks';
import { FormFieldPhoneInput } from './FormFieldPhoneInput';
import { useStyles } from './styles';
const animationTransition = {
    transition: {
        layout: { duration: 0.15 },
    },
};
export const Insurer = memo(() => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const insurerNameRef = useRef(null);
    const [ref, { height }] = useMeasure();
    const isInsurerInRussian = useSelector(isInsurerInRussianSelector);
    const isMobileFormView = useIsMobileViewInsuranceForm();
    const isMobileSuggestionsView = useIsMobileInputSuggestions();
    const insurer = useSelector(insurerFormsSelector);
    const tourists = useSelector(touristsFormsSelector);
    const emails = useSelector(emailsSelector);
    const persons = useSelector(personsSelector);
    const isFormDirty = useSelector(isFormDirtySelector);
    const { hasSecondNameRu, fieldsDirty, fieldsErrors } = insurer;
    const suggestions = useMemo(() => isInsurerInRussian
        ? getInsurerSuggestionsInRussian(persons, insurer)
        : getInsurerSuggestions(persons, insurer, tourists), [persons, tourists, isInsurerInRussian, insurer]);
    const hasSuggestions = suggestions.length > 0;
    const [hoveredSuggestion, setHoveredSuggestion] = useState(null);
    const [areSuggestionsShown, setAreSuggestionsShown] = useState(false);
    const [previewSuccess, setPreviewSuccess] = useState(false);
    const getFormError = (field) => !hoveredSuggestion &&
        (isFormDirty || fieldsDirty[field]) &&
        fieldsErrors[field];
    const formErrors = {
        lastName: getFormError(InsurerField.lastName),
        firstName: getFormError(InsurerField.firstName),
        lastNameRu: getFormError(InsurerField.lastNameRu),
        firstNameRu: getFormError(InsurerField.firstNameRu),
        secondNameRu: hasSecondNameRu && getFormError(InsurerField.secondNameRu),
        birthday: getFormError(InsurerField.birthday),
    };
    const handleSuggestionClick = (suggestion) => {
        setHoveredSuggestion(null);
        setAreSuggestionsShown(false);
        let nameFields = [
            InsurerField.lastName,
            InsurerField.firstName,
        ];
        if (suggestion.fromNotebook) {
            nameFields = [
                ...nameFields,
                InsurerField.lastNameRu,
                InsurerField.firstNameRu,
                InsurerField.secondNameRu,
            ];
            dispatch(notebookActions.setPersonIdUsedForInsurer(suggestion._uuid));
        }
        nameFields.forEach((field) => {
            setFieldDirty(field);
            setFieldNameValue(field, suggestion[field]);
        });
        setBirthday(suggestion.birthday);
        const hasSecondNameRu = suggestion.secondNameRu
            ? true
            : !suggestion.firstNameRu || !suggestion.lastNameRu;
        dispatch(formsActions.setInsurerHasSecondNameRu(Boolean(hasSecondNameRu)));
        setPreviewSuccess(true);
        setTimeout(() => {
            setPreviewSuccess(false);
        }, 200);
    };
    const handleSuggestionHover = (suggestion) => {
        if (isMobileSuggestionsView) {
            return;
        }
        setHoveredSuggestion(suggestion);
    };
    const handleSuggestionBlur = () => {
        setHoveredSuggestion(null);
    };
    useEffect(() => {
        handleSuggestionBlur();
    }, []);
    const getInputsAppearance = () => {
        if (hoveredSuggestion) {
            return 'preview';
        }
        if (previewSuccess) {
            return 'preview-success';
        }
    };
    const appearance = getInputsAppearance();
    const setBirthday = (value) => dispatch(setInsurerBirthday(value));
    const rootAnimation = useMemo(() => (height ? { height: height + 30 } : {}), [height]);
    const setFieldDirty = (field) => dispatch(formsActions.setInsurerFieldDirty(field));
    const setFieldNameValue = (field, value) => dispatch(setInsurerName({ field, value }));
    const getInputField = (field) => {
        const isBirthdayField = field === InsurerField.birthday;
        const isSecondNameField = field === InsurerField.secondNameRu;
        const withSuggestions = [
            NameField.lastName,
            NameField.lastNameRu,
        ].includes(field);
        const onChange = (value) => isBirthdayField
            ? setBirthday(value)
            : setFieldNameValue(field, value);
        const className = field === InsurerField.secondNameRu
            ? classes.secondNameWrapper
            : classes.insurerFieldWrapper;
        const getValue = () => {
            if (hoveredSuggestion) {
                return hoveredSuggestion[field];
            }
            if (isSecondNameField && !hasSecondNameRu) {
                return '';
            }
            return insurer[field];
        };
        const getIsDisabled = () => {
            if (hoveredSuggestion) {
                return !hoveredSuggestion[field];
            }
            return isSecondNameField && !hasSecondNameRu;
        };
        const getName = () => {
            if (withSuggestions && hasSuggestions) {
                return;
            }
            return inputNames[field];
        };
        return (_jsxs("div", { className: className, ref: withSuggestions ? insurerNameRef : undefined, children: [_jsx(Input, { value: getValue(), onChange: hoveredSuggestion ? () => { } : onChange, name: getName(), label: inputLabels[field], shouldFitContent: true, placeholder: placeholders[field], appearance: appearance, invalid: Boolean(formErrors[field]), disabled: getIsDisabled(), postfix: withSuggestions ? (_jsx(InputDropdpownPostfix, { isShown: hasSuggestions, isActive: areSuggestionsShown, onClick: () => setAreSuggestionsShown((prev) => !prev) })) : undefined, onFocus: () => {
                        if (withSuggestions && !insurer[field]?.trim()) {
                            setAreSuggestionsShown(true);
                        }
                    }, onBlur: () => {
                        setFieldDirty(field);
                        if (!isBirthdayField) {
                            onChange(formatNameOnBlur(insurer[field]));
                        }
                    } }), _jsx(InputError, { error: formErrors[field] }), withSuggestions && (_jsx(InputSuggestions, { ref: insurerNameRef, suggestions: suggestions, onHover: handleSuggestionHover, onBlur: handleSuggestionBlur, onClick: handleSuggestionClick, areSuggestionsShown: areSuggestionsShown, hideSuggestions: () => setAreSuggestionsShown(false), onEdit: (personId) => {
                        dispatch(openNotebookForm({
                            personId,
                            isFromInsurer: true,
                        }));
                    }, isInRussian: isInsurerInRussian })), isSecondNameField && (_jsx("div", { className: classes.hasSecondNameCheckbox, children: _jsx(Checkbox, { value: hoveredSuggestion
                            ? !hoveredSuggestion.secondNameRu
                            : !hasSecondNameRu, onChange: () => {
                            dispatch(formsActions.setInsurerHasSecondNameRu(!hasSecondNameRu));
                        }, appearance: appearance, label: "\u041D\u0435\u0442 \u043E\u0442\u0447\u0435\u0441\u0442\u0432\u0430" }) }))] }));
    };
    return (_jsx(motion.div, { layout: true, initial: rootAnimation, animate: rootAnimation, className: classes.root, "data-insurer": true, transition: { duration: 0.15 }, children: _jsx("div", { ref: ref, children: _jsxs(AnimatePresence, { initial: false, mode: "popLayout", children: [_createElement(motion.div, { layout: true, ...animationTransition, className: classes.header, key: "header" },
                        _jsx("h3", { className: classes.title, children: "\u041F\u043E\u043A\u0443\u043F\u0430\u0442\u0435\u043B\u044C" }),
                        _jsx(Hint, { id: "whoCanBay", label: "\u0422\u0440\u0435\u0431\u043E\u0432\u0430\u043D\u0438\u044F \u043A \u043F\u043E\u043A\u0443\u043F\u0430\u0442\u0435\u043B\u044E", title: "\u0422\u0440\u0435\u0431\u043E\u0432\u0430\u043D\u0438\u044F \u043A \u043F\u043E\u043A\u0443\u043F\u0430\u0442\u0435\u043B\u044E", actionType: "agree", children: whoCanBayHintText })), _jsxs(motion.div, { layout: "position", ...animationTransition, className: classNames(classes.insurer, {
                            [classes.insurerWithoutSecondName]: !isInsurerInRussian,
                        }), children: [isInsurerInRussian ||
                                getInputField(InsurerField.lastName), isInsurerInRussian ||
                                getInputField(InsurerField.firstName), isInsurerInRussian &&
                                getInputField(InsurerField.lastNameRu), isInsurerInRussian &&
                                getInputField(InsurerField.firstNameRu), isInsurerInRussian &&
                                getInputField(InsurerField.secondNameRu), getInputField(InsurerField.birthday), _jsx(FormFieldPhoneInput, {}), _jsx(InsurerEmails, {})] }), emails.length < MAX_EMAILS_AMOUNT && (_createElement(motion.div, { layout: true, className: classes.addTouristButtonWrapper, initial: { opacity: 0.8, scale: 0.4 }, animate: { opacity: 1, scale: 1 }, exit: { opacity: 0, scale: 0.3 }, ...animationTransition, key: "button" },
                        _jsx(Button, { onClick: () => dispatch(formsActions.addEmail()), baseAppearance: "toggle-item-forms-page", appearance: "secondary", shouldFitContent: isMobileFormView, children: "\u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C e-mail" })))] }) }) }));
});
