import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { apiIsServiceStatusLoadingSelector, isSalesWorkingSelector, } from '@/models/api/selectors';
import { Footer } from '../common/Footer';
import AppLoader from '../common/AppLoader';
import { NotWorking } from '../TechnicalPages';
import { Header } from '../common/Header';
export const SuccessLayout = () => {
    const isSalesWorking = useSelector(isSalesWorkingSelector);
    const apiIsServiceStatusLoading = useSelector(apiIsServiceStatusLoadingSelector);
    if (apiIsServiceStatusLoading) {
        return _jsx(AppLoader, {});
    }
    if (!isSalesWorking) {
        return _jsx(NotWorking, {});
    }
    return (_jsxs("div", { children: [_jsx(Header, { isHideable: false }), _jsx(Outlet, {}), _jsx(Footer, {})] }));
};
