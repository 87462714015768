import { jsx as _jsx } from "react/jsx-runtime";
import { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useScrollbarWidth } from 'react-use';
import { isSomeModalOpenSelector } from '@che-ins-ui/modal';
import { Container } from '@che-ins-ui/container';
import { useWindowScroll } from '@/libs/hooks';
import { isFrameSelector } from '@/models/request/selectors';
import { HEADER_HEIGHT } from '@/components/common/Header/styles';
import { useStyles } from './styles';
import { Search } from './components';
export const TravelForm = memo(({ isHideable }) => {
    const classes = useStyles();
    const { scrollTop, isLastScrollToTop } = useWindowScroll();
    const isSomeModalOpen = useSelector(isSomeModalOpenSelector);
    const scrollbarWidth = useScrollbarWidth();
    const isFrame = useSelector(isFrameSelector);
    const paddingRight = isSomeModalOpen ? scrollbarWidth + 'px' : '0px';
    const top = useMemo(() => {
        if (isFrame)
            return '0px';
        if (!isHideable) {
            return `${HEADER_HEIGHT}px`;
        }
        if (isLastScrollToTop || scrollTop < HEADER_HEIGHT) {
            return `${HEADER_HEIGHT}px`;
        }
        return '0px';
    }, [isHideable, isLastScrollToTop, scrollTop, isFrame]);
    return (_jsx("div", { className: classes.wrapper, style: {
            top,
            paddingRight,
        }, children: _jsx(Container, { children: _jsx(Search, {}) }) }));
});
export default memo(TravelForm);
export * from './components/WhiteLabelSearch';
