import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useSelector } from 'react-redux';
import { Accordion } from '@che-ins-ui/accordion';
import { apiSelector } from '@/models/api/selectors';
import FilterLayout from './FilterLayout';
import { FiltersList } from './FiltersList';
import { AccordionTitle } from './AccordionTitle';
const ImproveInsuranceFilter = () => {
    const { tripCancellationServices, improverServices } = useSelector(apiSelector);
    return (_jsxs(FilterLayout, { heading: "\u0423\u043B\u0443\u0447\u0448\u0438\u0442\u044C \u0441\u0442\u0440\u0430\u0445\u043E\u0432\u043A\u0443", isLast: true, children: [_jsx(Accordion, { shouldFitContent: true, appearance: "filters", titleButtonProps: {
                    appearance: 'filters-accordion-title-button',
                }, title: _jsx(AccordionTitle, { servicesList: tripCancellationServices, title: "\u041E\u0442\u043C\u0435\u043D\u0430 \u043F\u043E\u0435\u0437\u0434\u043A\u0438" }), children: _jsx(FiltersList, { list: tripCancellationServices, withServiceInfoImage: true }) }), _jsx(FiltersList, { list: improverServices, withServiceInfoImage: true })] }));
};
export default ImproveInsuranceFilter;
