import { jsx as _jsx } from "react/jsx-runtime";
import { useSelector } from 'react-redux';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { comparingClickedProductsSelector, comparingHoveredProductSelector, comparingProductsSelector, comparingSortingSelector, comparingViewSelector, } from '@/models/comparing/selectors';
import { matchedOffersSelector } from '@/models/calculation/selectors';
import { apiCompaniesSelector, apiSelector } from '@/models/api/selectors';
import { requestCurrencySelector } from '@/models/request/selectors';
import { filterRepeatingServices, getAllServices, getComparingOffers, sortComparingOffers, } from './helpers';
import { ComparingListOffers } from './ComparingListOffers';
import { ComparingTableOffers } from './ComparingTableOffers';
export const ComparingBody = memo(({ handleAddToBasket }) => {
    const matchedOffers = useSelector(matchedOffersSelector);
    const addToBasket = useCallback((offerId) => {
        const offer = matchedOffers.find((offer) => offer.company.id === offerId);
        if (offer) {
            handleAddToBasket(offer);
        }
    }, [matchedOffers, handleAddToBasket]);
    const companies = useSelector(apiCompaniesSelector);
    const comparing = useSelector(comparingProductsSelector);
    const currency = useSelector(requestCurrencySelector);
    const view = useSelector(comparingViewSelector);
    const [initialAnimationShown, setInitialAnimationShown] = useState(true);
    useEffect(() => {
        setInitialAnimationShown(false);
    }, [view]);
    const { servicesDictionary } = useSelector(apiSelector);
    const sorting = useSelector(comparingSortingSelector);
    const { matching, missing } = useMemo(() => {
        return getComparingOffers({
            comparingIds: comparing,
            companies,
            matchedOffers,
            servicesDictionary,
            currency,
        });
    }, [comparing, companies, matchedOffers, servicesDictionary, currency]);
    const { offers, repeatingServices } = useMemo(() => {
        return filterRepeatingServices(matching);
    }, [matching]);
    const allServices = useMemo(() => {
        return getAllServices(matching);
    }, [matching.length]);
    const sortedOffers = useMemo(() => {
        return sortComparingOffers(offers, sorting);
    }, [sorting, offers]);
    const comparingClickedProducts = useSelector(comparingClickedProductsSelector);
    const comparingHoveredProduct = useSelector(comparingHoveredProductSelector);
    if (view === 'table') {
        return (_jsx(ComparingTableOffers, { sortedOffers: sortedOffers, repeatingServices: repeatingServices, allServices: allServices, initialAnimationShown: initialAnimationShown, sorting: sorting, addToBasket: addToBasket }));
    }
    return (_jsx(ComparingListOffers, { sortedOffers: sortedOffers, missing: missing, comparingClickedProducts: comparingClickedProducts, comparingHoveredProduct: comparingHoveredProduct, repeatingServices: repeatingServices, initialAnimationShown: initialAnimationShown, addToBasket: addToBasket }));
});
