import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { useScrollbarWidth } from 'react-use';
import { useSelector } from 'react-redux';
import { Container } from '@che-ins-ui/container';
import { isSomeModalOpenSelector } from '@che-ins-ui/modal';
import { isFrameSelector } from '@/models/request/selectors';
import { useStyles } from './styles';
const Page = ({ aside, main }) => {
    const isSomeModalOpen = useSelector(isSomeModalOpenSelector);
    const classes = useStyles();
    const scrollbarWidth = useScrollbarWidth();
    const isFrame = useSelector(isFrameSelector);
    return (_jsx("div", { className: classes.root, style: {
            paddingRight: isSomeModalOpen ? scrollbarWidth + 'px' : '0px',
        }, children: _jsxs(Container, { baseAppearance: "page", appearance: isFrame ? 'page-in-frame' : undefined, children: [_jsx("aside", { className: classes.aside, children: aside }), _jsx("main", { className: classes.main, children: main })] }) }));
};
export default memo(Page);
