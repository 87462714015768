import { createSlice } from '@reduxjs/toolkit';
import { UPSELL_STATE_NAMESPACE } from './consts';
export const upsellInitialState = {
    isLoading: false,
    offers: [],
    chosen: {},
    finalPrice: null,
};
const UpsellSlice = createSlice({
    name: UPSELL_STATE_NAMESPACE,
    initialState: upsellInitialState,
    reducers: {
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setChosen: (state, action) => {
            state.chosen[action.payload.type] = action.payload.value;
        },
        addOffer: (state, action) => {
            const nextState = [...state.offers, action.payload];
            state.offers = nextState;
        },
        setFinalPrice: (state, action) => {
            state.finalPrice = action.payload;
        },
        reset: () => upsellInitialState,
    },
});
const { reducer: upsellReducer, actions: upsellActions } = UpsellSlice;
export { upsellReducer, upsellActions };
export { UPSELL_STATE_NAMESPACE };
