import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@che-ins-ui/button';
import { toggleElementInArray } from '@che-ins-ui/utils';
import { requestActions } from '@/models/request';
import { apiAviaCargoServiceSelector, apiSelector, } from '@/models/api/selectors';
import { requestSelector } from '@/models/request/selectors';
import { analytics } from '@/libs/analytics';
import FilterLayout from './FilterLayout';
const analyticGoals = {
    sunburnAssistance: 'vzr_sun_burn',
    motorcycle: 'vzr_motorcycle',
    aviaCargo: 'vzr_luggage_insurance',
};
const sendRecommendGoal = (code) => {
    analytics.sendEvent({
        action: analyticGoals[code],
        label: 'Действие пользователя',
        category: 'Cherehapa',
    });
};
const AviaCargoButton = () => {
    const { services } = useSelector(requestSelector);
    const { name, code } = useSelector(apiAviaCargoServiceSelector);
    const dispatch = useDispatch();
    const isSelected = !!services[code];
    const handleClick = () => {
        sendRecommendGoal('aviaCargo');
        if (isSelected) {
            dispatch(requestActions.setServices({ [code]: 0 }));
        }
        else {
            dispatch(requestActions.setServices({ [code]: 500 }));
        }
    };
    return (_jsx(Button, { value: code, baseAppearance: "filters", appearance: "selectable", isSelected: !!services[code], onClick: handleClick, children: name }));
};
const RecommendFilter = () => {
    const dispatch = useDispatch();
    const { recomendServices, recomendSports } = useSelector(apiSelector);
    const { services, sports } = useSelector(requestSelector);
    const handleChangeService = (code) => () => {
        sendRecommendGoal('sunburnAssistance');
        const newValue = Number(!services[code]);
        dispatch(requestActions.setServices({ [code]: newValue }));
    };
    const handleChangeSports = (code) => () => {
        sendRecommendGoal('motorcycle');
        const newSports = toggleElementInArray(sports, code);
        dispatch(requestActions.setSports(newSports));
    };
    const selectedSports = useMemo(() => {
        return sports.reduce((acc, elem) => ({ ...acc, [elem]: true }), {});
    }, [sports]);
    return (_jsxs(FilterLayout, { heading: "\u0420\u0435\u043A\u043E\u043C\u0435\u043D\u0434\u0443\u0435\u043C \u0434\u043E\u0431\u0430\u0432\u0438\u0442\u044C", isFirst: true, children: [recomendServices.map(({ code, name }) => {
                return (_jsx(Button, { value: code, baseAppearance: "filters", appearance: "selectable", isSelected: !!services[code], onClick: handleChangeService(code), children: name }, code));
            }), _jsx(AviaCargoButton, {}), recomendSports.map(({ code, name }) => {
                return (_jsx(Button, { value: code, baseAppearance: "filters", appearance: "selectable", isSelected: !!selectedSports[code], onClick: handleChangeSports(code), children: name }, code));
            })] }));
};
export default memo(RecommendFilter);
