import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button } from '@che-ins-ui/button';
import { minimalPriceSelector } from '@/models/calculation/selectors';
import { formatPrice } from '@/libs/helpers/utils';
import { scrollToTop } from '@/libs/helpers';
import { useCalculationQueryString } from '@/libs/hooks/useCalculationQueryString';
import { RESTORABLE_QS_PARAMS } from '@/libs/constants';
export const MobileFilterShowResultButton = memo(() => {
    const minimalPrice = useSelector(minimalPriceSelector);
    const queryString = useCalculationQueryString({
        removeParams: [RESTORABLE_QS_PARAMS.mobileFilters],
    });
    const isMinimalPrice = minimalPrice != null;
    return (_jsx(Button, { appearance: "mobile-filters-show-result", component: Link, to: { search: queryString }, passAppearancesToComponent: false, onClick: () => scrollToTop({ behavior: 'auto' }), children: isMinimalPrice ? (_jsxs(_Fragment, { children: ["\u041F\u043E\u043A\u0430\u0437\u0430\u0442\u044C \u043E\u0442", _jsx("b", { style: { marginLeft: 3 }, children: `${formatPrice(minimalPrice)} ₽` })] })) : ('Показать') }));
});
