import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { useCheTheme } from '@/styles/themes';
export const ComparingTableIcon = memo(({ isActive }) => {
    const theme = useCheTheme();
    const primaryColor = isActive
        ? theme.colors.brand
        : theme.colors.textSecondary;
    const secondaryColor = theme.colors.cloud;
    return (_jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", xmlSpace: "preserve", viewBox: "0 0 1000 1000", width: "22", height: "22", children: [_jsx("path", { fill: secondaryColor, d: "M44.6 51.5h899.3v899.3H44.6z" }), _jsx("path", { d: "M1000 230.8v-13.9h-.2c.1-1.7.2-3.4.2-5.1V72c0-39.8-32.2-72-72-72H72C32.3 0 0 32.2 0 72v139.8c0 1.7.1 3.4.2 5.1H.1v13.9H0v554h.1v.8c0 .9-.1 1.8-.1 2.7V928c0 39.8 32.2 72 72 72H928c39.8 0 72-32.2 72-72V230.8zM692.3 461.5V307.7h230.8v153.8H692.3zm230.8 77v153.8H692.3V538.5h230.8zM76.9 76.9h846.2v154H76.9v-154zm230.8 846.2H76.9V769.2h230.8v153.9zm0-230.8H76.9V538.5h230.8v153.8zm0-230.8H76.9V307.7h230.8v153.8zm307.7 461.6H384.6V769.2h230.8v153.9zm0-230.8H384.6V538.5h230.8v153.8zm0-230.8H384.6V307.7h230.8v153.8zm76.9 461.6V769.2h230.8V923H692.3z", fill: primaryColor })] }));
});
