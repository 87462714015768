import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Accordion } from '@che-ins-ui/accordion';
import { toggleElementInArray } from '@che-ins-ui/utils';
import { Select } from '@che-ins-ui/select';
import { Checkbox } from '@/components/common/checkbox';
import { requestActions } from '@/models/request';
import { requestSelector } from '@/models/request/selectors';
import { apiSelector } from '@/models/api/selectors';
import FilterLayout from '../FilterLayout';
import { FiltersList } from '../FiltersList';
import { AccordionTitle } from '../AccordionTitle';
const TripGoalFilter = () => {
    const dispatch = useDispatch();
    const { services, sports } = useSelector(requestSelector);
    const { workAbroadServices, educationServices, activeRestServices, commonSports, competitionSports, } = useSelector(apiSelector);
    const isMotorcycleChecked = sports.includes('motorcycle');
    const memoizedMotorcycle = useMemo(() => {
        return commonSports?.find(({ code }) => code === 'motorcycle');
    }, [commonSports]) || {};
    const handleChangeService = (code) => (value) => {
        dispatch(requestActions.setServices({ [code]: Number(value) }));
    };
    const handleChangeMotocycle = () => {
        const newSports = toggleElementInArray(sports, 'motorcycle');
        dispatch(requestActions.setSports(newSports));
    };
    const handleChangeSports = useCallback((newSports) => {
        dispatch(requestActions.setSports(newSports));
    }, []);
    const commonSportsOptions = useMemo(() => {
        return commonSports.map(({ code, name }) => ({
            value: code,
            label: name,
        }));
    }, [commonSports]);
    const competitionSportsOptions = useMemo(() => {
        return competitionSports.map(({ code, name }) => ({
            value: code,
            label: name,
        }));
    }, [competitionSports]);
    return (_jsxs(FilterLayout, { heading: "\u0426\u0435\u043B\u044C \u043F\u043E\u0435\u0437\u0434\u043A\u0438", children: [_jsxs(Accordion, { appearance: "filters", shouldFitContent: true, titleButtonProps: {
                    appearance: 'filters-accordion-title-button',
                }, title: _jsx(AccordionTitle, { servicesList: activeRestServices, title: "\u0417\u0430\u043D\u044F\u0442\u0438\u0435 \u0441\u043F\u043E\u0440\u0442\u043E\u043C \u0438 \u0430\u043A\u0442\u0438\u0432\u043D\u044B\u0439 \u043E\u0442\u0434\u044B\u0445", initialCount: sports.length }), children: [_jsx(Select, { appearance: "filters", options: commonSportsOptions, value: sports, label: "\u041B\u044E\u0431\u0438\u0442\u0435\u043B\u044C\u0441\u043A\u0438\u0439 \u0441\u043F\u043E\u0440\u0442 \u0438 \u0430\u043A\u0442\u0438\u0432\u043D\u044B\u0439 \u043E\u0442\u0434\u044B\u0445", placeholder: "\u0412\u044B\u0431\u0435\u0440\u0438\u0442\u0435 \u0432\u0438\u0434 \u0441\u043F\u043E\u0440\u0442\u0430", selectedHeader: "\u0412\u042B\u0411\u0420\u0410\u041D\u041D\u042B\u0415", unselectedHeader: "\u0412\u0421\u0415 \u0412\u0418\u0414\u042B \u0421\u041F\u041E\u0420\u0422\u0410", isMulti: true, onChange: handleChangeSports }), _jsx(Select, { appearance: "filters", options: competitionSportsOptions, value: sports, label: "\u041F\u0440\u043E\u0444\u0435\u0441\u0441\u0438\u043E\u043D\u0430\u043B\u044C\u043D\u044B\u0439 \u0441\u043F\u043E\u0440\u0442 \u0438 \u0441\u043E\u0440\u0435\u0432\u043D\u043E\u0432\u0430\u043D\u0438\u044F", placeholder: "\u0412\u044B\u0431\u0435\u0440\u0438\u0442\u0435 \u0432\u0438\u0434 \u0441\u043F\u043E\u0440\u0442\u0430", selectedHeader: "\u0412\u042B\u0411\u0420\u0410\u041D\u041D\u042B\u0415", unselectedHeader: "\u0412\u0421\u0415 \u0412\u0418\u0414\u042B \u0421\u041F\u041E\u0420\u0422\u0410", isMulti: true, onChange: handleChangeSports }), _jsx(Checkbox, { value: isMotorcycleChecked, onChange: handleChangeMotocycle, label: memoizedMotorcycle.name, appearance: "filters" }), _jsx(FiltersList, { list: activeRestServices, withServiceInfoImage: true })] }), _jsx(Accordion, { appearance: "filters", shouldFitContent: true, titleButtonProps: {
                    appearance: 'filters-accordion-title-button',
                }, title: _jsx(AccordionTitle, { servicesList: workAbroadServices, title: "\u0420\u0430\u0431\u043E\u0442\u0430 \u0437\u0430 \u0433\u0440\u0430\u043D\u0438\u0446\u0435\u0439" }), children: _jsx(FiltersList, { list: workAbroadServices, withServiceInfoImage: true }) }), _jsx(Checkbox, { value: !!services['education'], onChange: handleChangeService('education'), label: educationServices[0]?.name, appearance: "filters" })] }));
};
export default TripGoalFilter;
