import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createMask } from 'imask';
import { tabsActions } from '@che-ins-ui/tabs';
import { Tag } from '@che-ins-ui/tag';
import { EditIcon } from '@che-ins-ui/icons/EditIcon';
import { Skeleton } from '@che-ins-ui/skeleton';
import { emailsSelector, insurerFormsSelector, touristsFormsSelector, } from '@/models/forms/selectors';
import { transformInsurerNames } from '@/models/forms/helpers';
import { isInsurerInRussianSelector } from '@/models/basket/selectors';
import { getMaskValue } from '@/models/process/utils';
import { INSURANCE_TABS_NAME } from '../consts';
import { useStyles } from './styles';
import { TABS } from './tabs';
export const InsurerAndTourists = memo(() => {
    const tourists = useSelector(touristsFormsSelector);
    const insurerForms = useSelector(insurerFormsSelector);
    const isInsurerInRussian = useSelector(isInsurerInRussianSelector);
    const insurer = transformInsurerNames(insurerForms, isInsurerInRussian);
    const emails = useSelector(emailsSelector);
    const classes = useStyles();
    const dispatch = useDispatch();
    const handleClick = useCallback(() => {
        dispatch(tabsActions.setActiveTab({
            tabsName: INSURANCE_TABS_NAME,
            activePanelName: TABS[0].panelName,
        }));
    }, []);
    return (_jsxs("div", { className: classes.root, children: [_jsx("div", { className: classes.header, children: _jsx("h3", { className: classes.title, children: "\u0422\u0443\u0440\u0438\u0441\u0442\u044B" }) }), _jsxs("div", { className: classes.tagsBlocks, children: [_jsxs("div", { className: classes.insurerTagWrapper, children: [_jsx("span", { className: classes.tagsSubtitle, children: "\u041F\u043E\u043A\u0443\u043F\u0430\u0442\u0435\u043B\u044C" }), _jsx("div", { children: _jsx(PersonTag, { onEditClick: handleClick, ...insurer, phoneCountryCode: insurerForms.phoneCountryCode, phoneBody: insurerForms.phoneBody, emails: emails }) })] }), _jsxs("div", { className: classes.touristsTagsBlock, children: [_jsx("span", { className: classes.tagsSubtitle, children: "\u0417\u0430\u0441\u0442\u0440\u0430\u0445\u043E\u0432\u0430\u043D\u043D\u044B\u0435" }), _jsx("div", { className: classes.touristsTagsWrapper, children: tourists.map((tourist) => (_jsx(PersonTag, { onEditClick: handleClick, ...tourist }, tourist._uuid))) })] })] })] }));
});
const PersonTag = memo(({ firstName, lastName, birthday, phoneBody, phoneCountryCode, emails, onEditClick, }) => {
    const classes = useStyles();
    const name = `${lastName} ${firstName}`.toUpperCase();
    const phone = phoneCountryCode && phoneBody
        ? `+${phoneCountryCode} ${createMask({
            mask: getMaskValue(phoneCountryCode),
        }).resolve(phoneBody)}`
        : null;
    return (_jsx(Tag, { appearance: "insurance", postfix: _jsx(EditIcon, { onClick: onEditClick }), children: _jsxs("div", { className: classes.tagBody, children: [_jsx("div", { className: classes.tagTitleRow, children: _jsx("b", { title: name, className: classes.tagTitle, translate: "no", children: lastName ? (name) : (_jsx(Skeleton, { width: "100px", height: "15px", borderRadius: 7, margin: "0px 0px 2px" })) }) }), _jsx("p", { title: birthday, className: classes.tagDataRow, children: birthday }), phone && (_jsx("p", { title: phone, className: classes.tagDataRow, children: phone })), emails &&
                    emails.map(({ value }, i) => (_jsx("p", { title: value, className: classes.tagDataRow, translate: "no", children: value }, i)))] }) }));
});
