import { AssistanceTab } from './tabs/AssistanceTab';
import { FranciseTab } from './tabs/FranciseTab';
import { InsuranceRulesTab } from './tabs/InsuranceRulesTab';
import { OptionsTab } from './tabs/OptionsTab';
import { RestrictionsTab } from './tabs/RestrictionsTab';
import { SamplePolicyTab } from './tabs/SamplePolicyTab';
export const TABS = [
    {
        panelName: 'OPTIONS',
        label: 'Опции',
        Component: OptionsTab,
    },
    {
        panelName: 'RESTRICTIONS',
        label: 'Ограничения',
        Component: RestrictionsTab,
    },
    {
        panelName: 'SAMPLE_POLICY',
        label: 'Образец полиса',
        Component: SamplePolicyTab,
    },
    {
        panelName: 'INSURANCE_RULES',
        label: 'Правила страхования',
        Component: InsuranceRulesTab,
    },
    {
        panelName: 'ASSISTANCE',
        label: 'Ассистанс',
        Component: AssistanceTab,
    },
    {
        panelName: 'FRANCISE',
        label: 'Франшиза',
        Component: FranciseTab,
    },
];
