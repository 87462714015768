import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { Outlet, useLocation, useSearchParams } from 'react-router-dom';
import { BREAKPOINTS, useScreenSizes } from '@che-ins-ui/theme';
import { useSelector } from 'react-redux';
import { RESTORABLE_QS_PARAMS } from '@/libs/constants';
import { useIsMobileFilters } from '@/libs/hooks';
import { useCalculationQueryString } from '@/libs/hooks/useCalculationQueryString';
import { DESKTOP_FILTERS_BREAKPOINT } from '@/styles/themes/components';
import { apiIsServiceStatusLoadingSelector, isSalesWorkingSelector, } from '@/models/api/selectors';
import { removeTrailingSlash } from '@/libs/helpers';
import { Footer } from '../common/Footer';
import TravelForm from '../TravelForm';
import AppLoader from '../common/AppLoader';
import { ROUTES } from '../routes';
import { HeaderNavigation } from '../common/HeaderNavigation';
import { NotWorking } from '../TechnicalPages';
import { Header } from '../common/Header';
export const SearchLayout = () => {
    const [_, setSearch] = useSearchParams();
    const isMobileFilters = useIsMobileFilters();
    const isSalesWorking = useSelector(isSalesWorkingSelector);
    const apiIsServiceStatusLoading = useSelector(apiIsServiceStatusLoadingSelector);
    const queryString = useCalculationQueryString({
        removeParams: [RESTORABLE_QS_PARAMS.mobileFilters],
    });
    const { width } = useScreenSizes();
    const isDesktop = width > BREAKPOINTS[DESKTOP_FILTERS_BREAKPOINT];
    useEffect(() => {
        if (isMobileFilters && isDesktop) {
            setSearch(queryString);
        }
    }, [isMobileFilters, isDesktop, queryString]);
    const { pathname } = useLocation();
    const isAsideNavigationShown = width > BREAKPOINTS['md'];
    const isInsuranceRoute = removeTrailingSlash(pathname) === ROUTES.insurance;
    const isHeaderNavigationShown = isInsuranceRoute && !isAsideNavigationShown;
    if (apiIsServiceStatusLoading) {
        return _jsx(AppLoader, {});
    }
    if (!isSalesWorking) {
        return _jsx(NotWorking, {});
    }
    if (isMobileFilters) {
        return _jsx(Outlet, {});
    }
    return (_jsxs("div", { children: [isHeaderNavigationShown ? (_jsx(HeaderNavigation, { isHideable: true })) : (_jsx(Header, { isHideable: true })), _jsx(TravelForm, { isHideable: true }), _jsx(Outlet, {}), _jsx(Footer, {})] }));
};
