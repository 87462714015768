import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useClickAway } from 'react-use';
import { AnimatePresence, motion } from 'framer-motion';
import { Button, IconButton } from '@che-ins-ui/button';
import { Checkbox } from '@che-ins-ui/checkbox';
import { TriangleIcon } from '@che-ins-ui/icons/TriangleIcon';
import { CrossIcon } from '@che-ins-ui/icons/CrossIcon';
import { modalActions } from '@che-ins-ui/modal';
import { comparingSortingAnalyticEvents, comparingSortings, comparingSortingsNames, comparingViewNames, comparingViews, } from '@/models/comparing/consts';
import { comparingDifferencesOnlySelector, comparingProductsSelector, comparingSortingSelector, comparingViewSelector, } from '@/models/comparing/selectors';
import { comparingActions } from '@/models/comparing';
import { matchedOffersSelector } from '@/models/calculation/selectors';
import { analytics } from '@/libs/analytics';
import { ButtonWrapperWithTooltip } from '../ButtonWrapperWithTooltip/ButtonWrapperWithTooltip';
import { useStyles } from './styles';
import { COMPARING_MODAL_NAME } from './const';
import { getMatchingOffersAmount } from './helpers';
import { ComparingTableIcon } from './ComparingTableIcon';
import { ComparingListIcon } from './ComparingListIcon';
const viewIcons = {
    list: ComparingListIcon,
    table: ComparingTableIcon,
};
export const ComparingHeader = () => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const ref = useRef(null);
    const differencesOnly = useSelector(comparingDifferencesOnlySelector);
    const sorting = useSelector(comparingSortingSelector);
    const view = useSelector(comparingViewSelector);
    const [isSortingMenuOpen, setIsComparingMenuOpen] = useState(false);
    const matchedOffers = useSelector(matchedOffersSelector);
    const comparing = useSelector(comparingProductsSelector);
    const matchingOffersAmount = getMatchingOffersAmount(comparing, matchedOffers);
    useClickAway(ref, () => setIsComparingMenuOpen(false));
    const changeSorting = (sorting) => {
        if (!isSortingMenuOpen) {
            setIsComparingMenuOpen(true);
            return;
        }
        dispatch(comparingActions.setSorting(sorting));
        setIsComparingMenuOpen(false);
        analytics.sendEvent({
            action: comparingSortingAnalyticEvents[sorting],
            label: 'Действие пользователя',
            category: 'Cherehapa',
        });
    };
    const toggleDifferencesOnly = () => {
        dispatch(comparingActions.toggleDifferencesOnly());
        analytics.sendEvent({
            action: 'toggle_only_differences',
            label: 'Действие пользователя',
            category: 'Cherehapa',
        });
    };
    const changeView = (view) => {
        dispatch(comparingActions.setView(view));
        analytics.sendEvent({
            action: `view_${view}`,
            label: 'Действие пользователя',
            category: 'Cherehapa',
        });
    };
    const sortings = [...comparingSortings].sort((a, b) => {
        if (a === sorting)
            return -1;
        if (b === sorting)
            return 1;
        return 0;
    });
    const dropdownIconAnimation = {
        rotate: isSortingMenuOpen ? 90 : 0,
        y: isSortingMenuOpen ? 1 : 3,
        marginLeft: isSortingMenuOpen ? -6 : 0,
        paddingLeft: isSortingMenuOpen ? 0 : 4,
    };
    const transition = {
        type: 'ease',
        duration: 0.2,
        layout: {
            duration: 0,
        },
    };
    const sortingVariants = {
        hidden: {
            opacity: 0,
            x: -20,
            width: 0,
        },
        visible: {
            opacity: 1,
            x: 0,
            width: 'auto',
        },
    };
    return (_jsx("div", { className: classes.headerWrapper, children: _jsxs("div", { className: classes.header, children: [matchingOffersAmount > 1 && (_jsxs(_Fragment, { children: [_jsx(Checkbox, { value: differencesOnly, label: "\u0422\u043E\u043B\u044C\u043A\u043E \u043E\u0442\u043B\u0438\u0447\u0438\u044F", appearance: "comparing", onChange: toggleDifferencesOnly }), _jsx("div", { className: classes.sorting, children: _jsx(motion.div, { transition: { layout: { duration: 0 } }, style: {
                                    gap: isSortingMenuOpen ? 12 : 0,
                                }, className: classes.sortingMenu, ref: ref, children: _jsxs(AnimatePresence, { initial: false, children: [sortings.map((sortingItem) => isSortingMenuOpen ||
                                            sortingItem === sorting ? (_jsx(motion.div, { transition: transition, variants: sortingVariants, initial: "hidden", animate: "visible", exit: "hidden", layout: "position", onClick: () => changeSorting(sortingItem), className: classes.sortingItem, "data-active": sortingItem === sorting, children: _jsx(Button, { baseAppearance: "comparing-sorting", tabIndex: 0, children: comparingSortingsNames[sortingItem] }) }, sortingItem)) : null), _jsx(motion.div, { initial: dropdownIconAnimation, animate: dropdownIconAnimation, transition: transition, className: classes.sortingDropdownIcon, onClick: () => setIsComparingMenuOpen((prev) => !prev), children: _jsx(TriangleIcon, {}) })] }) }) })] })), _jsx("div", { className: classes.right, children: _jsxs("div", { className: classes.view, children: ["\u0412\u0438\u0434:", '\u00A0', comparingViews.map((viewItem) => {
                                const Icon = viewIcons[viewItem];
                                return (_jsx(ButtonWrapperWithTooltip, { onClick: () => {
                                        changeView(viewItem);
                                    }, tooltipLabel: comparingViewNames[viewItem], height: 20, position: "bottom", tooltipY: 5, children: _jsx(Icon, { isActive: viewItem === view }) }, viewItem));
                            })] }) }), _jsx(IconButton, { icon: _jsx(CrossIcon, {}), baseAppearance: "compare-close", onClick: () => {
                        dispatch(modalActions.close({ name: COMPARING_MODAL_NAME }));
                    } })] }) }));
};
