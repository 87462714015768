import { ABROAD_CODE, ACCIDENT_CODE, ALCOHOL_ASSISTANCE_CODE, ALLERGY_ARRESTING_CODE, ASSISTANCE_CODE, AUTO_CODE, AVIA_CARGO_CODE, CHILDREN_RETURN_CODE, CHRONIC_ARRESTING_CODE, CIVIL_LIABILITY_CODE, COVID_CODE, DELAY_CARGO_CODE, DENGE_CODE, DISASTER_ASSISTANCE_CODE, DOCUMENT_CODE, EDUCATION_CODE, FOREIGN_CODE, FRANCHISE_CODE, INSURED_RETURN_CODE, ISOLATION_CODE, LEGAL_CODE, MEDICINE_CODE, MULTIPOLICY_CODE, OBSERVATION_CODE, OFFICE_CODE, PREGNANCY_CODE, REGULAR_DELAY_CODE, RELATIVE_ILL_RETURN_CODE, RESCUE_ACTIVITIES_CODE, SEARCH_ACTIVITIES_CODE, SUNBURN_ASSISTANCE_CODE, TERROR_ASSISTANCE_CODE, THIRD_PARTY_TRANSPORTATION_CODE, TRIP_CANCEL_CODE, URGENCY_STOMATOLOGY_CODE, VISA_CANCEL_CODE, WORK_CODE, } from '@/libs/static';
export const COMPARING_STATE_NAMESPACE = 'comparing';
export const allComparingServices = {
    [FRANCHISE_CODE]: 'Франшиза',
    [ASSISTANCE_CODE]: 'Ассистанс',
    [FOREIGN_CODE]: 'Я не гражданин России',
    [ABROAD_CODE]: 'Я уже за границей',
    [MULTIPOLICY_CODE]: 'Годовой полис',
    [MEDICINE_CODE]: 'Медицинское страхование',
    [URGENCY_STOMATOLOGY_CODE]: 'Экстренная стоматология',
    [COVID_CODE]: 'Лечение Covid-19',
    [OBSERVATION_CODE]: 'Обсервация при Covid-19',
    [INSURED_RETURN_CODE]: 'Перелет после лечения',
    [CHILDREN_RETURN_CODE]: 'Эвакуация детей домой',
    [TERROR_ASSISTANCE_CODE]: 'Террористические акты',
    [DISASTER_ASSISTANCE_CODE]: 'Стихийные бедствия',
    [CHRONIC_ARRESTING_CODE]: 'Хронические заболевания',
    [ALLERGY_ARRESTING_CODE]: 'Аллергия',
    [SUNBURN_ASSISTANCE_CODE]: 'Солнечные ожоги',
    [ALCOHOL_ASSISTANCE_CODE]: 'Алкогольное опьянение',
    [PREGNANCY_CODE]: 'Осложнение беременности',
    [RELATIVE_ILL_RETURN_CODE]: 'Досрочное возвращение',
    [THIRD_PARTY_TRANSPORTATION_CODE]: 'Визит родственника',
    [DENGE_CODE]: 'Лихорадка денге',
    [DOCUMENT_CODE]: 'Потеря документов',
    [LEGAL_CODE]: 'Юридическая помощь',
    [SEARCH_ACTIVITIES_CODE]: 'Эвакуация вертолетом',
    [RESCUE_ACTIVITIES_CODE]: 'Поисково-спасательные мероприятия',
    [WORK_CODE]: 'Работа на производстве',
    [OFFICE_CODE]: 'Работа в офисе',
    [EDUCATION_CODE]: 'Учеба за границей',
    [TRIP_CANCEL_CODE]: 'Отмена поездки',
    [VISA_CANCEL_CODE]: 'Отказ в визе',
    [ISOLATION_CODE]: 'Карантин',
    [AVIA_CARGO_CODE]: 'Страхование багажа',
    [ACCIDENT_CODE]: 'Оплата несчастных случаев',
    [CIVIL_LIABILITY_CODE]: 'Гражданская ответственность',
    [REGULAR_DELAY_CODE]: 'Задержка рейса',
    [AUTO_CODE]: 'Поездка на личном автомобиле',
    [DELAY_CARGO_CODE]: 'Задержка багажа',
};
export const comparingServices = Object.keys(allComparingServices);
export const comparingServicesNames = Object.values(allComparingServices);
const franchiseDescription = 'При наличии франшизы в полисе застрахованному туристу необходимо оплатить её самостоятельно. Оплата производится в клинике при обращении за медицинской помощью. Все остальные расходы на лечение (свыше размера франшизы) компенсирует страховая компания.';
const assistanceDescription = 'Сервисная компания, которая по поручению страховой компании организует экстренную медицинскую помощь за рубежом.';
export const comparingAdditionalDictionary = {
    [FRANCHISE_CODE]: franchiseDescription,
    [ASSISTANCE_CODE]: assistanceDescription,
};
