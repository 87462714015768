import { createSlice } from '@reduxjs/toolkit';
import { COMPARING_STATE_NAMESPACE } from './consts';
export const comparingInitialState = {
    comparing: [],
    isDifferencesOnly: false,
    sorting: 'added',
    clickedProducts: [],
    hoveredProduct: null,
    view: 'table',
    wasAddingUsed: false,
};
const ComparingSlice = createSlice({
    name: COMPARING_STATE_NAMESPACE,
    initialState: comparingInitialState,
    reducers: {
        setDefault: (state) => ({
            ...comparingInitialState,
            wasAddingUsed: state.wasAddingUsed,
        }),
        toggleComparing: (state, action) => {
            const { comparing } = state;
            if (comparing.includes(action.payload)) {
                state.comparing = comparing.filter((id) => id !== action.payload);
            }
            else {
                if (comparing.length) {
                    state.wasAddingUsed = true;
                }
                state.comparing = [...comparing, action.payload];
            }
        },
        toggleDifferencesOnly: (state) => {
            state.isDifferencesOnly = !state.isDifferencesOnly;
        },
        setSorting: (state, action) => {
            state.sorting = action.payload;
        },
        toggleServiceToClicked: (state, action) => {
            const service = action.payload;
            if (state.clickedProducts.includes(service)) {
                state.clickedProducts = state.clickedProducts.filter((id) => id !== service);
                if (state.hoveredProduct === service) {
                    state.hoveredProduct = null;
                }
            }
            else {
                state.clickedProducts = [...state.clickedProducts, service];
            }
        },
        resetClickedServices: (state) => {
            state.clickedProducts = [];
        },
        setHoveredService: (state, action) => {
            state.hoveredProduct = action.payload;
        },
        setView: (state, action) => {
            state.view = action.payload;
        },
        moveLeft: (state, action) => {
            const index = state.comparing.findIndex((id) => id === action.payload);
            if (index > 0) {
                const temp = state.comparing[index - 1];
                state.comparing[index - 1] = state.comparing[index];
                state.comparing[index] = temp;
            }
        },
        moveRight: (state, action) => {
            const index = state.comparing.findIndex((id) => id === action.payload);
            if (index !== -1 && index < state.comparing.length - 1) {
                const temp = state.comparing[index + 1];
                state.comparing[index + 1] = state.comparing[index];
                state.comparing[index] = temp;
            }
        },
        setWasAddingUsed: (state) => {
            state.wasAddingUsed = true;
        },
    },
});
const { reducer: comparingReducer, actions: comparingActions } = ComparingSlice;
export { comparingReducer, comparingActions };
export { COMPARING_STATE_NAMESPACE };
