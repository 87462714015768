import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { useDispatch } from 'react-redux';
import { motion, AnimatePresence } from 'framer-motion';
import classNames from 'classnames';
import { IconButton } from '@che-ins-ui/button';
import { ChevronIconCalendar } from '@che-ins-ui/icons/ChevronIconCalendar';
import { declOfNum } from '@/libs/helpers';
import { IconsBlock } from '@/components/Showcase/components/IconsBlock';
import { getTypes } from '@/libs/static';
import { comparingActions } from '@/models/comparing';
import { useListStyles } from './listStyles';
import { ComparingTableOfferServices } from './ComparingTableOfferServices';
import { useTableStyles } from './tableStyles';
import { ComparingTableOfferLimitations } from './ComparingTableOfferLimitations';
import { comparingTableOfferMoveDuration } from './ComparingTableOffers';
import { ComparingPriceButton } from './ComparingPriceButton';
export const ComparingTableOffer = memo(({ id, name, linkToLogo, productId, services, servicesCount, missingServicesCount, price, allServices, repeatingServices, missingServices, movingAvailable, isFirst, isLast, isExpanded, limitations, onMove, isBeingMoved, addToBasket, }) => {
    const classes = useTableStyles();
    const dispatch = useDispatch();
    const listClasses = useListStyles();
    const buttonVariants = {
        hidden: {
            opacity: 0,
            scale: 0,
        },
        visible: {
            opacity: 1,
            scale: 1,
        },
    };
    return (_jsxs(motion.div, { className: classes.offer, initial: { opacity: 0, scale: 0 }, animate: { opacity: 1, scale: 1 }, exit: { opacity: 0, scale: 0 }, layout: "position", transition: {
            height: { duration: 0.1 },
            layout: {
                duration: comparingTableOfferMoveDuration / 1000,
            },
        }, "data-expanded": isExpanded, style: { zIndex: isBeingMoved ? 1 : undefined }, children: [_jsxs("div", { className: classes.offerHeaderWrapper, children: [_jsxs("div", { className: classNames(listClasses.offerHeader, classes.offerHeader), children: [_jsx("img", { className: listClasses.offerImage, src: linkToLogo, alt: "" }), _jsxs("div", { className: classNames(listClasses.offerTitles, classes.offerTitles), children: [_jsx("span", { className: listClasses.offerTitle, children: name }), _jsx("span", { className: listClasses.offerDescription, "data-warning": Boolean(missingServicesCount), children: missingServicesCount
                                            ? `Отсутствует ${missingServicesCount} ${declOfNum(missingServicesCount, getTypes('SERVICESMALL'))}`
                                            : `${servicesCount} опций` })] })] }), _jsx("div", { className: classNames(listClasses.offerButtons, classes.offerButtons), onClick: (e) => e.stopPropagation(), "data-moving-available": movingAvailable, children: _jsxs(AnimatePresence, { initial: false, mode: "popLayout", children: [movingAvailable && (_jsx(motion.div, { variants: buttonVariants, initial: "hidden", animate: "visible", exit: "hidden", children: _jsx(IconButton, { disabled: isFirst, baseAppearance: "compare-move", onClick: () => {
                                            onMove();
                                            dispatch(comparingActions.moveLeft(id));
                                        }, icon: _jsx(ChevronIconCalendar, {}) }) }, "left")), _jsx(IconsBlock, { company: {
                                        id,
                                        name,
                                        linkToLogo,
                                    }, noPaddingRight: true, productId: productId, noTooltips: isBeingMoved }), movingAvailable && (_jsx(motion.div, { variants: buttonVariants, initial: "hidden", animate: "visible", exit: "hidden", children: _jsx(IconButton, { disabled: isLast, baseAppearance: "compare-move", appearance: "compare-move-right", onClick: () => {
                                            onMove();
                                            dispatch(comparingActions.moveRight(id));
                                        }, icon: _jsx(ChevronIconCalendar, {}) }) }, "right"))] }) }), _jsx("div", { className: classes.offerPriceWrapper, children: Boolean(price) && (_jsx(ComparingPriceButton, { price: price, onClick: addToBasket })) })] }), _jsx(ComparingTableOfferServices, { services: services, allServices: allServices, repeatingServices: repeatingServices, missingServices: missingServices }), _jsx(ComparingTableOfferLimitations, { limitations: limitations })] }));
});
