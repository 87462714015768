import { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { RESTORABLE_QS_PARAMS } from '../constants';
export { useCalculationFranhcise } from './useCalculationFranhcise';
export const useWindowScroll = () => {
    const [scrollTop, setScrollTop] = useState(0);
    const isLastScrollToTop = useRef(false);
    useEffect(() => {
        const listener = () => {
            let prevScrollY = 0;
            setScrollTop((value) => {
                prevScrollY = value;
                return value;
            });
            isLastScrollToTop.current = window.scrollY < prevScrollY;
            setScrollTop(window.scrollY);
        };
        window.addEventListener('scroll', listener);
        return () => {
            window.removeEventListener('scroll', listener);
        };
    }, []);
    return { scrollTop, isLastScrollToTop: isLastScrollToTop.current };
};
export const useIsMobileFilters = () => {
    const [search] = useSearchParams();
    const isMobileFilters = search.get(RESTORABLE_QS_PARAMS.mobileFilters);
    return isMobileFilters === 'true';
};
