import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { Button } from '@che-ins-ui/button';
import { addDays, addYears, Datepicker, differenceInDays, formatDate, getToday, parseDate, today, } from '@che-ins-ui/datepicker';
import { isMultipolicySelectedSelector, requestSelector, } from '@/models/request/selectors';
import { requestActions, todayPlus3Days } from '@/models/request';
import { apiIsApiDataLoadingSelector } from '@/models/api/selectors';
import { DEFAULT_MULTIPOLICY_DAYS } from '@/libs/constants';
import { MULTIPOLICY_SERVICE_NAME } from '@/libs/static';
import { upsellIsLoadingSelector } from '@/models/upsell/selectors';
import { useStyles } from '../styles';
const MAX_START = addDays(addYears(today, 1), -1);
const SelectYearButton = ({ setIsOpen }) => {
    const { dateStart, dateEnd } = useSelector(requestSelector);
    const dispatch = useDispatch();
    const handleClick = () => {
        const defaultStart = todayPlus3Days;
        if (!dateStart) {
            dispatch(requestActions.setDateStart(formatDate(defaultStart)));
        }
        if (!dateEnd) {
            const nextYearDate = addYears(dateStart || defaultStart, 1);
            const newDateEnd = addDays(nextYearDate, -1);
            dispatch(requestActions.setDateEnd(formatDate(newDateEnd)));
        }
        dispatch(requestActions.setServices({
            [MULTIPOLICY_SERVICE_NAME]: DEFAULT_MULTIPOLICY_DAYS,
        }));
        setIsOpen?.(false);
    };
    return (_jsx(Button, { onClick: handleClick, baseAppearance: "filters", appearance: "secondary", shouldFitContent: true, children: "\u0413\u043E\u0434\u043E\u0432\u043E\u0439 \u043F\u043E\u043B\u0438\u0441" }));
};
export const Dates = ({ mode }) => {
    const isLoading = useSelector(apiIsApiDataLoadingSelector);
    const isLoadingUpsell = useSelector(upsellIsLoadingSelector);
    const classes = useStyles();
    const dispatch = useDispatch();
    const { dateStart, dateEnd } = useSelector(requestSelector);
    const isMultipolicySelected = useSelector(isMultipolicySelectedSelector);
    const [isOpenEnd, setIsOpenEnd] = useState(false);
    const parsedDateStart = useMemo(() => {
        return dateStart ? parseDate(dateStart) : undefined;
    }, [dateStart]);
    const parsedDateEnd = useMemo(() => {
        if (!dateEnd)
            return undefined;
        const formattedDateEnd = parseDate(dateEnd);
        if (isMultipolicySelected) {
            const nextYearDate = addYears(parsedDateStart, 1);
            const newDateEnd = addDays(nextYearDate, -1);
            return newDateEnd;
        }
        return formattedDateEnd;
    }, [isMultipolicySelected, parsedDateStart, dateEnd]);
    const handleChangeStart = useCallback((newStart) => {
        const newFormattedStart = formatDate(newStart);
        dispatch(requestActions.setDateStart(newFormattedStart));
        if (!isMultipolicySelected) {
            setIsOpenEnd(true);
        }
    }, [isMultipolicySelected]);
    const handleChangeEnd = useCallback((newEnd) => {
        const newFormattedEnd = formatDate(newEnd);
        dispatch(requestActions.setDateEnd(newFormattedEnd));
        setIsOpenEnd(false);
    }, []);
    const handleChangeIsOpenStart = useCallback((newIsOpen) => {
        if (newIsOpen) {
            setIsOpenEnd(false);
        }
    }, []);
    const endLabel = useMemo(() => {
        if (!parsedDateStart || !parsedDateEnd)
            return 'Обратно';
        const diff = differenceInDays(parsedDateStart, parsedDateEnd);
        return `Выбрано ${diff + 1} дней`;
    }, [parsedDateStart, parsedDateEnd]);
    const maxEnd = useMemo(() => {
        return addDays(addYears(parsedDateStart || getToday(), 1), -1);
    }, [parsedDateStart]);
    const baseAppearance = useMemo(() => {
        return mode === 'calculation'
            ? 'header-filters'
            : 'white-label-filters';
    }, [mode]);
    const handleClearStart = useMemo(() => {
        const onClear = (e) => {
            e.stopPropagation();
            if (isMultipolicySelected) {
                batch(() => {
                    dispatch(requestActions.setDateStart(null));
                    dispatch(requestActions.setDateEnd(null));
                    dispatch(requestActions.setServices({
                        [MULTIPOLICY_SERVICE_NAME]: 0,
                    }));
                });
            }
            else {
                dispatch(requestActions.setDateStart(null));
            }
        };
        return mode === 'while-label' ? onClear : undefined;
    }, [mode, isMultipolicySelected]);
    const handleClearEnd = useMemo(() => {
        const onClearWidget = (e) => {
            e.stopPropagation();
            batch(() => {
                dispatch(requestActions.setDateEnd(null));
                dispatch(requestActions.setServices({
                    [MULTIPOLICY_SERVICE_NAME]: 0,
                }));
            });
        };
        const onClearShowcase = isMultipolicySelected
            ? () => {
                dispatch(requestActions.setServices({
                    [MULTIPOLICY_SERVICE_NAME]: 0,
                }));
                setIsOpenEnd(false);
                requestAnimationFrame(() => {
                    setIsOpenEnd(true);
                });
            }
            : undefined;
        return mode === 'while-label' ? onClearWidget : onClearShowcase;
    }, [mode, isMultipolicySelected]);
    const disabledEnd = useMemo(() => {
        return isLoading || isLoadingUpsell || isMultipolicySelected;
    }, [isLoading, mode, isMultipolicySelected, isLoadingUpsell]);
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: classes.dateFrom, children: _jsx(Datepicker, { minDate: new Date(), label: "\u041A\u043E\u0433\u0434\u0430", value: parsedDateStart, onChange: handleChangeStart, baseAppearance: baseAppearance, appearance: "header-date-from", onChangeIsOpen: handleChangeIsOpenStart, inputProps: {
                        baseAppearance: 'header-filters',
                        appearance: 'header-date-from',
                    }, maxDate: MAX_START, disabled: isLoading || isLoadingUpsell, rangeSelector: "start", startDate: parsedDateStart, endDate: parsedDateEnd, shouldFitContent: true, onClear: handleClearStart, openedDate: parsedDateStart }) }), _jsx("div", { className: classes.dateTo, children: _jsx(Datepicker, { minDate: parsedDateStart, label: endLabel, value: parsedDateEnd, onChange: handleChangeEnd, isOpen: isOpenEnd, openedDate: parsedDateEnd, baseAppearance: baseAppearance, appearance: "header-date-to", inputProps: {
                        baseAppearance: 'header-filters',
                        appearance: 'header-date-to',
                    }, disabled: disabledEnd, maxDate: maxEnd, footerComponent: _jsx(SelectYearButton, {}), rangeSelector: "end", startDate: parsedDateStart, endDate: parsedDateEnd, onClear: handleClearEnd, shouldFitContent: true }) })] }));
};
