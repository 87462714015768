import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ComparingTableOffer } from './ComparingTableOffer';
import { useTableStyles } from './tableStyles';
import { ComparingTableAside } from './ComparingTableAside';
export const comparingTableOfferMoveDuration = 350;
export const ComparingTableOffers = memo(({ sortedOffers, repeatingServices, allServices, initialAnimationShown, sorting, addToBasket, }) => {
    const classes = useTableStyles();
    const [movedOfferId, setMovedOfferId] = useState(null);
    useEffect(() => {
        if (!movedOfferId) {
            return;
        }
        const timeout = setTimeout(() => {
            setMovedOfferId(null);
        }, comparingTableOfferMoveDuration);
        return () => {
            clearTimeout(timeout);
        };
    }, [movedOfferId]);
    const offersAmount = sortedOffers.length;
    return (_jsxs(motion.div, { className: classes.offers, transition: {
            duration: 0.2,
            height: { duration: 0.1 },
            layout: { duration: 0.2 },
        }, children: [_jsxs(AnimatePresence, { initial: initialAnimationShown, mode: "popLayout", children: [_jsx(ComparingTableAside, { allServices: allServices, repeatingServices: repeatingServices }, "aside"), sortedOffers.map(({ company: { id, name, linkToLogo }, info: { price, servicesCount, productId, services, limitations, missingServices, missingServicesCount, }, }, index) => (_jsx(ComparingTableOffer, { id: id, name: name, linkToLogo: linkToLogo, price: price, services: services, allServices: allServices, repeatingServices: repeatingServices, missingServices: missingServices, servicesCount: servicesCount, missingServicesCount: missingServicesCount, productId: productId, movingAvailable: sorting === 'added', isFirst: index === 0, isLast: index === sortedOffers.length - 1, isExpanded: offersAmount <= 2, limitations: limitations, isBeingMoved: movedOfferId === id, onMove: () => setMovedOfferId(id), addToBasket: () => addToBasket(id) }, id)))] }), _jsx("div", { className: classes.bottomGradient })] }));
});
