import { jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { requestSelector } from '@/models/request/selectors';
import { useStyles } from './styles';
export const AccordionTitle = memo(({ servicesList, title, initialCount = 0 }) => {
    const classes = useStyles();
    const { services } = useSelector(requestSelector);
    const servicesCount = useMemo(() => {
        const count = servicesList.reduce((acc, { code }) => {
            if (services[code]) {
                return acc + 1;
            }
            return acc;
        }, initialCount);
        return count;
    }, [servicesList, services, initialCount]);
    return (_jsxs("span", { className: classes.accordionTitleText, children: [title, servicesCount ? (_jsxs("span", { className: classes.accordionServicesCount, children: ["(", servicesCount, ")"] })) : null, ' '] }));
});
