import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { useCheTheme } from '@/styles/themes';
export const ComparingListIcon = memo(({ isActive }) => {
    const theme = useCheTheme();
    const primaryColor = isActive
        ? theme.colors.brand
        : theme.colors.textSecondary;
    const secondaryColor = theme.colors.cloud;
    return (_jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", xmlSpace: "preserve", viewBox: "0 0 1000 1000", width: 22, height: 22, children: [_jsx("path", { fillRule: "evenodd", d: "M928.3 1000h-856c-39.8 0-72-32.2-72-72V788.2c0-39.8 32.2-72 72-72h856c39.8 0 72 32.2 72 72V928c0 39.8-32.2 72-72 72z", clipRule: "evenodd", fill: primaryColor }), _jsx("path", { fill: secondaryColor, d: "M76.6 793.1h847.5v130H76.6z" }), _jsx("path", { fillRule: "evenodd", d: "M928.3 641.9h-856c-39.8 0-72-32.2-72-72V430.1c0-39.8 32.2-72 72-72h856c39.8 0 72 32.2 72 72v139.8c0 39.8-32.2 72-72 72z", clipRule: "evenodd", fill: primaryColor }), _jsx("path", { fill: secondaryColor, d: "M76.6 435h847.5v130H76.6z" }), _jsx("path", { fillRule: "evenodd", d: "M928.3 283.8h-856c-39.8 0-72-32.2-72-72V72c0-39.8 32.2-72 72-72h856c39.8 0 72 32.2 72 72v139.8c0 39.8-32.2 72-72 72z", clipRule: "evenodd", fill: primaryColor }), _jsx("path", { fill: secondaryColor, d: "M76.6 76.9h847.5v130H76.6z" })] }));
});
