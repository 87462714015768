import { createSelector } from '@reduxjs/toolkit';
import { CALCULATION_STATE_NAMESPACE } from './consts';
export const calculationSelector = (state) => state[CALCULATION_STATE_NAMESPACE];
export const minimalPriceSelector = createSelector(calculationSelector, ({ originalCalculations }) => {
    return originalCalculations[0]?.priceRub;
});
export const calculationIsLoadingSelector = createSelector(calculationSelector, ({ isLoading }) => isLoading);
export const calculationLastRequestIdSelector = createSelector(calculationSelector, ({ lastRequestId }) => lastRequestId);
export const matchedOffersSelector = createSelector(calculationSelector, ({ matchedOffers, partiallyOffers }) => [
    ...matchedOffers,
    ...partiallyOffers,
]);
