import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCalculationFranhcise } from '@/libs/hooks';
import { useStyles } from './styles';
export const FranciseTab = ({ offer }) => {
    const classes = useStyles();
    const { value } = useCalculationFranhcise(offer.calculation);
    if (value === 'Нет')
        return (_jsxs(_Fragment, { children: [_jsx("p", { className: classes.tabTitle, children: "\u0412 \u0412\u0430\u0448\u0435\u043C \u043F\u043E\u043B\u0438\u0441\u0435 \u043D\u0435\u0442 \u0444\u0440\u0430\u043D\u0448\u0438\u0437\u044B" }), _jsxs("ul", { children: [_jsx("li", { className: classes.tabText, children: "\u041F\u0440\u0438 \u043D\u0430\u043B\u0438\u0447\u0438\u0438 \u0444\u0440\u0430\u043D\u0448\u0438\u0437\u044B \u0432 \u043F\u043E\u043B\u0438\u0441\u0435, \u0412\u0430\u043C \u043F\u0440\u0438\u0434\u0435\u0442\u0441\u044F \u043E\u043F\u043B\u0430\u0442\u0438\u0442\u044C \u0435\u0435 \u0441\u0430\u043C\u043E\u0441\u0442\u043E\u044F\u0442\u0435\u043B\u044C\u043D\u043E." }), _jsx("li", { className: classes.tabText, children: "\u041E\u043F\u043B\u0430\u0442\u0430 \u043F\u0440\u043E\u0438\u0437\u0432\u043E\u0434\u0438\u0442\u0441\u044F \u0432 \u043A\u043B\u0438\u043D\u0438\u043A\u0435 \u043F\u0440\u0438 \u0441\u0442\u0440\u0430\u0445\u043E\u0432\u043E\u043C \u0441\u043B\u0443\u0447\u0430\u0435. \u0412\u0441\u0435 \u043E\u0441\u0442\u0430\u043B\u044C\u043D\u044B\u0435 \u0440\u0430\u0441\u0445\u043E\u0434\u044B \u043D\u0430 \u043B\u0435\u0447\u0435\u043D\u0438\u0435 (\u0441\u0432\u044B\u0448\u0435 \u0440\u0430\u0437\u043C\u0435\u0440\u0430 \u0444\u0440\u0430\u043D\u0448\u0438\u0437\u044B) \u043A\u043E\u043C\u043F\u0435\u043D\u0441\u0438\u0440\u0443\u0435\u0442 \u0441\u0442\u0440\u0430\u0445\u043E\u0432\u0430\u044F \u043A\u043E\u043C\u043F\u0430\u043D\u0438\u044F." })] })] }));
    return _jsxs("p", { className: classes.tabTitle, children: ["\u0424\u0440\u0430\u043D\u0448\u0438\u0437\u0430: \u00A0", value] });
};
