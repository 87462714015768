import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { motion } from 'framer-motion';
import { Skeleton } from '@che-ins-ui/skeleton';
import { IconsBlock } from '@/components/Showcase/components/IconsBlock';
import { useListStyles } from './listStyles';
export const ComparingUnavailableOffer = ({ name, linkToLogo, id, isLoading, }) => {
    const classes = useListStyles();
    return (_jsx(motion.div, { className: classes.offer, layout: true, initial: { opacity: 0, scale: 0 }, animate: { opacity: 1, scale: 1 }, exit: { opacity: 0, scale: 0 }, children: _jsxs("div", { className: classes.offerHeader, children: [_jsx("img", { className: classes.offerImage, src: linkToLogo, alt: "" }), _jsxs("div", { className: classes.offerTitles, children: [_jsx("span", { className: classes.offerTitle, children: name }), isLoading ? (_jsx(Skeleton, { width: "120px", height: "13px", borderRadius: 7 })) : (_jsx("span", { className: classes.offerDescription, "data-warning": true, children: "\u041E\u0442\u0441\u0443\u0442\u0441\u0442\u0432\u0443\u044E\u0442 \u043D\u0435\u043E\u0431\u0445\u043E\u0434\u0438\u043C\u044B\u0435 \u043E\u043F\u0446\u0438\u0438" }))] }), _jsx(IconsBlock, { company: {
                        id,
                        name,
                        linkToLogo,
                    }, comparingOnly: true, noPaddingRight: true })] }) }));
};
