import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@che-ins-ui/button';
import { ExportIcon } from '@che-ins-ui/icons/ExportIcon';
import { tabsActions } from '@che-ins-ui/tabs';
import { Services } from '@/components/Services';
import { isLoadingPaymentSelector, isOrderCreatingSelector, processActions, } from '@/models/process';
import { basketPriceSelector, basketProductIsMissingSelector, isInsurerInRussianSelector, } from '@/models/basket/selectors';
import { CURRENCIES_BADGES, UPSELL_ENABLED } from '@/libs/constants';
import { shareOrder } from '@/models/process/shareOrder';
import { createOrder } from '@/models/process/createOrder';
import { useCheTheme } from '@/styles/themes';
import { calculationIsLoadingSelector } from '@/models/calculation/selectors';
import { formatPrice } from '@/libs/helpers/utils';
import { emailConfirmationModalOpen } from '@/components/common/EmailConfirmationModal';
import { sendConfirmationCode } from '@/models/process/sendConfirmationCode';
import { isAntiDosWorkingSelector } from '@/models/api/selectors';
import { EmailConfirmationStatuses } from '@/models/process/consts';
import { upsellActions } from '@/models/upsell';
import { fetchUpsell } from '@/models/upsell/fetchUpsell';
import { scrollToTop } from '@/libs/helpers/utils';
import { isIgnoreUpsellSelector } from '@/models/request/selectors';
import { roleSelector } from '@/models/user/selectors';
import { AdminPartnerIdEditBlock } from '@/components/common/AdminPartnerIdEditBlock/AdminPartnerIdEditBlock';
import { isFormValidatedSelector } from '@/models/forms/selectors';
import { validateForms } from '@/models/forms/validateForms';
import { CircleLoader } from '@/components/common/CircleLoader/CircleLoader';
import { INSURANCE_TABS_NAME } from '../consts';
import { InsurerAndTourists } from './InsurerAndTourists';
import { useIsMobileViewInsuranceForm } from './hooks';
import { useStyles } from './styles';
import { GoToCalculationsBtn } from './GoToCalculationsBtn';
import { UserTermsBlock } from './UserTermsBlock';
import { TABS } from './tabs';
import { PaymentButtonTextSvg } from './PaymentButtonTextSvg';
export const OptionsTab = () => {
    const theme = useCheTheme();
    const classes = useStyles();
    const dispatch = useDispatch();
    const isServicesMobileView = useIsMobileViewInsuranceForm();
    const isFormValidated = useSelector(isFormValidatedSelector);
    const isInsurerInRussian = useSelector(isInsurerInRussianSelector);
    const role = useSelector(roleSelector);
    const isAntiDosWorking = useSelector(isAntiDosWorkingSelector);
    const isIgnoreUpsell = useSelector(isIgnoreUpsellSelector);
    const isOrderCreating = useSelector(isOrderCreatingSelector);
    const isLoadingPayment = useSelector(isLoadingPaymentSelector);
    const isLoadingCalculation = useSelector(calculationIsLoadingSelector);
    const productIsMissing = useSelector(basketProductIsMissingSelector);
    const priceInRub = useSelector(basketPriceSelector);
    const paymentButtonRef = useRef(null);
    useEffect(() => {
        dispatch(validateForms());
    }, [isInsurerInRussian]);
    useEffect(() => {
        dispatch(upsellActions.reset());
        scrollToTop();
    }, []);
    const handleGoNext = useCallback(() => {
        paymentButtonRef?.current?.blur();
        if (UPSELL_ENABLED && !isIgnoreUpsell) {
            dispatch(fetchUpsell());
            dispatch(tabsActions.setActiveTab({
                tabsName: INSURANCE_TABS_NAME,
                activePanelName: TABS[2].panelName,
            }));
        }
        else if (isAntiDosWorking) {
            dispatch(processActions.setEmailConfirmationStatus(EmailConfirmationStatuses.none));
            dispatch(sendConfirmationCode());
            dispatch(emailConfirmationModalOpen());
        }
        else {
            dispatch(createOrder({}));
        }
    }, [isIgnoreUpsell]);
    const handleShareOrder = useCallback(() => {
        dispatch(shareOrder());
    }, []);
    useEffect(() => {
        dispatch(processActions.setIsLoadingPayment(false));
    }, []);
    const buttonsDisabled = !isFormValidated ||
        isLoadingPayment ||
        isLoadingCalculation ||
        isOrderCreating;
    return (_jsxs(_Fragment, { children: [_jsxs("div", { children: [_jsx(InsurerAndTourists, {}), _jsx(Services, { isDesktopView: !isServicesMobileView }), _jsx("div", { className: classes.buttons, children: productIsMissing ? (_jsx(GoToCalculationsBtn, {})) : (_jsxs(_Fragment, { children: [role === 'admin' ? (_jsx(AdminPartnerIdEditBlock, { isFormStep: true, handleShareOrder: handleShareOrder })) : (_jsxs(Button, { disabled: buttonsDisabled, onClick: handleShareOrder, shouldFitContent: true, baseAppearance: "outlined", appearance: "continue", children: [_jsx(ExportIcon, { width: 13, height: 17, className: classes.shareIcon, fill: theme.colors.brand }), "\u041E\u0442\u043F\u0440\u0430\u0432\u0438\u0442\u044C \u0441\u0441\u044B\u043B\u043A\u0443"] })), _jsx(Button, { disabled: buttonsDisabled, onClick: handleGoNext, shouldFitContent: true, type: "submit", baseAppearance: "success", appearance: "continue", innerRef: paymentButtonRef, children: isOrderCreating ? (_jsx(CircleLoader, { color: theme.colors.white })) : (_jsxs(_Fragment, { children: [_jsx(PaymentButtonTextSvg, {}), _jsxs("b", { children: [formatPrice(priceInRub), " \u00A0", CURRENCIES_BADGES['rub']] })] })) })] })) })] }), _jsx(UserTermsBlock, {})] }));
};
