import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { useSelector } from 'react-redux';
import { stringify } from 'qs';
import { motion } from 'framer-motion';
import { useCheTheme } from '@/styles/themes';
import { ASSETS_SRC } from '@/libs/constants';
import { isIframe } from '@/libs/helpers';
import { isAuthenticatedSelector } from '@/models/user/selectors';
import { useStyles } from './styles';
export const AuthButton = memo(() => {
    const theme = useCheTheme();
    const classes = useStyles();
    const isAuthenticated = useSelector(isAuthenticatedSelector);
    const onAuthClick = () => {
        window.location.href =
            __HOMEPAGE__ +
                '/authpage?' +
                stringify({ urlToReturn: location.href });
    };
    if (isAuthenticated || isIframe()) {
        return null;
    }
    return (_jsx(motion.div, { initial: { scale: 1 }, animate: { scale: [1, 1.05, 1] }, transition: {
            scale: {
                delay: 3,
                repeatDelay: 7,
                repeat: Infinity,
                stiffness: 600,
                damping: 20,
            },
        }, whileHover: { scale: 1 }, style: { width: 'fit-content' }, children: _jsxs(motion.button, { initial: { backgroundColor: theme.colors.textBackground }, animate: { backgroundColor: theme.colors.textBackground }, whileHover: { backgroundColor: theme.colors.brandTertiary }, whileTap: { scale: 1 }, className: classes.root, onClick: (e) => {
                e.preventDefault();
                e.stopPropagation();
                onAuthClick();
            }, children: [_jsx("img", { src: `${ASSETS_SRC.IMAGES}/keys-icon.png`, className: classes.authImage }), _jsxs("div", { children: [_jsx("p", { className: classes.authTitle, children: "\u0410\u0432\u0442\u043E\u0440\u0438\u0437\u0443\u0439\u0442\u0435\u0441\u044C" }), _jsx("p", { className: classes.authText, children: "\u0418 \u043C\u044B \u0437\u0430\u043F\u043E\u043B\u043D\u0438\u043C \u0430\u043D\u043A\u0435\u0442\u0443 \u0437\u0430 \u0432\u0430\u0441!" })] })] }) }));
});
