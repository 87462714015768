const SKI_TAGS = ['ski', 'лыжи', 'лыжн'];
const SURFING_TAGS = ['surf', 'серф', 'сёрф'];
const BOAT = ['boat', 'лодка'];
const MOUNTAINS = ['mountains', 'горы', 'горн', 'гора'];
export const commonSportSearchTags = {
    mountaineering: MOUNTAINS,
    climbing: MOUNTAINS,
    mountainTrekking: MOUNTAINS,
    mountainSkiing: [...MOUNTAINS, ...SKI_TAGS],
    mountainSkiingFreeride: [...MOUNTAINS, ...SKI_TAGS],
    skiing: SKI_TAGS,
    skiFreestyle: SKI_TAGS,
    surfing: SURFING_TAGS,
    kiting: SURFING_TAGS,
    windsurfing: SURFING_TAGS,
    motorboat: BOAT,
    boating: BOAT,
};
export const competitionSportsSearchTags = {
    mountaineeringCompetition: MOUNTAINS,
    mountainTrekkingCompetition: MOUNTAINS,
    climbingCompetition: MOUNTAINS,
    mountainSkiingCompetition: [...MOUNTAINS, ...SKI_TAGS],
    mountainSkiingFreerideCompetition: [...MOUNTAINS, ...SKI_TAGS],
    skiingCompetition: SKI_TAGS,
    skiFreestyleCompetition: SKI_TAGS,
    surfingCompetition: SURFING_TAGS,
    kitingCompetition: SURFING_TAGS,
    windsurfingCompetition: SURFING_TAGS,
};
