const countableItems = {
    AGE: ['год', 'года', 'лет'],
    PERSON: ['человек', 'человека', 'человек'],
    TOURIST: ['турист', 'туриста', 'туристов'],
    DAY: ['день', 'дня', 'дней'],
    SELECTED: ['Выбран', 'Выбрано', 'Выбрано'],
    USD: ['доллар', 'доллара', 'долларов'],
    EUR: ['евро', 'евро', 'евро'],
    RUB: ['рубль', 'рубля', 'рублей'],
    SERVICE: ['Опция', 'Опции', 'Опций'],
    SERVICESMALL: ['опция', 'опции', 'опций'],
    COUNTRY: ['страна', 'страны', 'стран'],
    OFFER: ['предложение', 'предложения', 'предложений'],
    POLICY: ['полис', 'полиса', 'полисов'],
    BACKSPACE: ['пробела', 'пробелов', 'пробелов'],
};
export function getTypes(type) {
    return countableItems[type];
}
export const MULTIPOLICY_SERVICE_NAME = 'multipolicy';
export const PREGNANCY_SERVICE_NAME = 'pregnancy';
export const recommendServices = ['sunburnAssistance'];
export const recommendSports = ['motorcycle'];
export const popularServices = [
    MULTIPOLICY_SERVICE_NAME,
    'abroad',
    'foreign',
    'franchise',
    'covid',
    'observation',
    'disability',
];
export const additionalServices = [
    PREGNANCY_SERVICE_NAME,
    'chronicArresting',
    'sunburnAssistance',
    'allergyArresting',
    'childrenReturn',
    'disasterAssistance',
    'alcoholAssistance',
    'denge',
    'insuredReturn',
    'thirdPartyTransportation',
    'relativeIllReturn',
    'terrorAssistance',
];
export const workAbroadServices = ['work', 'office'];
export const educationServices = ['education'];
export const activeRestServices = [
    'rescueActivities',
    'searchActivities',
    'divingChamber',
];
export const tripCancellationServices = [
    'tripCancel',
    'visaCancel',
    'isolation',
];
export const improverServices = [
    'accident',
    'aviaCargo',
    'civilLiability',
    'delayCargo',
    'regularDelay',
    'document',
    'legal',
    'auto',
];
export const MULTIPOLICY_FULL_YEAR_VALUE = 'full-year';
export const FRANCHISE_SERVICE = {
    code: 'franchise',
    name: 'Без франшизы',
    description: 'Покажем страховки без франшизы. При страховом случае, все расходы по лечению возмещает страховая компания',
    serviceValues: [0, 1],
};
