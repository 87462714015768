import { getPregnancyLabel } from '@/libs/helpers/utils';
import { PREGNANCY_SERVICE_NAME } from '@/libs/static';
export const formatMultiValueByCode = (code, value, params = {}) => {
    switch (code) {
        case PREGNANCY_SERVICE_NAME:
            return getPregnancyLabel(value);
        case 'observation':
        case 'accident':
        case 'aviaCargo':
        case 'civilLiability':
        case 'tripCancel':
        case 'medicine':
            return `${value} ${params.currencyBadge}`;
        default:
            return value;
    }
};
