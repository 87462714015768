import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import { comparingDifferencesOnlySelector } from '@/models/comparing/selectors';
import { ComparingListOfferServiceTag } from './ComparingListOfferServiceTag';
import { useListStyles } from './listStyles';
export const ComparingListOfferServices = memo(({ services, missingServices, shouldHighlightMissing, repeatingServices, comparingClickedProducts, comparingHoveredProduct, }) => {
    const classes = useListStyles();
    const differencesOnly = useSelector(comparingDifferencesOnlySelector);
    const missingSelectedServices = comparingClickedProducts?.filter((service) => ![...services, ...missingServices].some(({ service: missingService }) => missingService === service) && !repeatingServices.includes(service));
    return (_jsxs("div", { className: classes.offerServices, children: [_jsx("div", { className: classes.offerServicesColumn, children: services.map(({ service, postfix, description, isRepeating }) => (_jsx(ComparingListOfferServiceTag, { service: service, postfix: postfix, description: description, isActive: comparingHoveredProduct === service, isClicked: comparingClickedProducts.includes(service), isHidden: differencesOnly && isRepeating }, service))) }), _jsxs("div", { className: classes.offerServicesColumn, children: [missingServices.map(({ service, postfix, description, isRepeating }) => (_jsx(ComparingListOfferServiceTag, { service: service, postfix: postfix, description: description, isDisadvantage: true, isActive: comparingHoveredProduct === service ||
                            (shouldHighlightMissing &&
                                service !== 'Франшиза'), isClicked: comparingClickedProducts.includes(service), isHidden: differencesOnly && isRepeating }, service))), Boolean(missingSelectedServices.length) && (_jsxs(motion.span, { initial: { opacity: 0, y: 5 }, animate: { opacity: 1, y: 0 }, transition: { type: 'ease', duration: 0.1 }, className: classes.missingSelectedLabel, children: ["\u0421\u0440\u0430\u0432\u043D\u0438\u0432\u0430\u0435\u043C\u044B\u0435:", ' '] })), missingSelectedServices.map((service) => (_jsx(ComparingListOfferServiceTag, { service: service, appearsFromBelow: true, isDisadvantage: true, isActive: true, isClicked: comparingClickedProducts.includes(service), postfix: "\u041E\u0442\u0441\u0443\u0442\u0441\u0442\u0432\u0443\u0435\u0442" }, service)))] })] }));
});
