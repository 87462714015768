import { activeRestServices, additionalServices, popularServices, recommendServices, workAbroadServices, educationServices, tripCancellationServices, improverServices, } from '../static';
function carriedFilterService(availableServices) {
    return ({ code }) => availableServices.includes(code);
}
export const getAdditionalServices = (apiServices) => {
    return apiServices.filter(carriedFilterService(additionalServices));
};
export const getPopularServices = (apiServices) => {
    return apiServices.filter(carriedFilterService(popularServices));
};
export const getMandatoryServices = (apiServices) => {
    return apiServices.filter(({ serviceValues, code }) => code !== 'pcr' &&
        (serviceValues.length === 1 || code === 'urgentStomatology'));
};
export const getRecomendServices = (apiServices) => {
    return apiServices.filter(carriedFilterService(recommendServices));
};
export const getWorkAbroadServices = (apiServices) => {
    return apiServices.filter(carriedFilterService(workAbroadServices));
};
export const getEducationServices = (apiServices) => {
    return apiServices.filter(carriedFilterService(educationServices));
};
export const getActiveRestServices = (apiServices) => {
    return apiServices.filter(carriedFilterService(activeRestServices));
};
export const getTripCancellationServices = (apiServices) => {
    return apiServices.filter(carriedFilterService(tripCancellationServices));
};
export const getImproverServices = (apiServices) => {
    return apiServices.filter(carriedFilterService(improverServices));
};
export const transformServicesArrayToDictionary = (apiServices) => {
    return apiServices.reduce((acc, { code, ...service }) => ({ ...acc, [code]: service }), {});
};
