import { jsx as _jsx } from "react/jsx-runtime";
import { memo } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from '@che-ins-ui/button';
import { requestActions } from '@/models/request';
import { comparingActions } from '@/models/comparing';
export const ResetButton = memo(() => {
    const dispatch = useDispatch();
    return (_jsx(Button, { baseAppearance: "text", appearance: "filters-reset", onClick: () => {
            dispatch(requestActions.setDefault());
            dispatch(comparingActions.setDefault());
        }, children: "\u0421\u0431\u0440\u043E\u0441\u0438\u0442\u044C" }));
});
