import color from 'color';
import { BREAKPOINTS } from './breakpoints';
const MEDIA_SCREEN = '@media screen';
const getBreakPoint = (value) => {
    let breakpoint = null;
    if (typeof value === 'number') {
        breakpoint = value;
    }
    else {
        breakpoint = BREAKPOINTS[value];
    }
    return breakpoint;
};
export function mediaMinWidth(value) {
    return `${MEDIA_SCREEN} and (min-width: ${getBreakPoint(value)}px)`;
}
export function mediaMaxWidth(value) {
    return `${MEDIA_SCREEN} and (max-width: ${getBreakPoint(value) - 1}px)`;
}
export function mediaBetweenWidth(minValue, maxValue) {
    return `${MEDIA_SCREEN} and (min-width: ${getBreakPoint(minValue)}px) and (max-width: ${getBreakPoint(maxValue) - 1}px)`;
}
export const withAlpha = (colour, alpha) => color(colour).alpha(alpha).toString();
export const darken = (colour, value) => color(colour).darken(value).toString();
