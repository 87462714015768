import { createUseStyles } from 'react-jss';
import { DESKTOP_FILTERS_BREAKPOINT } from '@/styles/themes/components';
export const useStyles = createUseStyles((theme) => ({
    root: {
        width: '100%',
    },
    aside: {
        display: 'none',
        maxWidth: 320,
        flexShrink: 0,
        [theme.breakpoints.up(DESKTOP_FILTERS_BREAKPOINT)]: {
            display: 'initial',
        },
    },
    main: {
        flexGrow: 1,
    },
}), { name: 'page' });
