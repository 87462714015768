import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useSelector } from 'react-redux';
import { basketProductSelector } from '@/models/basket/selectors';
import { useStyles } from './styles';
const getLinkToKID = (company) => {
    const companiesWithKid = [
        'absolut',
        'alfa',
        'energogarant',
        'renins',
        'rstandart',
        'sberbank',
        'soglasie',
        'sovcombank',
        'tinkoff',
        'rosbank',
        'reso',
        'zetta',
    ];
    if (company && companiesWithKid.includes(company)) {
        return __HOMEPAGE__ + '/files/vzr/kid/' + company + '.pdf';
    }
};
export const UserTermsBlock = () => {
    const classes = useStyles();
    const product = useSelector(basketProductSelector);
    const linkToKID = getLinkToKID(product?.company.code);
    return (_jsxs("div", { className: classes.termsAndAgreement, children: [_jsxs("span", { children: ["\u041D\u0430\u0436\u0438\u043C\u0430\u044F \u043A\u043D\u043E\u043F\u043A\u0443 \u00AB\u041F\u0435\u0440\u0435\u0439\u0442\u0438 \u043A \u043E\u043F\u043B\u0430\u0442\u0435\u00BB \u044F", linkToKID && (_jsxs(_Fragment, { children: [' ', "\u043F\u043E\u0434\u0442\u0432\u0435\u0440\u0436\u0434\u0430\u044E, \u0447\u0442\u043E \u043E\u0437\u043D\u0430\u043A\u043E\u043C\u043B\u0435\u043D \u0441", ' ', _jsx("a", { target: "_blank", href: linkToKID, style: { whiteSpace: 'nowrap' }, children: "\u041A\u043B\u044E\u0447\u0435\u0432\u044B\u043C \u0438\u043D\u0444\u043E\u0440\u043C\u0430\u0446\u0438\u043E\u043D\u043D\u044B\u043C \u0434\u043E\u043A\u0443\u043C\u0435\u043D\u0442\u043E\u043C" }), ' ', "\u0438"] })), ' ', "\u0441\u043E\u0433\u043B\u0430\u0448\u0430\u044E\u0441\u044C \u0441"] }), _jsx("a", { target: "_blank", href: product?.company.linkToInsuranceRules, children: "\u00A0\u041F\u0440\u0430\u0432\u0438\u043B\u0430\u043C\u0438 \u0441\u0442\u0440\u0430\u0445\u043E\u0432\u0430\u043D\u0438\u044F" })] }));
};
