import { createUseStyles } from 'react-jss';
import { SCRIPTS } from '@/libs/constants';
import { GLOBAL_LETTER_SPACING, GLOBAL_LINE_HEIGHT } from './themes/components';
export const useGlobalStyles = createUseStyles((theme) => ({
    '@import': `url(${SCRIPTS.FONTS})`,
    '@global': {
        '*, *::before, *::after': {
            boxSizing: 'border-box',
            margin: 0,
            padding: 0,
        },
        html: {
            minHeight: '100vh',
        },
        body: {
            minHeight: '100vh',
            minWidth: 320,
            overflowX: 'hidden',
            lineHeight: GLOBAL_LINE_HEIGHT,
            letterSpacing: GLOBAL_LETTER_SPACING,
            background: theme.colors.background,
            font: {
                family: theme.font.primary,
                weight: 400,
                size: theme.font.baseSize,
            },
        },
        input: {
            width: '100%',
            webkitAppearance: 'none',
            appearance: 'none',
            fontFamily: theme.font.primary,
            border: {
                radius: 0,
            },
            '&::-ms-clear, &::-ms-reveal': {
                display: 'none',
            },
            transition: theme.transition.global,
            '&:-webkit-autofill': {
                '-webkit-text-fill-color': theme.colors.sky + '!important',
                caretColor: theme.colors.sky + ' !important',
                backgroundColor: 'transparent !important',
                fontFamily: theme.font.primary + ' !important',
                '-webkit-background-clip': 'text',
                boxShadow: 'none !important',
            },
        },
        a: {
            textDecoration: 'none',
            '&:hover': {},
        },
        button: {
            cursor: 'pointer',
            fontFamily: theme.font.primary,
            backgroundColor: 'transparent',
            border: 'none',
        },
        ul: {
            listStyle: 'none',
        },
        '#cherehapa-root': {
            position: 'relative',
            minHeight: '100vh',
            display: 'flex',
            flexDirection: 'column',
        },
        '.wrapper': {
            display: 'flex',
            flexDirection: 'column',
            flex: '1 0 auto',
        },
        '.content': {
            flex: '1 0 auto',
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
        },
        '.art': {
            display: 'none',
            [theme.breakpoints.up('xl')]: {
                display: 'block',
                position: 'absolute',
                top: 0,
                left: 0,
                zIndex: theme.zIndex.base,
            },
        },
        '.footer': {
            flex: '0 0 auto',
        },
        'input[type="date"]::-webkit-calendar-picker-indicator': {
            opacity: 0,
            display: 'none',
            '-webkit-appearance': 'none',
        },
    },
}));
