import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { requestSelector } from '@/models/request/selectors';
import { ABROAD_CODE } from '@/libs/static';
import { requestActions } from '@/models/request';
import { isRussiaInfoOpenedSelector } from '@/models/functional/selectors';
import { functionalActions } from '@/models/functional';
import { analytics } from '@/libs/analytics';
import { useStyles } from './styles';
import { MustReadInfoOptionEnabler } from './MustReadInfoOptionEnabler';
import { MustReadInfoBlock } from './MustReadInfoBlock';
let hasSentEvent = false;
const onEnable = () => {
    if (!hasSentEvent) {
        hasSentEvent = true;
        analytics.sendEvent({
            action: 'travel_russia_recommendations_selection',
            label: 'Действие пользователя',
            category: 'Cherehapa',
        });
    }
};
export const MustReadRussia = memo(() => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { services } = useSelector(requestSelector);
    const isOpened = useSelector(isRussiaInfoOpenedSelector);
    const setIsOpened = (value) => {
        dispatch(functionalActions.setIsRussiaInfoOpened(value));
    };
    const tabIndex = isOpened ? 0 : -1;
    const isForeignEnabled = Boolean(services?.[ABROAD_CODE]);
    const allEnabled = isForeignEnabled;
    const toggleService = (serviceCode) => {
        dispatch(requestActions.setServices({
            [serviceCode]: services[serviceCode] ? 0 : 1,
        }));
    };
    return (_jsx(MustReadInfoBlock, { isOpened: isOpened, setIsOpened: setIsOpened, icon: _jsx("svg", { width: 32, height: 32, viewBox: "0 -960 960 960", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { d: "m300-300 280-80 80-280-280 80-80 280Zm180-120q-25 0-42.5-17.5T420-480q0-25 17.5-42.5T480-540q25 0 42.5 17.5T540-480q0 25-17.5 42.5T480-420Zm0 340q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q133 0 226.5-93.5T800-480q0-133-93.5-226.5T480-800q-133 0-226.5 93.5T160-480q0 133 93.5 226.5T480-160Zm0-320Z" }) }), title: "\u041F\u0443\u0442\u0435\u0448\u0435\u0441\u0442\u0432\u0443\u0435\u0442\u0435 \u043F\u043E \u0420\u043E\u0441\u0441\u0438\u0438?", areAllItemsEnabled: allEnabled, children: _jsx("div", { className: classes.infoContainer, children: _jsxs("div", { children: [_jsx("p", { className: classes.info, children: "\u0415\u0441\u043B\u0438 \u043D\u0430 \u043C\u043E\u043C\u0435\u043D\u0442 \u043F\u043E\u043A\u0443\u043F\u043A\u0438 \u043F\u043E\u043B\u0438\u0441\u0430 \u0442\u0443\u0440\u0438\u0441\u0442\u044B \u043F\u043E\u043A\u0438\u043D\u0443\u043B\u0438 \u043C\u0435\u0441\u0442\u043E \u043F\u043E\u0441\u0442\u043E\u044F\u043D\u043D\u043E\u0433\u043E \u043F\u0440\u043E\u0436\u0438\u0432\u0430\u043D\u0438\u044F (\u043D\u0430\u0445\u043E\u0434\u044F\u0442\u0441\u044F \u0431\u043E\u043B\u0435\u0435 \u0447\u0435\u043C \u0432 90 \u043A\u043C \u043E\u0442 \u0433\u0440\u0430\u043D\u0438\u0446\u044B \u043D\u0430\u0441\u0435\u043B\u0435\u043D\u043D\u043E\u0433\u043E \u043F\u0443\u043D\u043A\u0442\u0430), \u043F\u0440\u0438\u043C\u0435\u043D\u0438\u0442\u0435 \u0443\u0441\u043B\u043E\u0432\u0438\u0435:" }), [
                        {
                            label: 'Я уже за границей',
                            isActive: isForeignEnabled,
                            onCheck: () => toggleService(ABROAD_CODE),
                        },
                    ].map(({ label, isActive, onCheck }) => (_jsx(MustReadInfoOptionEnabler, { label: label, isActive: isActive, onCheck: () => {
                            onCheck();
                            onEnable();
                        }, tabIndex: tabIndex }, label)))] }) }) }));
});
