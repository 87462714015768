import { createUseStyles } from 'react-jss';
import { HEADER_HEIGHT } from '../common/Header/styles';
export const useStyles = createUseStyles((theme) => ({
    componentWrapper: {
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'center',
        marginTop: HEADER_HEIGHT,
    },
}), { name: 'not-working' });
