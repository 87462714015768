import { MULTIPOLICY_SERVICE_NAME } from '@/libs/static';
export const REQUEST_STATE_NAMESPACE = 'request';
export const DATE_START_INTERVAL_FROM_TODAY = 3;
export const CHEREHAPA_PARTNER_ID = 1;
export const ALL_SERVICE_DEFAULT = {
    medicine: 50000,
    [MULTIPOLICY_SERVICE_NAME]: 30,
    observation: 1500,
    tripCancel: 500,
    accident: 1000,
    aviaCargo: 500,
    civilLiability: 10000,
};
export const SERVICE_DEFAULT = {
    medicine: ALL_SERVICE_DEFAULT.medicine,
    covid: 1,
    urgentStomatology: 1,
};
export const DEFAULT_MARKETING_PARAMS = {
    partnerId: null,
    marker: null,
    marker2: null,
    srcsale: null,
};
export const currencies = ['eur', 'usd'];
