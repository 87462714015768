import { createUseStyles } from 'react-jss';
import { BLOCK_BOX_SHADOW, DESKTOP_FILTERS_BREAKPOINT, GLOBAL_LETTER_SPACING, MOBILE_FONT_SIZES, } from '@/styles/themes/components';
import { withAlpha } from '@/styles/utils';
export const useStyles = createUseStyles((theme) => ({
    root: {
        boxShadow: BLOCK_BOX_SHADOW,
        width: '100%',
        borderRadius: 12,
        backgroundColor: theme.colors.cloud,
    },
    titleButton: {
        width: '100%',
        padding: '12px 7px 12px 10px',
        display: 'flex',
        alignItems: 'center',
        borderRadius: 12,
        gap: 10,
        outlineOffset: -1,
        cursor: 'pointer',
        '& h3': {
            fontSize: MOBILE_FONT_SIZES.hintModalTitle,
            fontWeight: 700,
            color: theme.colors.sky,
        },
        [theme.breakpoints.up(DESKTOP_FILTERS_BREAKPOINT)]: {
            padding: '10px 7px 10px 12px',
        },
    },
    logo: {
        flexShrink: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& svg > path': {
            fill: theme.colors.warning,
            transition: theme.transition.global,
        },
        '&[data-all-enabled="true"]': {
            '& svg > path': {
                fill: theme.colors.success,
            },
        },
    },
    dropdownIcon: {
        marginLeft: 'auto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 36,
        width: 36,
        borderRadius: 999,
        transition: theme.transition.global,
        '& svg > path': {
            fill: theme.colors.textSecondary,
        },
        '&:active, &:hover': {
            backgroundColor: theme.colors.textBackground,
        },
    },
    infoContainer: {
        padding: '0 12px 12px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 6,
    },
    info: {
        fontSize: MOBILE_FONT_SIZES.small,
        color: theme.colors.sky,
        letterSpacing: GLOBAL_LETTER_SPACING,
        marginBottom: 6,
        [theme.breakpoints.up(DESKTOP_FILTERS_BREAKPOINT)]: {
            textWrap: 'balance',
        },
    },
    optionEnabler: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        padding: '5px 20px 5px 8px',
        borderRadius: 8,
        gap: 8,
        '&:active, &:hover': {
            backgroundColor: withAlpha(theme.colors.success, 0.1),
        },
        [theme.breakpoints.up(DESKTOP_FILTERS_BREAKPOINT)]: {
            width: 'fit-content',
        },
    },
    optionIconContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexShrink: 0,
    },
    optionIcon: {
        height: 20,
        width: 20,
        borderRadius: 6,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: `1px solid ${theme.colors.textSecondary}`,
        '& svg > path': {
            fill: theme.colors.textSecondary,
        },
        '&[data-active="true"]': {
            borderColor: theme.colors.success,
            backgroundColor: theme.colors.success,
            '& svg > path': {
                fill: theme.colors.white,
            },
        },
    },
    optionLabel: {
        fontSize: MOBILE_FONT_SIZES.small,
        color: theme.colors.sky,
        letterSpacing: GLOBAL_LETTER_SPACING,
    },
}), { name: 'must-read-info' });
