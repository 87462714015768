import { createUseStyles } from 'react-jss';
import { withAlpha } from '@/styles/utils';
import { DEKSTOP_FONT_SIZES, DESKTOP_SIZES_BREAKPOINT, GLOBAL_LETTER_SPACING, MOBILE_FONT_SIZES, } from '@/styles/themes/components';
export const useStyles = createUseStyles((theme) => ({
    root: {
        display: 'flex',
        padding: '9px 12px',
        gap: 10,
        borderRadius: 12,
        backgroundColor: theme.colors.cloud,
        boxShadow: '0px 4px 30px 0px rgba(0, 0, 0, 0.05)',
        border: `2px solid ${withAlpha(theme.colors.brand, 0.4)}`,
        [theme.breakpoints.up(DESKTOP_SIZES_BREAKPOINT)]: {
            padding: '12px 20px',
            alignItems: 'center',
            gap: 16,
        },
    },
    image: {
        width: 32,
        height: 32,
        flexShrink: 0,
        transformOrigin: 'center bottom',
        marginTop: 1,
        [theme.breakpoints.up(DESKTOP_SIZES_BREAKPOINT)]: {
            marginTop: 0,
        },
    },
    title: {
        fontFamily: theme.font.primary,
        fontSize: MOBILE_FONT_SIZES.main,
        color: theme.colors.sky,
        fontWeight: 700,
        letterSpacing: GLOBAL_LETTER_SPACING,
        marginBottom: 1,
    },
    text: {
        fontFamily: theme.font.primary,
        fontSize: MOBILE_FONT_SIZES.small,
        color: theme.colors.textPrimary,
        letterSpacing: GLOBAL_LETTER_SPACING,
        fontWeight: 400,
        textWrap: 'balance',
        [theme.breakpoints.up(DESKTOP_SIZES_BREAKPOINT)]: {
            fontSize: DEKSTOP_FONT_SIZES.small,
        },
    },
}), { name: 'abroad-date-info' });
