import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useMeasure } from 'react-use';
import { ComparingListOffer } from './ComparingListOffer';
import { ComparingUnavailableOffer } from './ComparingUnavailableOffer';
import { useListStyles } from './listStyles';
export const ComparingListOffers = memo(({ sortedOffers, missing, comparingClickedProducts, comparingHoveredProduct, repeatingServices, initialAnimationShown, addToBasket, }) => {
    const classes = useListStyles();
    const [ref, { height }] = useMeasure();
    const rootAnimation = height ? { height } : {};
    return (_jsx(motion.div, { className: classes.offers, initial: rootAnimation, animate: rootAnimation, transition: {
            duration: 0.2,
            height: { duration: 0.1 },
            layout: {
                duration: 0.2,
            },
        }, children: _jsx("div", { ref: ref, className: classes.offersWrapper, children: _jsxs(AnimatePresence, { initial: initialAnimationShown, mode: "popLayout", children: [sortedOffers.map(({ company: { id, name, linkToLogo }, info: { price, servicesCount, productId, services, missingServices, missingServicesCount, limitations, }, }) => (_jsx(ComparingListOffer, { id: id, name: name, linkToLogo: linkToLogo, addToBasket: () => addToBasket(id), price: price, servicesCount: servicesCount, productId: productId, services: services, missingServices: missingServices, missingServicesCount: missingServicesCount, repeatingServices: repeatingServices, comparingClickedProducts: comparingClickedProducts, comparingHoveredProduct: comparingHoveredProduct, limitations: limitations }, id))), Boolean(missing.length) && (_jsx(motion.span, { initial: { opacity: 0, x: -30 }, animate: { opacity: 1, x: 0 }, exit: { opacity: 0, x: 30 }, layout: true, className: classes.notMatchingTypeLabel, children: "\u041D\u0435 \u0441\u043E\u043E\u0442\u0432\u0435\u0442\u0441\u0442\u0432\u0443\u044E\u0442" }, "label")), missing.map(({ company: { id, name, linkToLogo } }) => (_jsx(ComparingUnavailableOffer, { id: id, name: name, linkToLogo: linkToLogo }, id)))] }) }) }));
});
