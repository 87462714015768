import { createUseStyles } from 'react-jss';
import { MOBILE_FONT_SIZES, DESKTOP_SIZES_BREAKPOINT, DEKSTOP_FONT_SIZES, } from '@/styles/themes/components';
export const useStyles = createUseStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '900px',
        background: theme.colors.cloud,
        padding: '15px',
        borderRadius: '12px',
        boxShadow: '0px 4px 30px rgba(0, 0, 0, 0.05)',
        [theme.breakpoints.down('md')]: {
            padding: '10px',
        },
    },
    companyShortDescription: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'row',
        marginBottom: '25px',
    },
    companyLogo: {
        marginRight: '10px',
        width: '32px',
        height: '32px',
        borderRadius: '9px',
    },
    companyNameBlock: {},
    companyName: {
        fontWeight: 400,
        color: theme.colors.sky,
        fontSize: MOBILE_FONT_SIZES.main,
        [theme.breakpoints.up(DESKTOP_SIZES_BREAKPOINT)]: {
            fontSize: DEKSTOP_FONT_SIZES.main,
        },
    },
    companyNumberServices: {
        fontWeight: 400,
        color: theme.colors.textPrimary,
        fontSize: MOBILE_FONT_SIZES.small,
        textTransform: 'lowercase',
        [theme.breakpoints.up(DESKTOP_SIZES_BREAKPOINT)]: {
            fontSize: DEKSTOP_FONT_SIZES.small,
        },
    },
    warningIcon: {
        width: '18px',
        height: '18px',
        marginBottom: '2px',
    },
    serviceBox: {
        display: 'flex',
        flexDirection: 'column',
    },
    services: {
        background: theme.colors.cloud,
        paddingBottom: '5px',
        display: 'flex',
        flexDirection: 'column',
        rowGap: '7px',
    },
    servicesDesktop: {
        columnGap: '40px',
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    service: {
        display: 'flex',
        alignItems: 'center',
    },
    serviceDesktop: {
        minWidth: '314px',
        flexGrow: 1,
        maxWidth: 'calc(50% - 20px)',
    },
    serviceName: {
        fontWeight: 400,
        color: theme.colors.sky,
        margin: '0 0 0 7px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        flexShrink: 1,
        fontSize: MOBILE_FONT_SIZES.main,
        [theme.breakpoints.up(DESKTOP_SIZES_BREAKPOINT)]: {
            fontSize: DEKSTOP_FONT_SIZES.main,
        },
    },
    serviceHint: {
        display: 'inline-block',
    },
    serviceNameText: {
        margin: '0 7px 0 0',
    },
    serviceValue: {
        color: theme.colors.sky,
        fontWeight: 700,
        flexShrink: 0,
        fontSize: MOBILE_FONT_SIZES.main,
        [theme.breakpoints.up(DESKTOP_SIZES_BREAKPOINT)]: {
            fontSize: DEKSTOP_FONT_SIZES.main,
        },
    },
    sports: {
        color: theme.colors.textPrimary,
        fontSize: MOBILE_FONT_SIZES.main,
        [theme.breakpoints.up(DESKTOP_SIZES_BREAKPOINT)]: {
            fontSize: DEKSTOP_FONT_SIZES.main,
        },
    },
    serviceIcon: {
        flexShrink: 0,
        marginLeft: '3px',
        marginRight: '3px',
        '& .alert': {
            marginInline: '-3px -2px',
        },
    },
    sportValues: {
        color: theme.colors.sky,
        fontWeight: '700',
        marginLeft: '5px',
    },
    dashedLine: {
        borderBottom: '1px dashed rgba(113, 130, 153, 0.25)',
        flexGrow: 1,
        height: '2px',
        alignSelf: 'flex-end',
        margin: '0px 4px 4px 4px',
    },
    restrictionsWrapper: {
        marginTop: 25,
        display: 'flex',
        flexDirection: 'column',
        gap: 12,
        marginLeft: -1,
    },
    restrictionsTitle: {
        color: theme.colors.sky,
        fontSize: theme.rem(18),
        fontWeight: 700,
        letterSpacing: -0.3,
        alignSelf: 'stretch',
        display: 'flex',
        alignItems: 'center',
        gap: 5,
    },
    restrictionsList: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: 2,
    },
    restrictionsItem: {
        display: 'flex',
        alignItems: 'flex-start',
        gap: 9,
    },
    restrictionText: {
        fontSize: theme.rem(15),
        lineHeight: 1.267,
        fontWeight: 400,
        letterSpacing: -0.3,
        color: theme.colors.textPrimary,
        [theme.breakpoints.up(DESKTOP_SIZES_BREAKPOINT)]: {
            fontSize: theme.rem(13),
            lineHeight: 1.38,
        },
    },
    signalIconWrapper: {
        display: 'flex',
        padding: '2px 0px',
        justifyContent: 'center',
        alignItems: 'flex-start',
        marginLeft: 2,
    },
    optionsTitle: {
        color: theme.colors.sky,
        fontSize: theme.rem(18),
        lineHeight: 1.3888,
        fontWeight: 700,
        letterSpacing: -0.3,
        marginBottom: 12,
    },
}), { name: 'serviceShow' });
