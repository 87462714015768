import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useEffect } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { nanoid } from '@reduxjs/toolkit';
import classNames from 'classnames';
import { Button } from '@che-ins-ui/button';
import { SettingsIcon } from '@che-ins-ui/icons/SettingsIcon';
import { tabsActions } from '@che-ins-ui/tabs';
import { calculationIsLoadingSelector, calculationSelector, matchedOffersSelector, } from '@/models/calculation/selectors';
import { basketActions } from '@/models/basket';
import { scrollToTop } from '@/libs/helpers';
import { useCalculationQueryString } from '@/libs/hooks/useCalculationQueryString';
import { MODAL_PRODUCT_ID_QUERY_PARAM, RESTORABLE_QS_PARAMS, } from '@/libs/constants';
import { INSURANCE_TABS_NAME } from '../insurance/consts';
import { OfferNotFoundModal, offerNotFoundModalOpen, } from '../common/Hint/OfferNotFoundModal';
import { ROUTES } from '../routes';
import { TABS } from '../insurance/components';
import { ComparingModal } from '../common/Comparing/ComparingModal';
import { ComparingButtonOffers } from '../common/Comparing/ComparingButtonOffers';
import { CommissionButton } from '../common/CommissionButton/CommissionButton';
import Offers, { OffersSkeleton } from './components/Offers';
import { useStyles } from './styles';
import { OfferModal } from './components/OfferModal';
import { offerModalClose, offerModalOpen, } from './components/OfferModal/actions';
import { AbroadlMoreOffersDateInfo } from './components/AbroadlMoreOffersDateInfo/AbroadlMoreOffersDateInfo';
export { MoreExpensiveTomorrowAlert } from './components/MoreExpensiveTomorrowAlert';
const Showcase = () => {
    const { matchedOffers, partiallyOffers, missingOffers } = useSelector(calculationSelector);
    const classes = useStyles();
    const isLoadingCalculation = useSelector(calculationIsLoadingSelector);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [search] = useSearchParams();
    const queryString = useCalculationQueryString({
        addParams: { [RESTORABLE_QS_PARAMS.mobileFilters]: 'true' },
    });
    const offers = useSelector(matchedOffersSelector);
    const handleAddToBasket = (offer) => {
        dispatch(basketActions.addToBasket({
            product: offer,
            lastChangesId: nanoid(),
        }));
        scrollToTop({ behavior: 'auto' });
        dispatch(tabsActions.setActiveTab({
            tabsName: INSURANCE_TABS_NAME,
            activePanelName: TABS[0].panelName,
        }));
        navigate(ROUTES.insurance);
        dispatch(offerModalClose());
    };
    useEffect(() => {
        const openedProductId = search.get(MODAL_PRODUCT_ID_QUERY_PARAM);
        if (!openedProductId) {
            dispatch(offerModalClose());
            return;
        }
        if (isLoadingCalculation) {
            dispatch(offerModalOpen({ state: 'loading' }));
            return;
        }
        const offer = offers.find(({ calculation }) => calculation.productId === Number(openedProductId));
        if (offer) {
            dispatch(offerModalOpen({ state: 'found', offer }));
        }
        else {
            dispatch(offerModalClose());
            dispatch(offerNotFoundModalOpen());
        }
    }, [search, isLoadingCalculation, offers]);
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: classNames(classes.root), children: [_jsx(CommissionButton, { nonDesktopOnly: true }), isLoadingCalculation ? (_jsx(OffersSkeleton, {})) : (_jsxs(_Fragment, { children: [_jsx(Offers, { handleAddToBasket: handleAddToBasket, offers: matchedOffers, matchedType: "full" }), _jsx(Offers, { handleAddToBasket: handleAddToBasket, offers: partiallyOffers, matchedType: "partial" }), _jsx(AbroadlMoreOffersDateInfo, { offersAmount: matchedOffers.length + partiallyOffers.length }), _jsx(Offers, { offers: missingOffers, matchedType: "none" })] })), _jsx(ComparingButtonOffers, {}), _jsx(OfferModal, { handleAddToBasket: handleAddToBasket }), _jsx(OfferNotFoundModal, {}), _jsx(ComparingModal, { handleAddToBasket: handleAddToBasket })] }), _jsxs(Button, { onClick: () => scrollToTop({ behavior: 'auto' }), appearance: "mobile-filters-show", component: Link, to: { search: queryString }, passAppearancesToComponent: false, children: [_jsx(SettingsIcon, { className: classes.icon }), "\u0424\u0438\u043B\u044C\u0442\u0440\u044B \u043F\u043E\u0438\u0441\u043A\u0430"] })] }));
};
export default memo(Showcase);
