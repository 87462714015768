import { createSelector } from '@reduxjs/toolkit';
import { MULTIPOLICY_SERVICE_NAME } from '@/libs/static';
export const apiSelector = (state) => state.api;
export const isSalesWorkingSelector = createSelector(apiSelector, ({ serviceStatus }) => serviceStatus.serviceStatus.isSalesWorking);
export const isAntiDosWorkingSelector = createSelector(apiSelector, ({ serviceStatus }) => serviceStatus.serviceStatus.isAntiDosWorking);
export const apiCountriesSelector = createSelector(apiSelector, ({ countries }) => countries);
export const apiIsApiDataLoadingSelector = createSelector(apiSelector, ({ isApiDataLoading }) => isApiDataLoading);
export const apiIsServiceStatusLoadingSelector = createSelector(apiSelector, ({ isServiceStatusLoading }) => isServiceStatusLoading);
export const apiWasNotifiedAboutTomorrowRatesSelector = createSelector(apiSelector, ({ wasNotifiedAboutTomorrowRates }) => wasNotifiedAboutTomorrowRates);
export const apiWasNotifiedAboutTomorrowMoreOffersSelector = createSelector(apiSelector, ({ wasNotifiedAboutTomorrowMoreOffers }) => wasNotifiedAboutTomorrowMoreOffers);
export const apiCompaniesSelector = createSelector(apiSelector, ({ companies }) => companies);
export const apiCountryGroupsSelector = createSelector(apiSelector, ({ countryGroups }) => countryGroups);
export const apiExchangeTodayRatesSelector = createSelector(apiSelector, ({ exchangeTodayRates }) => exchangeTodayRates);
export const apiServicesSelector = createSelector(apiSelector, ({ services }) => services);
export const apiSportsSelector = createSelector(apiSelector, ({ sports }) => sports);
export const apiMultipolicyValuesSelector = createSelector(apiServicesSelector, (services) => {
    return (services.find(({ code }) => code === MULTIPOLICY_SERVICE_NAME)
        ?.serviceValues || []);
});
export const apiAviaCargoServiceSelector = createSelector(apiServicesSelector, (services) => {
    return services.find(({ code }) => code === 'aviaCargo');
});
export const apiIsTomorrowCurrencyRateMoreThenToday = (currencyCode) => createSelector(apiSelector, ({ exchangeTodayRates = [], exchangeTomorrowRates = [] }) => {
    const _currencyCode = currencyCode.toUpperCase();
    if (![exchangeTodayRates, exchangeTomorrowRates].every((rates) => rates.length > 0)) {
        return false;
    }
    const todayRate = exchangeTodayRates.find(({ code }) => code === _currencyCode)?.rate;
    const tomorrowRate = exchangeTomorrowRates.find(({ code }) => code === _currencyCode)?.rate;
    if (!todayRate || !tomorrowRate)
        return false;
    return tomorrowRate > todayRate;
});
