import { createUseStyles } from 'react-jss';
import { DESKTOP_SEARCH_FORM_BREAKPOINT, } from '@/styles/themes/components';
export const useStyles = createUseStyles((theme) => ({
    wrapper: {
        position: 'sticky',
        transition: '0.3s',
        transitionProperty: 'top',
        zIndex: 2,
        width: '100%',
        background: theme.colors.header,
        padding: '10px 0px',
    },
    fields: {
        position: 'relative',
        zIndex: 1,
        filter: 'drop-shadow(0px 2px 7px rgba(0, 0, 0, 0.1))',
        display: 'grid',
        gridRowGap: '2px',
        gridColumnGap: '2px',
        gridTemplateColumns: 'repeat(2, 1fr)',
        gridTemplateRows: 'auto auto 36px 36px 62px',
        paddingTop: '2px',
        gridTemplateAreas: `
                "date-from     date-to"
                "tourists      tourists"
                "abroad        abroad"
                "foreign       foreign"
                "search-button search-button"
            `,
        [theme.breakpoints.up('sm')]: {
            gridTemplateColumns: 'repeat(2, 1fr)',
            gridTemplateRows: 'auto auto 36px 62px',
            gridTemplateAreas: `
                    "date-from     date-to"
                    "tourists      tourists"
                    "abroad        foreign"
                    "search-button search-button"
                `,
        },
        [theme.breakpoints.up('md')]: {
            gridTemplateColumns: 'repeat(4, 1fr)',
            gridTemplateRows: 'auto 36px 62px',
            gridTemplateAreas: `
                    "date-from     date-to       tourists      tourists"
                    "abroad        foreign       foreign       ."
                    "search-button search-button search-button search-button"
                `,
        },
        [theme.breakpoints.up(DESKTOP_SEARCH_FORM_BREAKPOINT)]: {
            paddingTop: '0px',
            gridTemplateColumns: '1fr 175px 175px 175px 147px;',
            gridTemplateRows: 'auto',
            gridTemplateAreas: `
                    "countries date-from date-to tourists search-button"
                    ".         abroad    foreign foreign  ."
                `,
        },
    },
    ages: {
        padding: '3px',
    },
    countries: {
        position: 'relative',
        zIndex: 2,
        gridArea: 'countries',
    },
    dateFrom: {
        gridArea: 'date-from',
    },
    dateTo: {
        gridArea: 'date-to',
    },
    tourists: {
        gridArea: 'tourists',
    },
    searchButton: {
        gridArea: 'search-button',
        marginTop: '10px',
        [theme.breakpoints.up(DESKTOP_SEARCH_FORM_BREAKPOINT)]: {
            marginTop: '0px',
            marginLeft: '10px',
        },
    },
    removeTouristIcon: {
        width: '36px',
        height: '36px',
        padding: '13px 13px',
    },
    touristsDropdownIcon: {
        transition: '0.2s',
        '&[data-is-open="true"]': {
            transform: 'rotate(180deg)',
        },
    },
    touristsSelector: {
        padding: '10px',
        display: 'flex',
        gap: '5px',
        flexDirection: 'column',
    },
}), { name: 'travel-form' });
