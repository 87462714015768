import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import { addDays, isAfter, parseDate } from '@che-ins-ui/datepicker';
import { ASSETS_SRC } from '@/libs/constants';
import { useCheTheme } from '@/styles/themes';
import { withAlpha } from '@/styles/utils';
import { requestSelector, requestServiceSelector, } from '@/models/request/selectors';
import { analytics } from '@/libs/analytics';
import { getStartOfDay } from '@/libs/dates';
import { useStyles } from './styles';
const MAX_DATE_TO_SHOW_INFO = addDays(getStartOfDay(), 3);
let eventSent = false;
let animationShown = false;
export const AbroadlMoreOffersDateInfo = memo(({ offersAmount }) => {
    const { abroad } = useSelector(requestServiceSelector);
    const { dateStart } = useSelector(requestSelector);
    const isWithinDateLimitToShow = dateStart && !isAfter(parseDate(dateStart), MAX_DATE_TO_SHOW_INFO);
    const isAbroad = Boolean(abroad);
    const shouldShow = isAbroad && offersAmount < 3 && isWithinDateLimitToShow;
    useEffect(() => {
        if (shouldShow && !eventSent) {
            analytics.sendEvent({
                action: 'vzr_abroad_increase_offers_by_later_date',
                label: 'Действие пользователя',
                category: 'Cherehapa',
            });
            eventSent = true;
            animationShown = true;
        }
    }, [shouldShow, eventSent]);
    if (!shouldShow) {
        return null;
    }
    return _jsx(Component, {});
});
const Component = () => {
    const classes = useStyles();
    const theme = useCheTheme();
    const borderColor = theme.colors.brand;
    return (_jsxs(motion.div, { animate: {
            borderColor: [
                withAlpha(borderColor, 0.4),
                withAlpha(borderColor, 0.7),
                withAlpha(borderColor, 0.4),
            ],
        }, transition: { duration: 0.4, delay: 0.15 }, className: classes.root, children: [_jsx(motion.img, { animate: { rotate: [0, 5, -5, 4, 0], y: [0, -5, -2, 0] }, transition: { delay: 0.3, duration: animationShown ? 0 : 0.4 }, src: `${ASSETS_SRC.IMAGES}/calendar-icon.png`, alt: "", className: classes.image }), _jsxs("div", { children: [_jsx("h5", { className: classes.title, children: "\u041D\u0443\u0436\u043D\u043E \u0431\u043E\u043B\u044C\u0448\u0435 \u0432\u0430\u0440\u0438\u0430\u043D\u0442\u043E\u0432 \u0441\u0442\u0440\u0430\u0445\u043E\u0432\u043E\u043A?" }), _jsx("p", { className: classes.text, children: "\u041A\u0430\u043A \u043F\u0440\u0430\u0432\u0438\u043B\u043E, \u043A\u043E\u043C\u043F\u0430\u043D\u0438\u0438 \u0441\u0442\u0440\u0430\u0445\u0443\u044E\u0442 \u0442\u0443\u0440\u0438\u0441\u0442\u043E\u0432, \u043A\u043E\u0442\u043E\u0440\u044B\u0435 \u0443\u0436\u0435 \u0437\u0430 \u0440\u0443\u0431\u0435\u0436\u043E\u043C, \u0441 \u043E\u0442\u0441\u0440\u043E\u0447\u043A\u043E\u0439 \u043D\u0430\u0447\u0430\u043B\u0430 \u0441\u0442\u0440\u0430\u0445\u043E\u0432\u0430\u043D\u0438\u044F \u0432 4-6 \u0434\u043D\u0435\u0439 \u043E\u0442 \u0434\u0430\u0442\u044B \u043F\u043E\u043A\u0443\u043F\u043A\u0438" })] })] }));
};
