import { createUseStyles } from 'react-jss';
import { isIframe } from '@/libs/helpers';
export const useStyles = createUseStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        gap: '15px',
        marginBottom: '15px',
        contain: 'paint',
        [theme.breakpoints.up('md')]: {
            marginBottom: '25px',
        },
        [theme.breakpoints.up('lg')]: {
            margin: `-20px -20px -${isIframe() ? 20 : 40}px`,
            padding: 20,
        },
    },
    icon: {
        marginRight: 8,
    },
}), { name: 'showcase' });
