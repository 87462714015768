import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@che-ins-ui/button';
import { CURRENCIES_BADGES } from '@/libs/constants';
import { basketProductIsMissingSelector, basketProductSelector, } from '@/models/basket/selectors';
import { formatPrice } from '@/libs/helpers/utils';
import { upsellChosenSelector, upsellFinalPriceSelector, upsellIsLoadingSelector, upsellOffersSelector, } from '@/models/upsell/selectors';
import { upsellActions } from '@/models/upsell';
import { formatCardPriceInRub, getNextMedicineSum, } from '@/models/upsell/helpers';
import { isOrderCreatingSelector, processActions } from '@/models/process';
import { EmailConfirmationStatuses } from '@/models/process/consts';
import { sendConfirmationCode } from '@/models/process/sendConfirmationCode';
import { emailConfirmationModalOpen } from '@/components/common/EmailConfirmationModal';
import { isAntiDosWorkingSelector } from '@/models/api/selectors';
import { createOrder } from '@/models/process/createOrder';
import { scrollToTop } from '@/libs/helpers';
import { requestCurrencySelector } from '@/models/request/selectors';
import { roleSelector } from '@/models/user/selectors';
import { AdminPartnerIdEditBlock } from '@/components/common/AdminPartnerIdEditBlock/AdminPartnerIdEditBlock';
import { CircleLoader } from '@/components/common/CircleLoader/CircleLoader';
import { useCheTheme } from '@/styles/themes';
import { UpsellDetailsHints } from '../hints';
import { UserTermsBlock } from '../UserTermsBlock';
import { PaymentButtonTextSvg } from '../PaymentButtonTextSvg';
import { GoToCalculationsBtn } from '../GoToCalculationsBtn';
import { useStyles as useFormStyles } from '../styles';
import { useStyles } from './styles';
import { constructUpsellCards } from './helpers';
import { COMPANY_CODES_FOR_MOTO_UPSELL, UPSELL_CHE_PRICE_TITLE, UPSELL_CHECKED_PRICE_TITLE, UPSELL_INCLUDE_PRICE_TITLE, } from './consts';
import { UpsellCards } from './UpsellCards';
export const UpsellTab = () => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const theme = useCheTheme();
    const formClasses = useFormStyles();
    const isAntiDosWorking = useSelector(isAntiDosWorkingSelector);
    const basketProduct = useSelector(basketProductSelector);
    const isOrderCreating = useSelector(isOrderCreatingSelector);
    const isLoading = useSelector(upsellIsLoadingSelector);
    const upsellOffers = useSelector(upsellOffersSelector);
    const chosen = useSelector(upsellChosenSelector);
    const finalPrice = useSelector(upsellFinalPriceSelector);
    const requestCurrency = useSelector(requestCurrencySelector);
    const productIsMissing = useSelector(basketProductIsMissingSelector);
    const role = useSelector(roleSelector);
    const paymentButtonRef = useRef(null);
    const { calculation: { priceRub: basketPrice, serviceProduct: { medicine: basketMedicine }, }, company: { code: companyCode }, } = basketProduct;
    const nextMedicine = upsellOffers.find((offer) => offer.medicine > basketMedicine)?.medicine;
    const chosenMedicine = chosen?.medicine || basketMedicine;
    const chosenActiveRest = Boolean(chosen?.activeRest);
    const chosenMoto = Boolean(chosen?.moto);
    const isChosenMedicineEqualBasket = chosenMedicine === basketMedicine;
    const isChosenMedicineMoreBasket = chosenMedicine > basketMedicine;
    const hasMedicineUpsell = Boolean(upsellOffers?.find((offer) => offer.medicine > basketMedicine));
    const hasActiveRestUpsell = Boolean(upsellOffers?.find((offer) => offer.activeRest));
    const hasMotoUpsell = Boolean(upsellOffers?.find((offer) => offer.moto));
    const isCheckedMedicine = isChosenMedicineMoreBasket;
    const isCheckedActiveRest = chosenActiveRest;
    const isCheckedMoto = chosenMoto;
    const isShowMedicine = hasMedicineUpsell;
    const isShowActiveRest = hasActiveRestUpsell;
    const isShowMoto = hasMotoUpsell;
    const isIncludedActiveRest = companyCode === 'reso'
        ? Boolean(isCheckedMoto && !isCheckedActiveRest)
        : isCheckedMoto &&
            COMPANY_CODES_FOR_MOTO_UPSELL.includes(companyCode);
    const isIncludedMoto = isCheckedActiveRest &&
        COMPANY_CODES_FOR_MOTO_UPSELL.filter((code) => code !== 'reso').includes(companyCode);
    const toggleMedicine = useCallback(() => {
        const nextValue = isChosenMedicineEqualBasket
            ? nextMedicine
            : basketMedicine;
        dispatch(upsellActions.setChosen({
            type: 'medicine',
            value: nextValue,
        }));
    }, [isChosenMedicineEqualBasket, nextMedicine, basketMedicine]);
    const toggleActiveRest = useCallback(() => {
        dispatch(upsellActions.setChosen({
            type: 'activeRest',
            value: !chosenActiveRest,
        }));
    }, [chosenActiveRest]);
    const toggleMoto = useCallback(() => {
        dispatch(upsellActions.setChosen({
            type: 'moto',
            value: !chosenMoto,
        }));
    }, [chosenMoto]);
    const getPrice = useCallback((type, isChecked, isIncluded) => {
        if (!type) {
            return UPSELL_CHE_PRICE_TITLE;
        }
        if (isIncluded) {
            return UPSELL_INCLUDE_PRICE_TITLE;
        }
        if (isChecked) {
            return UPSELL_CHECKED_PRICE_TITLE;
        }
        if (type === 'activeRest') {
            const nextOffer = upsellOffers.find((offer) => offer.activeRest === true &&
                offer.moto === chosenMoto &&
                offer.medicine === chosenMedicine);
            const nextPrice = nextOffer.price;
            return formatCardPriceInRub(nextPrice - finalPrice);
        }
        if (type === 'moto') {
            const nextOffer = upsellOffers.find((offer) => offer.activeRest === chosenActiveRest &&
                offer.moto === true &&
                offer.medicine === chosenMedicine);
            const nextPrice = nextOffer.price;
            return formatCardPriceInRub(nextPrice - finalPrice);
        }
        if (type === 'medicine') {
            const nextOffer = upsellOffers.find((offer) => offer.activeRest === chosenActiveRest &&
                offer.moto === chosenMoto &&
                offer.medicine === nextMedicine);
            const nextPrice = nextOffer.price;
            return formatCardPriceInRub(nextPrice - finalPrice);
        }
        return `error ${CURRENCIES_BADGES.rub}`;
    }, [
        upsellOffers,
        finalPrice,
        chosenMedicine,
        chosenActiveRest,
        chosenMoto,
        nextMedicine,
    ]);
    const getCompensationText = useCallback((type) => {
        const getCompensationAmount = (type) => {
            switch (type) {
                case 'activeRest':
                case 'moto': {
                    return chosenMedicine ?? basketMedicine;
                }
                case 'medicine': {
                    return getNextMedicineSum(basketMedicine);
                }
                default: {
                    const exhaustiveCheck = type;
                    throw new Error(exhaustiveCheck);
                }
            }
        };
        return (_jsxs(_Fragment, { children: ['Компенсация ', !type ? (_jsx("b", { children: "\u0431\u0435\u0441\u043A\u043E\u043D\u0435\u0447\u043D\u0430" })) : (_jsxs(_Fragment, { children: ["\u0434\u043E", ' ', _jsxs("b", { children: [' ', `${formatPrice(getCompensationAmount(type))}`, ' ', `${CURRENCIES_BADGES[requestCurrency]} / чел.`] })] }))] }));
    }, [basketMedicine, chosenMedicine, requestCurrency]);
    const handleGoToPayment = useCallback(() => {
        paymentButtonRef?.current?.blur();
        if (isAntiDosWorking) {
            dispatch(processActions.setEmailConfirmationStatus(EmailConfirmationStatuses.none));
            dispatch(sendConfirmationCode());
            dispatch(emailConfirmationModalOpen());
        }
        else {
            dispatch(createOrder({}));
        }
    }, [isAntiDosWorking]);
    useEffect(() => scrollToTop(), []);
    useEffect(() => {
        let finalPrice = basketPrice;
        const currentOffer = upsellOffers.find((offer) => offer.activeRest === chosenActiveRest &&
            offer.moto === chosenMoto &&
            offer.medicine === chosenMedicine);
        if (currentOffer?.price) {
            finalPrice = currentOffer.price;
        }
        dispatch(upsellActions.setFinalPrice(finalPrice));
    }, [
        chosenMedicine,
        chosenActiveRest,
        chosenMoto,
        upsellOffers,
        basketPrice,
    ]);
    const constructedCards = useMemo(() => constructUpsellCards({
        basketMedicine,
        isCheckedMedicine,
        isCheckedActiveRest,
        isCheckedMoto,
        isShowMedicine,
        isShowActiveRest,
        isShowMoto,
        isIncludedActiveRest,
        isIncludedMoto,
        toggleMedicine,
        toggleActiveRest,
        toggleMoto,
        requestCurrency,
    }), [
        basketMedicine,
        isCheckedMedicine,
        isCheckedActiveRest,
        isCheckedMoto,
        isShowMedicine,
        isShowActiveRest,
        isShowMoto,
        isIncludedActiveRest,
        isIncludedMoto,
        toggleMedicine,
        toggleActiveRest,
        toggleMoto,
        requestCurrency,
    ]);
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: classes.wrapper, children: _jsx(UpsellCards, { cards: constructedCards, isLoading: isLoading, getCompensationText: getCompensationText, getPrice: getPrice }) }), _jsxs("div", { className: classes.buyBlock, children: [_jsx("div", { className: formClasses.buttons, children: productIsMissing ? (_jsx(GoToCalculationsBtn, {})) : (_jsxs(_Fragment, { children: [role === 'admin' && (_jsx(AdminPartnerIdEditBlock, { isFormStep: true })), _jsx(Button, { disabled: isLoading || isOrderCreating, onClick: handleGoToPayment, shouldFitContent: true, type: "submit", baseAppearance: "success", appearance: "continue", innerRef: paymentButtonRef, children: isOrderCreating ? (_jsx(CircleLoader, { color: theme.colors.white })) : (_jsxs(_Fragment, { children: [_jsx(PaymentButtonTextSvg, {}), _jsxs("b", { children: [formatPrice(finalPrice || basketPrice), ' ', "\u00A0", CURRENCIES_BADGES['rub']] })] })) })] })) }), _jsx("div", { className: classes.terms, children: _jsx(UserTermsBlock, {}) })] }), _jsx(UpsellDetailsHints, {})] }));
};
