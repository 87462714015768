import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMeasure } from 'react-use';
import { motion } from 'framer-motion';
import { ConnectedModal, modalActions, modalSelector } from '@che-ins-ui/modal';
import { comparingProductsSelector, comparingViewSelector, } from '@/models/comparing/selectors';
import { useCheTheme } from '@/styles/themes';
import { calculationIsLoadingSelector, matchedOffersSelector, } from '@/models/calculation/selectors';
import { Loader } from '../AppLoader';
import { COMPARING_MODAL_NAME } from './const';
import { ComparingHeader } from './ComparingHeader';
import { useStyles } from './styles';
import { ComparingBody } from './ComparingBody';
import { getMatchingOffersAmount } from './helpers';
export const ComparingModal = ({ handleAddToBasket, }) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const comparing = useSelector(comparingProductsSelector);
    const view = useSelector(comparingViewSelector);
    const matchedOffers = useSelector(matchedOffersSelector);
    const matchingOffersAmount = getMatchingOffersAmount(comparing, matchedOffers);
    const theme = useCheTheme();
    const [shouldShow, setShouldShow] = useState(false);
    const isOpen = useSelector(modalSelector(COMPARING_MODAL_NAME))?.isOpen;
    const isLoadingCalculation = useSelector(calculationIsLoadingSelector);
    useEffect(() => {
        const timeout = setTimeout(() => setShouldShow(isOpen), isOpen ? 75 : 300);
        return () => clearTimeout(timeout);
    }, [isOpen]);
    const closeModal = useCallback(() => {
        dispatch(modalActions.close({ name: COMPARING_MODAL_NAME }));
    }, []);
    const addToBasket = useCallback((offerId) => {
        handleAddToBasket(offerId);
        closeModal();
    }, [closeModal, handleAddToBasket]);
    useEffect(() => {
        if (matchingOffersAmount === 0) {
            closeModal();
        }
    }, [matchingOffersAmount]);
    const [ref, { height, width }] = useMeasure();
    const rootHeightAnimation = height ? { height } : {};
    const rootWidthAnimation = width ? { width } : {};
    const rootAnimation = {
        ...rootHeightAnimation,
        ...rootWidthAnimation,
    };
    return (_jsx(ConnectedModal, { name: COMPARING_MODAL_NAME, baseAppearance: "comparing", children: _jsx(motion.div, { initial: rootAnimation, animate: rootAnimation, transition: { duration: isOpen && !shouldShow ? 0 : 0.215 }, children: _jsx("div", { ref: ref, className: classes.root, "data-list-view": view === 'list', children: shouldShow && !isLoadingCalculation ? (_jsxs(_Fragment, { children: [_jsx(ComparingHeader, {}), _jsx(ComparingBody, { handleAddToBasket: addToBasket })] })) : (_jsx("div", { className: classes.loader, style: {
                        minWidth: 822,
                    }, children: _jsx(Loader, { loaderColor: theme.colors.brand }) })) }) }) }));
};
