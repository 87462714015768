import { FRANCHISE_SERVICE, MULTIPOLICY_SERVICE_NAME } from '../static';
const SERVICE_WITH_MULTI_VALUES = 2;
const withFranchiseService = (apiServices) => {
    const multipolicyIndex = apiServices.findIndex((service) => service.code === MULTIPOLICY_SERVICE_NAME);
    const indexForInsert = multipolicyIndex > -1 ? multipolicyIndex + 1 : apiServices.length - 1;
    apiServices.splice(indexForInsert, 0, FRANCHISE_SERVICE);
    return apiServices;
};
export const transformServices = (apiServices) => withFranchiseService(apiServices.map((apiService) => {
    if (apiService.serviceValues.length > SERVICE_WITH_MULTI_VALUES) {
        apiService.serviceValues = apiService.serviceValues.filter((serviceValue) => serviceValue > 1);
    }
    if (apiService.code === 'abroad') {
        apiService.name = 'Я уже за границей';
    }
    return apiService;
}));
