export const COMPARING_STATE_NAMESPACE = 'comparing';
export const comparingSortings = [
    'price:asc',
    'price:desc',
    'added',
    'services',
];
export const comparingSortingsNames = {
    'price:asc': 'Дешевле',
    'price:desc': 'Дороже',
    added: 'В порядке добавления',
    services: 'Количество опций',
};
export const comparingViews = ['table', 'list'];
export const comparingViewNames = {
    table: 'Табличный вид',
    list: 'Вид списком',
};
export const allComparingServices = {
    franchise: 'Франшиза',
    assistance: 'Ассистанс',
    foreign: 'Я не гражданин России',
    abroad: 'Я уже за границей',
    multipolicy: 'Годовой полис',
    medicine: 'Медицинское страхование',
    urgentStomatology: 'Экстренная стоматология',
    covid: 'Лечение Covid-19',
    observation: 'Обсервация при Covid-19',
    insuredReturn: 'Перелет после лечения',
    childrenReturn: 'Эвакуация детей домой',
    terrorAssistance: 'Террористические акты',
    disasterAssistance: 'Стихийные бедствия',
    chronicArresting: 'Хронические заболевания',
    allergyArresting: 'Аллергия',
    sunburnAssistance: 'Солнечные ожоги',
    alcoholAssistance: 'Алкогольное опьянение',
    pregnancy: 'Осложнение беременности',
    relativeIllReturn: 'Досрочное возвращение',
    thirdPartyTransportation: 'Визит родственника',
    denge: 'Лихорадка денге',
    document: 'Потеря документов',
    legal: 'Юридическая помощь',
    searchActivities: 'Эвакуация вертолетом',
    rescueActivities: 'Поисково-спасательные мероприятия',
    work: 'Работа на производстве',
    office: 'Работа в офисе',
    education: 'Учеба за границей',
    tripCancel: 'Отмена поездки',
    visaCancel: 'Отказ в визе',
    isolation: 'Карантин',
    aviaCargo: 'Страхование багажа',
    accident: 'Оплата несчастных случаев',
    civilLiability: 'Гражданская ответственность',
    regularDelay: 'Задержка рейса',
    auto: 'Поездка на личном автомобиле',
    delayCargo: 'Задержка багажа',
};
export const comparingServices = Object.keys(allComparingServices);
export const comparingServicesNames = Object.values(allComparingServices);
export const comparingSortingAnalyticEvents = {
    'price:asc': 'sorting_cheaper',
    'price:desc': 'sorting_more_expensive',
    added: 'sorting_addition_order',
    services: 'sorting_number_of_options',
};
const franchiseDescription = 'При наличии франшизы в полисе застрахованному туристу необходимо оплатить её самостоятельно. Оплата производится в клинике при обращении за медицинской помощью. Все остальные расходы на лечение (свыше размера франшизы) компенсирует страховая компания.';
const assistanceDescription = 'Сервисная компания, которая по поручению страховой компании организует экстренную медицинскую помощь за рубежом.';
export const comparingAdditionalDictionary = {
    franchise: franchiseDescription,
    [allComparingServices.franchise]: franchiseDescription,
    assistance: assistanceDescription,
    [allComparingServices.assistance]: assistanceDescription,
};
