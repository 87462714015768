import { CONTAINER_COMPONENTS_NAMES } from '@che-ins-ui/container';
import { HEADER_HEIGHT } from '@/components/common/Header/styles';
import { DESKTOP_SEARCH_FORM_BREAKPOINT, TABLET_SEARCH_FORM_BREAKPOINT, } from './consts';
export const getContainerTheme = (theme) => ({
    base: {
        [CONTAINER_COMPONENTS_NAMES.wrapper]: {
            padding: '0px 15px',
        },
    },
    page: {
        [CONTAINER_COMPONENTS_NAMES.wrapper]: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            gap: '30px',
            paddingTop: HEADER_HEIGHT,
            margin: '15px auto',
            [theme.breakpoints.up(TABLET_SEARCH_FORM_BREAKPOINT)]: {
                margin: '15px auto 25px auto',
            },
            [theme.breakpoints.up(DESKTOP_SEARCH_FORM_BREAKPOINT)]: {
                margin: '40px auto',
            },
        },
    },
    'white-label-search-form': {
        [CONTAINER_COMPONENTS_NAMES.wrapper]: {
            flex: 'initial',
        },
    },
    'page-in-frame': {
        [CONTAINER_COMPONENTS_NAMES.wrapper]: {
            paddingTop: '0px',
        },
    },
    faq: {
        [CONTAINER_COMPONENTS_NAMES.wrapper]: {
            maxWidth: 'calc(652px + 30px)',
            position: 'relative',
        },
    },
});
