import { createSlice } from '@reduxjs/toolkit';
import { FUNCTIONAL_STATE_NAMESPACE } from './consts';
const initialState = {
    shouldCloseTravelMobileFormOnScroll: false,
    isNepanInfoOpened: true,
    isRussiaInfoOpened: true,
    isIsraelInfoOpened: true,
    isLebanonInfoOpened: true,
};
const functionalSlice = createSlice({
    name: FUNCTIONAL_STATE_NAMESPACE,
    initialState,
    reducers: {
        setShouldCloseTravelMobileFormOnScroll(state, action) {
            state.shouldCloseTravelMobileFormOnScroll = action.payload;
        },
        setIsNepalInfoOpened(state, action) {
            state.isNepanInfoOpened = action.payload;
        },
        setIsRussiaInfoOpened(state, action) {
            state.isRussiaInfoOpened = action.payload;
        },
        setIsIsraelInfoOpened(state, action) {
            state.isIsraelInfoOpened = action.payload;
        },
        setIsLebanonInfoOpened(state, action) {
            state.isLebanonInfoOpened = action.payload;
        },
    },
});
export const functionalInitialState = initialState;
export const { actions: functionalActions, reducer: functionalReducer } = functionalSlice;
export { FUNCTIONAL_STATE_NAMESPACE };
export * from './consts';
