import { SELECT_COMPONENTS_NAMES } from '@che-ins-ui/select';
import { DEKSTOP_FONT_SIZES, DESKTOP_SIZES_BREAKPOINT, MOBILE_FONT_SIZES, SEARCH_INPUT_HEIGHT, } from './consts';
export const getSelectTheme = (theme) => ({
    base: {
        [SELECT_COMPONENTS_NAMES.wrapper]: {
            height: '56px',
            [theme.breakpoints.up(DESKTOP_SIZES_BREAKPOINT)]: {
                height: '48px',
            },
        },
        [SELECT_COMPONENTS_NAMES.groupHeader]: {
            fontSize: MOBILE_FONT_SIZES.small,
            backgroundColor: theme.colors.textBackground,
            color: theme.colors.textPrimary,
            [theme.breakpoints.up(DESKTOP_SIZES_BREAKPOINT)]: {
                fontSize: DEKSTOP_FONT_SIZES.small,
            },
        },
        [SELECT_COMPONENTS_NAMES.listItem]: {
            fontSize: MOBILE_FONT_SIZES.main,
            height: '34px',
            backgroundColor: theme.colors.cloud,
            color: theme.colors.sky,
            '&[data-active="true"]': {
                background: theme.colors.brandTertiary,
            },
            '&:hover': {
                [`& .che-select-${SELECT_COMPONENTS_NAMES.removeIconWrapper}`]: {
                    '& svg > path': {
                        fill: theme.colors.textPrimary,
                    },
                },
            },
            [theme.breakpoints.up(DESKTOP_SIZES_BREAKPOINT)]: {
                fontSize: DEKSTOP_FONT_SIZES.main,
                height: '30px',
            },
        },
        [SELECT_COMPONENTS_NAMES.dropdown]: {
            overflow: 'hidden',
            backgroundColor: theme.colors.cloud,
        },
        [SELECT_COMPONENTS_NAMES.removeIconWrapper]: {
            display: 'flex',
            alignItems: 'center',
            '& svg': {
                width: 12,
                height: 12,
                '& path': {
                    fill: theme.colors.textSecondary,
                    fillOpacity: 1,
                },
            },
        },
    },
    'white-label-filters': {
        [SELECT_COMPONENTS_NAMES.wrapper]: {
            height: SEARCH_INPUT_HEIGHT.mobile,
            [theme.breakpoints.up(DESKTOP_SIZES_BREAKPOINT)]: {
                height: SEARCH_INPUT_HEIGHT.desktop,
            },
        },
        [SELECT_COMPONENTS_NAMES.dropdown]: {
            transform: 'translateY(2px)',
        },
    },
    'header-filters': {
        [SELECT_COMPONENTS_NAMES.wrapper]: {
            height: SEARCH_INPUT_HEIGHT.mobile,
            [theme.breakpoints.up(DESKTOP_SIZES_BREAKPOINT)]: {
                height: SEARCH_INPUT_HEIGHT.desktop,
            },
        },
    },
    filters: {
        [SELECT_COMPONENTS_NAMES.wrapper]: {
            width: 'calc(100% - 24px)',
            marginLeft: '12px',
            marginBottom: '5px',
            marginTop: '5px',
        },
    },
});
