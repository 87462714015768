import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, ButtonGroup } from '@che-ins-ui/button';
import { Accordion } from '@che-ins-ui/accordion';
import { requestActions } from '@/models/request';
import { isVascoPartnerSelector, requestSelector, } from '@/models/request/selectors';
import { apiSelector } from '@/models/api/selectors';
import { CURRENCIES_BADGES, VASCO_PROHIBITED_MEDICINE_SERVICES, } from '@/libs/constants';
import { separatePriceBySpaces } from '@/libs/helpers/utils';
import { Hint } from '../common/Hint';
import FilterLayout from './FilterLayout';
import { useStyles } from './styles';
import { MedicineHintContent } from './MedicineHintContent';
import { FiltersList } from './FiltersList';
import { ChangeCurrencyButtons } from './ChangeCurrencyButtons';
import { formatMultiValueByCode } from './utils';
import { AccordionTitle } from './AccordionTitle';
const Header = () => {
    const classes = useStyles();
    const { services } = useSelector(apiSelector);
    const medicineCode = 'medicine';
    const memoizedMedicine = useMemo(() => {
        return (services?.find(({ code }) => code === medicineCode) ||
            {});
    }, [services]);
    return (_jsxs("div", { className: classes.medicineBlockHeader, children: [_jsxs("div", { children: [_jsx("span", { className: classes.blockTitle, children: memoizedMedicine.name }), _jsx("br", {}), _jsx(Hint, { id: "medicineInsuranceIncluded", label: "\u0427\u0442\u043E \u0432\u0445\u043E\u0434\u0438\u0442 \u0432 \u043B\u044E\u0431\u0443\u044E \u0441\u0442\u0440\u0430\u0445\u043E\u0432\u043A\u0443?", title: "\u0427\u0442\u043E \u0432\u0445\u043E\u0434\u0438\u0442 \u0432 \u043B\u044E\u0431\u0443\u044E \u0441\u0442\u0440\u0430\u0445\u043E\u0432\u043A\u0443?", actionType: "agree", withIcon: false, children: _jsx(MedicineHintContent, {}) })] }), _jsx(ChangeCurrencyButtons, {})] }));
};
const HealthInsuranceFilter = () => {
    const dispatch = useDispatch();
    const { services: apiServices, additionalServices } = useSelector(apiSelector);
    const { currency, services } = useSelector(requestSelector);
    const isVascoPartner = useSelector(isVascoPartnerSelector);
    const value = services['medicine'];
    const classes = useStyles();
    const medicineOptions = useMemo(() => {
        return apiServices.find(({ code }) => code === 'medicine')
            ?.serviceValues;
    }, [apiServices]) || [];
    const onChange = (newValue) => {
        newValue &&
            dispatch(requestActions.setServices({ medicine: newValue }));
    };
    const currencyBadge = CURRENCIES_BADGES[currency];
    return (_jsxs(FilterLayout, { childrenWrapperClassName: classes.medicineBlockWrapper, heading: _jsx(Header, {}), children: [_jsx(ButtonGroup, { appearance: "filters-medicine-options", value: value, onChange: onChange, children: medicineOptions
                    .filter((option) => !isVascoPartner ||
                    !VASCO_PROHIBITED_MEDICINE_SERVICES.includes(option))
                    .map((amount) => {
                    const formattedAmount = formatMultiValueByCode('medicine', separatePriceBySpaces(amount), { currencyBadge });
                    return (_jsx(Button, { value: amount, children: formattedAmount }, amount));
                }) }), _jsx(Accordion, { shouldFitContent: true, appearance: "filters", titleButtonProps: {
                    appearance: 'filters-accordion-title-button',
                }, title: _jsx(AccordionTitle, { servicesList: additionalServices, title: "\u0414\u043E\u043F\u043E\u043B\u043D\u0438\u0442\u0435\u043B\u044C\u043D\u044B\u0435 \u043E\u043F\u0446\u0438\u0438" }), children: _jsx(FiltersList, { list: additionalServices, withServiceInfoImage: true }) })] }));
};
export default HealthInsuranceFilter;
