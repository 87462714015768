import { TOOLTIP_COMPONENTS_NAMES } from '@che-ins-ui/tooltip';
import { withAlpha } from '@/styles/utils';
export const getTooltipTheme = (theme) => ({
    base: {
        [TOOLTIP_COMPONENTS_NAMES.body]: {
            backgroundColor: `${theme.colors.brand}!important`,
            '& svg path': {
                fill: theme.colors.brand,
            },
        },
    },
    compare: {
        [TOOLTIP_COMPONENTS_NAMES.body]: {
            backgroundColor: `${withAlpha(theme.colors.sky, 0.95)}!important`,
            color: `${theme.colors.cloud}!important`,
            '& svg path': {
                fill: theme.colors.sky,
                fillOpacity: 0.95,
            },
        },
    },
});
