import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, ButtonGroup } from '@che-ins-ui/button';
import { Accordion } from '@che-ins-ui/accordion';
import { requestActions } from '@/models/request';
import { isVascoPartnerSelector, requestSelector, } from '@/models/request/selectors';
import { apiSelector } from '@/models/api/selectors';
import { CURRENCIES_BADGES, VASCO_PROHIBITED_MEDICINE_SERVICES, } from '@/libs/constants';
import { separatePriceBySpaces } from '@/libs/helpers/utils';
import { searchResultsSelector } from '@/models/search/selectors';
import { mainServicesCodes, MEDICINE_CODE } from '@/libs/static';
import { MEDICINE_SUM_SEARCH_CODE } from '@/models/search/filtersSearchTags';
import { FilterLayout } from './FilterLayout';
import { useStyles } from './styles';
import { MedicineHintContent } from './MedicineHintContent';
import { FiltersList } from './FiltersList';
import { ChangeCurrencyButtons } from './ChangeCurrencyButtons';
import { formatMultiValueByCode } from './utils';
import { SearchBlockWrapper } from './SearchElementWrapper';
const Header = () => {
    const classes = useStyles();
    const { services } = useSelector(apiSelector);
    const searchResults = useSelector(searchResultsSelector);
    const memoizedMedicine = useMemo(() => {
        return (services?.find(({ code }) => code === MEDICINE_CODE) ||
            {});
    }, [services]);
    return (_jsxs("div", { children: [_jsxs("div", { className: classes.medicineHeaderTitle, children: [_jsx("span", { className: classes.blockTitle, children: memoizedMedicine.name }), _jsx(ChangeCurrencyButtons, {})] }), _jsx(Accordion, { titleButtonProps: {
                    appearance: 'filters-accordion-title-button',
                    'data-medicine': true,
                }, shouldFitContent: true, title: _jsx("span", { className: classes.accordionTitleText, children: "\u0427\u0442\u043E \u0432\u0445\u043E\u0434\u0438\u0442 \u0432 \u043B\u044E\u0431\u0443\u044E \u0441\u0442\u0440\u0430\u0445\u043E\u0432\u043A\u0443?" }), isOpen: Boolean(searchResults &&
                    searchResults.length == 1 &&
                    searchResults.includes(MEDICINE_CODE)), children: _jsx(MedicineHintContent, {}) })] }));
};
const groupCodes = [
    ...mainServicesCodes,
    MEDICINE_CODE,
    MEDICINE_SUM_SEARCH_CODE,
];
export const HealthInsuranceFilter = memo(() => {
    const dispatch = useDispatch();
    const { services: apiServices, mainServices } = useSelector(apiSelector);
    const { currency, services } = useSelector(requestSelector);
    const isVascoPartner = useSelector(isVascoPartnerSelector);
    const searchResults = useSelector(searchResultsSelector);
    const value = services[MEDICINE_CODE];
    const classes = useStyles();
    const medicineOptions = useMemo(() => {
        return apiServices.find(({ code }) => code === MEDICINE_CODE)
            ?.serviceValues;
    }, [apiServices]) || [];
    const onChange = (newValue) => {
        newValue &&
            dispatch(requestActions.setServices({ [MEDICINE_CODE]: newValue }));
    };
    const currencyBadge = CURRENCIES_BADGES[currency];
    return (_jsx(SearchBlockWrapper, { codes: groupCodes, searchResults: searchResults, children: _jsxs(FilterLayout, { childrenWrapperClassName: classes.medicineBlockWrapper, heading: _jsx(Header, {}), children: [_jsx(ButtonGroup, { appearance: "filters-medicine-options", value: value, onChange: onChange, children: medicineOptions
                        .filter((option) => !isVascoPartner ||
                        !VASCO_PROHIBITED_MEDICINE_SERVICES.includes(option))
                        .map((amount) => {
                        const formattedAmount = formatMultiValueByCode(MEDICINE_CODE, separatePriceBySpaces(amount), { currencyBadge });
                        return (_jsx(Button, { value: amount, children: formattedAmount }, amount));
                    }) }), _jsx(FiltersList, { list: mainServices, searchResults: searchResults, withServiceInfoImage: true })] }) }));
});
