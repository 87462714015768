import { CURRENCIES_BADGES } from '@/libs/constants';
import { declOfNum } from '@/libs/helpers';
import { getKeys, separatePriceBySpaces } from '@/libs/helpers/utils';
import { getTypes } from '@/libs/static';
import { allComparingServices, comparingAdditionalDictionary, comparingServices, comparingServicesNames, } from '@/models/comparing/consts';
const getCompanyInfo = (company) => {
    const { id, name, linkToLogo } = company;
    return { id, name, linkToLogo };
};
const formatePostfix = (serviceCode, value, currency) => {
    if (serviceCode === 'pregnancy') {
        return `До ${value} нед.`;
    }
    if (serviceCode === 'multipolicy') {
        if (typeof value === 'number') {
            return `${value} ${declOfNum(value, getTypes('DAY'))}`;
        }
        return 'Включено';
    }
    return separatePriceBySpaces(value) + ' ' + CURRENCIES_BADGES[currency];
};
const getComparingOffer = ({ companyId, companies, matchedOffers, servicesDictionary, currency, }) => {
    try {
        const company = companies.find((company) => company.id === companyId);
        if (!company) {
            return null;
        }
        const companyInfo = getCompanyInfo(company);
        const offer = [...matchedOffers].find((offer) => offer.company.id === companyId);
        if (!offer) {
            return { company: companyInfo };
        }
        const { priceRub, matchDetails, productId, serviceProduct, info, assistances, } = offer?.calculation;
        const { missingServices: missingServicesCount } = matchDetails;
        const isFullMatching = missingServicesCount === 0;
        const assistance = assistances[0]?.name || 'Неизвестно';
        const services = [
            {
                service: 'Ассистанс',
                postfix: assistance,
                description: comparingAdditionalDictionary.assistance,
            },
        ];
        const missingServicesCodes = offer?.missingServices?.map(({ code }) => ({
            service: servicesDictionary?.[code].name,
            description: servicesDictionary?.[code]?.description,
            postfix: 'Отсутствует',
        }));
        const missingServices = [
            ...missingServicesCodes,
        ].filter(({ service }) => service !== 'Без франшизы');
        const franchise = info?.medicine?.find(({ type }) => type === 'franchise')?.value;
        const limitations = [];
        for (const key in info) {
            for (const item of info[key]) {
                if (item.type === 'limitation') {
                    limitations.push(item.value);
                }
            }
        }
        const franchiseItem = {
            service: 'Франшиза',
            description: comparingAdditionalDictionary.franchise,
        };
        if (franchise) {
            missingServices.push({
                ...franchiseItem,
                postfix: franchise + ' ' + CURRENCIES_BADGES[currency],
            });
        }
        else {
            services.push({
                ...franchiseItem,
                postfix: 'Нет',
            });
        }
        getKeys(serviceProduct)
            .reduce((sortedArray, serviceCode) => {
            const index = comparingServices.indexOf(serviceCode);
            if (index !== -1 && serviceProduct[serviceCode] !== 0) {
                let insertionIndex = sortedArray.findIndex((item) => comparingServices.indexOf(item) > index);
                if (insertionIndex === -1) {
                    sortedArray.push(serviceCode);
                }
                else {
                    sortedArray.splice(insertionIndex, 0, serviceCode);
                }
            }
            return sortedArray;
        }, [])
            .forEach((serviceCode) => {
            const value = serviceProduct[serviceCode];
            const dictionary = servicesDictionary?.[serviceCode];
            const service = dictionary?.name || serviceCode;
            const description = dictionary?.description;
            const postfix = value !== 1
                ? formatePostfix(serviceCode, value, currency)
                : 'Включено';
            services.push({
                service,
                postfix,
                description,
            });
        });
        return {
            company: companyInfo,
            info: {
                price: priceRub,
                servicesCount: matchDetails.totalServices - 9,
                productId,
                services,
                missingServices,
                missingServicesCount,
                limitations,
            },
            matchedType: isFullMatching ? 'full' : 'partial',
        };
    }
    catch (error) {
        console.log(error);
        return null;
    }
};
export const getComparingOffers = ({ comparingIds, companies, matchedOffers, servicesDictionary, currency, }) => comparingIds.reduce((acc, id) => {
    const offer = getComparingOffer({
        companyId: id,
        companies,
        matchedOffers,
        servicesDictionary,
        currency,
    });
    if (offer) {
        if ('matchedType' in offer) {
            acc.matching.push(offer);
        }
        else {
            acc.missing.push(offer);
        }
    }
    return acc;
}, {
    matching: [],
    missing: [],
});
export const filterRepeatingServices = (offers) => {
    const checkedServicesItems = new Map();
    const checkedMissingServicesItems = new Map();
    const splitter = '%%';
    const checkServices = (offer, services, checkedItems, isService) => services?.map((serviceItem) => {
        const { service, postfix = '' } = serviceItem;
        const key = service + splitter + postfix;
        if (checkedItems.has(key)) {
            return checkedItems.get(key)
                ? { ...serviceItem, isRepeating: true }
                : serviceItem;
        }
        let isPresentInAll = true;
        for (const otherOffer of offers) {
            if (otherOffer !== offer) {
                const comparableServices = isService
                    ? otherOffer.info.services
                    : otherOffer.info.missingServices;
                const isFound = comparableServices.some(({ service, postfix }) => {
                    const currentKey = service + splitter + (postfix || '');
                    return currentKey === key;
                });
                if (!isFound) {
                    isPresentInAll = false;
                    break;
                }
            }
        }
        checkedItems.set(key, isPresentInAll);
        return isPresentInAll
            ? { ...serviceItem, isRepeating: true }
            : serviceItem;
    });
    const areMultipleOffers = offers.length > 1;
    return {
        offers: areMultipleOffers
            ? offers.map((offer) => ({
                ...offer,
                info: {
                    ...offer.info,
                    services: checkServices(offer, offer.info.services, checkedServicesItems, true),
                    missingServices: checkServices(offer, offer.info.missingServices, checkedMissingServicesItems, false),
                },
            }))
            : offers,
        repeatingServices: areMultipleOffers
            ? [
                ...checkedServicesItems.entries(),
                ...checkedMissingServicesItems.entries(),
            ]
                .filter(([, value]) => value)
                .map(([key]) => key.split(splitter)[0])
            : [],
    };
};
export const getAllServices = (offers) => {
    const allKeys = offers.flatMap((offer) => [
        ...offer.info.services.map((serviceItem) => serviceItem.service),
        ...offer.info.missingServices.map((serviceItem) => serviceItem.service),
    ]);
    return Array.from(new Set(allKeys)).reduce((sortedArray, serviceName) => {
        const index = comparingServicesNames.indexOf(serviceName);
        if (index !== -1) {
            let insertionIndex = sortedArray.findIndex((item) => comparingServicesNames.indexOf(item) > index);
            if (insertionIndex === -1) {
                sortedArray.push(serviceName);
            }
            else {
                sortedArray.splice(insertionIndex, 0, serviceName);
            }
        }
        return sortedArray;
    }, []);
};
export const getMatchingOffersAmount = (comparingIds, offers) => offers?.filter((offer) => comparingIds.includes(offer.company.id)).length;
export const getMatchingOffersLogos = (comparingIds, offers) => comparingIds.reduce((acc, id) => {
    const offer = offers.find((offer) => offer.company.id === id);
    if (offer) {
        acc.unshift(offer.company.linkToLogo);
    }
    return acc;
}, []);
export const sortComparingOffers = (offers, sorting) => {
    switch (sorting) {
        case 'added':
            return offers;
        case 'services':
            return [...offers].sort((a, b) => b.info.servicesCount - a.info.servicesCount);
        case 'price:asc':
            return [...offers].sort((a, b) => a.info.price - b.info.price);
        case 'price:desc':
            return [...offers].sort((a, b) => b.info.price - a.info.price);
        default:
            const exhaustiveCheck = sorting;
            throw new Error(`Unhandled sorting type: ${exhaustiveCheck}`);
    }
};
export const getServicesDescription = (services, servicesDictionary) => {
    const nameToKey = {
        ...Object.keys(servicesDictionary).reduce((acc, key) => {
            acc[servicesDictionary[key].name] = key;
            return acc;
        }, {}),
        [allComparingServices.franchise]: 'franchise',
        [allComparingServices.assistance]: 'assistance',
    };
    return services.map((service) => {
        const key = nameToKey[service];
        const description = comparingAdditionalDictionary?.[key] ||
            servicesDictionary[key]?.description;
        if (key && description) {
            return {
                service,
                description,
            };
        }
        return { service };
    });
};
