import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { memo } from 'react';
import { useStyles } from './styles';
const FilterLayout = ({ heading, children, isFirst, isLast, childrenWrapperClassName, }) => {
    const classes = useStyles();
    const isHeadingString = typeof heading === 'string';
    return (_jsxs("div", { className: classNames(classes.blockWrapper, isLast && classes.lastBlockWrapper, isFirst && classes.firstBlockWrapper), children: [_jsx("div", { children: isHeadingString ? (_jsx("span", { className: classes.blockTitle, children: heading })) : (heading) }), _jsx("div", { className: classNames(childrenWrapperClassName, isLast && classes.lastBlockChildren), children: children })] }));
};
export default memo(FilterLayout);
