import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { motion } from 'framer-motion';
import { useStyles } from './styles';
export const MustReadInfoBlock = memo(({ isOpened, setIsOpened, icon, title, areAllItemsEnabled, children }) => {
    const classes = useStyles();
    return (_jsxs("div", { className: classes.root, children: [_jsxs("div", { className: classes.titleButton, onClick: () => {
                    setIsOpened(!isOpened);
                }, role: "button", children: [_jsx("div", { className: classes.logo, "data-all-enabled": areAllItemsEnabled, children: icon }), _jsx("h3", { children: title }), _jsx("button", { className: classes.dropdownIcon, children: _jsx(motion.svg, { variants: { main: { rotate: isOpened ? 180 : 0 } }, initial: "main", animate: "main", transition: { type: 'just', duration: 0.2 }, width: "14", height: (14 / 12) * 7, viewBox: "0 0 12 7", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { d: "M0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L6 4.58579L10.2929 0.292893C10.6834 -0.0976311 11.3166 -0.0976311 11.7071 0.292893C12.0976 0.683417 12.0976 1.31658 11.7071 1.70711L6.70711 6.70711C6.31658 7.09763 5.68342 7.09763 5.29289 6.70711L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893Z" }) }) })] }), _jsx(motion.div, { variants: {
                    main: isOpened
                        ? { height: 'auto', y: 0, opacity: 1 }
                        : { height: 0, y: -8, opacity: 0.6 },
                }, transition: { type: 'just', duration: 0.2 }, initial: "main", animate: "main", style: { overflow: 'hidden' }, children: children })] }));
});
