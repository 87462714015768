import { getModifiedSports } from '@/models/process/utils';
import { getDateStartAndInsuranceDays } from '../helpers/utils';
export function transformUpsell({ request, tourists, nextActiveRest, nextMedicineSum, nextMoto, }) {
    const services = { ...request.services };
    const { dateStart, insuredDays } = getDateStartAndInsuranceDays({
        dateStart: request.dateStart,
        dateEnd: request.dateEnd,
        services,
    });
    if (nextMedicineSum) {
        services.medicine = nextMedicineSum;
    }
    return {
        dateStart,
        insuredDays,
        currency: request.currency.toUpperCase(),
        services,
        tourists,
        countries: request.countries,
        countryGroups: request.countryGroups,
        sports: getModifiedSports({
            currentSports: request.sports,
            withActiveRest: nextActiveRest,
            withMoto: nextMoto,
        }),
        assistances: request.assistances,
    };
}
