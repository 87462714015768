import { createUseStyles } from 'react-jss';
import { mixins } from '@/styles/mixins';
import { DEKSTOP_FONT_SIZES, GLOBAL_LETTER_SPACING, } from '@/styles/themes/components';
import { withAlpha } from '@/styles/utils';
import { comparingTableAsideLeftOffset } from './ComparingTableAside';
const PADDING_BOTTOM = 35;
export const useTableStyles = createUseStyles((theme) => ({
    offers: {
        padding: `0 15px ${PADDING_BOTTOM}px`,
        display: 'flex',
        gap: 6,
        width: 'max-content',
    },
    aside: {
        zIndex: 2,
        position: 'sticky',
        overflow: 'hidden',
        left: comparingTableAsideLeftOffset,
        flexShrink: 0,
        display: 'flex',
        flexDirection: 'column',
        padding: 'calc(112.5px + 10px) 3px 0 10px',
        margin: '-10px -4px 0 -10px',
        backgroundColor: withAlpha(theme.colors.cloud, 0.75),
        backdropFilter: 'blur(7px)',
    },
    asideObservedElement: {
        position: 'absolute',
        width: 4,
        height: '100%',
    },
    asideLimitations: {
        width: '100%',
        height: 'auto',
        paddingTop: 9,
        display: 'flex',
        justifyContent: 'flex-end',
        '& div': {
            width: 'fit-content',
        },
    },
    asideLimitationsIcon: {
        flexShrink: 0,
        height: 16,
        ...mixins.centeredContent(),
        width: '16px !important',
        border: `1px solid ${theme.colors.warning}`,
        borderRadius: 999,
        transform: 'translate(4px, 4px)',
        '& svg': {
            width: 11,
            height: 11,
        },
    },
    offer: {
        flexShrink: 0,
        minWidth: 240,
        maxWidth: 270,
        backgroundColor: theme.colors.cloud,
        '&[data-expanded="true"]': {
            minWidth: 340,
            maxWidth: 340,
        },
    },
    offerHeader: {
        display: 'flex',
        gap: 10,
        justifyContent: 'center',
    },
    offerTitles: {
        flexGrow: 0,
    },
    offerButtons: {
        height: 28,
        justifyContent: 'center',
        margin: '12px 0px 2px',
        '&[data-moving-available="true"]': {
            justifyContent: 'space-between',
        },
    },
    offerPriceWrapper: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        marginBottom: 8,
    },
    offerHeaderWrapper: {
        paddingInline: 15,
    },
    offerLimitations: {
        padding: '13px 5px 0',
        display: 'flex',
        flexDirection: 'column',
        gap: 6,
        fontSize: DEKSTOP_FONT_SIZES.main,
        letterSpacing: '-0.5px',
        color: withAlpha(theme.colors.sky, 0.9),
        '& span': {
            width: '100%',
            textAlign: 'center',
        },
        '& p': {
            color: withAlpha(theme.colors.sky, 0.9),
        },
    },
    offerLimitationsDot: {
        position: 'relative',
        top: -1.5,
        marginRight: 5,
        fontSize: 10,
    },
    service: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        gap: 5,
        fontSize: DEKSTOP_FONT_SIZES.main,
        fontWeight: 400,
        letterSpacing: GLOBAL_LETTER_SPACING,
        color: withAlpha(theme.colors.sky, 0.9),
        justifyContent: 'flex-end',
        borderWidth: 1,
        borderStyle: 'solid',
        '&[data-centered="true"]': {
            justifyContent: 'center',
            fontWeight: 500,
        },
        '&[data-disadvantage="true"]': {
            color: theme.colors.error,
        },
    },
    bottomGradient: {
        width: '100%',
        background: `linear-gradient(180deg, 
                ${withAlpha(theme.colors.cloud, 0)} 0, 
                ${withAlpha(theme.colors.cloud, 0.5)} 33%, 
                ${theme.colors.cloud}
                )`,
        height: PADDING_BOTTOM + 5,
        position: 'fixed',
        bottom: 0,
        zIndex: 2,
        left: 0,
        pointerEvents: 'none',
    },
}), { name: 'comparing-table' });
