import { createSlice } from '@reduxjs/toolkit';
import { addDays, addYears, formatDate, getToday, isAfter, parseDate, } from '@che-ins-ui/datepicker';
import { checkIfCurrencyZoneAccessible, getInitialZoneCurrency, getZoneCurrency, } from '@/libs/helpers';
import { addToArrayIfNotExist, removeFromArray } from '@/libs/helpers/utils';
import { diffDates } from '@/libs/dates';
import { DEFAULT_PARTNER_ID } from '../user';
import { DATE_START_INTERVAL_FROM_TODAY, DEFAULT_MARKETING_PARAMS, REQUEST_STATE_NAMESPACE, SERVICE_DEFAULT, } from './consts';
export const todayPlus3Days = addDays(getToday(), DATE_START_INTERVAL_FROM_TODAY);
export const todayPlus1Day = addDays(getToday(), 1);
const initialState = {
    isPaytureInPay: false,
    isCurrencyInited: false,
    isFrame: 0,
    isIgnoreForm: false,
    isCheSupport: true,
    isLogo: true,
    isIgnoreUpsell: true,
    isShowExpiredCalculationModal: true,
    currency: 'eur',
    sports: [],
    services: SERVICE_DEFAULT,
    countries: [],
    countryGroups: ['schengen'],
    dateStart: formatDate(todayPlus3Days),
    dateEnd: formatDate(todayPlus3Days),
    assistances: [],
    ...DEFAULT_MARKETING_PARAMS,
};
const requestSlice = createSlice({
    name: REQUEST_STATE_NAMESPACE,
    initialState,
    reducers: {
        init: {
            reducer: (state, { payload }) => {
                Object.entries(payload).forEach(([key, value]) => {
                    state[key] = value;
                });
            },
            prepare: (payload, meta) => {
                return {
                    payload,
                    meta,
                };
            },
        },
        setEmptyFieldsToDefault: (state) => {
            if (!state.countries.length && !state.countryGroups.length) {
                state.countries = initialState.countries;
                state.countryGroups = initialState.countryGroups;
            }
            if (state.dateStart && !state.dateEnd) {
                state.dateEnd = state.dateStart;
            }
            if (state.dateEnd && !state.dateStart) {
                state.dateStart = state.dateEnd;
            }
            if (!state.dateStart && !state.dateEnd) {
                state.dateStart = initialState.dateStart;
                state.dateEnd = initialState.dateEnd;
            }
        },
        initializeCurrency: {
            reducer: (state) => {
                state.currency = getInitialZoneCurrency();
                state.isCurrencyInited = true;
            },
            prepare: (payload, meta) => {
                return {
                    payload,
                    meta,
                };
            },
        },
        changeCurrency(state, { payload: { apiCountries, apiCountryGroups, newCurrency }, }) {
            if (checkIfCurrencyZoneAccessible(apiCountries, apiCountryGroups, state.countries, state.countryGroups, newCurrency)) {
                state.currency = newCurrency;
            }
        },
        setServices(state, action) {
            state.services = Object.entries({
                ...state.services,
                ...action.payload,
            }).reduce((acc, [code, value]) => {
                const needWrite = value || code in SERVICE_DEFAULT;
                return needWrite ? { ...acc, [code]: value } : acc;
            }, {});
        },
        setAssistance: (state, action) => {
            state.assistances = action.payload;
        },
        setSports: (state, action) => {
            state.sports = action.payload;
        },
        setDefault(state) {
            state.sports = initialState.sports;
            state.services = initialState.services;
            state.assistances = initialState.assistances;
        },
        setCountries: (state, { payload }) => {
            const { countries, countryGroups, type, optionValue, apiCountries, apiCountryGroups, } = payload;
            state.countries = countries;
            state.countryGroups = countryGroups;
            if (state.countries.includes('finland')) {
                state.countryGroups = removeFromArray(state.countryGroups, 'schengen');
            }
            switch (type) {
                case 'select':
                    switch (optionValue) {
                        case 'andorra':
                            state.countries = addToArrayIfNotExist(state.countries, 'spain');
                            break;
                        case 'greenland':
                        case 'faroe_islands':
                            state.countries = addToArrayIfNotExist(state.countries, 'denmark');
                            break;
                        default:
                            break;
                    }
                    break;
                case 'remove':
                    switch (optionValue) {
                        case 'spain':
                            state.countries = removeFromArray(state.countries, 'andorra');
                            break;
                        case 'denmark':
                            state.countries = removeFromArray(state.countries, [
                                'greenland',
                                'faroe_islands',
                            ]);
                            break;
                        default:
                            break;
                    }
            }
            state.currency = getZoneCurrency(apiCountries, apiCountryGroups, state.countries, state.countryGroups, state.currency);
        },
        setDateStart(state, { payload }) {
            if (!payload) {
                state.dateStart = payload;
            }
            else {
                const dateEnd = state.dateEnd && parseDate(state.dateEnd);
                const dateStart = parseDate(payload);
                if (dateEnd && isAfter(dateStart, dateEnd)) {
                    state.dateEnd = payload;
                }
                if (dateEnd && diffDates(dateStart, dateEnd, 'days') > 365) {
                    state.dateEnd = formatDate(addDays(addYears(dateStart, 1), -1));
                }
                state.dateStart = payload;
            }
        },
        setDateEnd(state, action) {
            state.dateEnd = action.payload;
        },
        resetSrcsale(state) {
            state.srcsale = null;
        },
        setPartnerId(state, action) {
            const { payload } = action;
            state.partnerId = payload === DEFAULT_PARTNER_ID ? null : payload;
        },
    },
});
export const requestInitialState = initialState;
export const { actions: requestActions, reducer: requestReducer } = requestSlice;
export { REQUEST_STATE_NAMESPACE };
export * from './consts';
