import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { AlertIcon } from '@che-ins-ui/icons/AlertIcon';
import { CheckIcon } from '@che-ins-ui/icons/CheckIcon';
import { useCheTheme } from '@/styles/themes';
import { requestSelector } from '@/models/request/selectors';
import { CURRENCIES_BADGES } from '../constants';
export const useCalculationFranhcise = (calculation) => {
    const theme = useCheTheme();
    const { currency } = useSelector(requestSelector);
    return useMemo(() => {
        const value = calculation.info?.medicine?.find(({ type }) => type === 'franchise')?.value;
        const FranchiseIcon = Boolean(value) ? (_jsx(AlertIcon, { className: "alert" })) : (_jsx(CheckIcon, { fill: theme.colors.success }));
        return {
            value: value ? `${value}${CURRENCIES_BADGES[currency]}` : 'нет',
            FranchiseIcon,
        };
    }, [calculation, theme.colors.success, currency]);
};
