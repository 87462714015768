import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { memo } from 'react';
import { useSelector } from 'react-redux';
import { getTypes } from '@/libs/static';
import { declOfNum, getRestrictions } from '@/libs/helpers';
import ServiceList from '@/components/Services/components/serviceList';
import { basketProductSelector } from '@/models/basket/selectors';
import { requestServiceSelector, requestSportsSelector, } from '@/models/request/selectors';
import { ASSETS_SRC } from '@/libs/constants';
import { useStyles } from './styles';
import { SignalIcon } from './components/SignalIcon';
export const Services = memo(({ isDesktopView }) => {
    const product = useSelector(basketProductSelector);
    const services = useSelector(requestServiceSelector);
    const sports = useSelector(requestSportsSelector);
    const classes = useStyles();
    if (!product)
        return null;
    const servicesNumber = product.calculation.matchDetails.totalServices - 9;
    const restrictions = getRestrictions(product.calculation);
    return (_jsxs("div", { className: classNames(classes.root), children: [_jsxs("div", { className: classes.companyShortDescription, children: [_jsx("div", { className: classes.companyLogo, children: _jsx("img", { src: product.company.linkToLogo, alt: "logo", className: classes.companyLogo }) }), _jsxs("div", { className: classes.companyNameBlock, children: [_jsx("div", { className: classes.companyName, children: product.company.name }), _jsx("div", { className: classes.companyNumberServices, children: `${servicesNumber} ${declOfNum(servicesNumber, getTypes('SERVICE'))}` })] })] }), _jsx(ServiceList, { services: services, product: product, sports: sports, isDesktopView: isDesktopView, withTitle: true }), restrictions.length > 0 && (_jsxs("div", { className: classes.restrictionsWrapper, children: [_jsxs("div", { className: classes.restrictionsTitle, children: [_jsx("img", { className: classes.warningIcon, src: `${ASSETS_SRC.IMAGES}/warning-icon.png`, alt: "warning" }), _jsx("span", { children: "\u041E\u0431\u0440\u0430\u0442\u0438\u0442\u0435 \u0432\u043D\u0438\u043C\u0430\u043D\u0438\u0435!" })] }), _jsx("ul", { className: classes.restrictionsList, children: restrictions.map((text, idx) => (_jsxs("li", { className: classes.restrictionsItem, children: [_jsx("div", { className: classes.signalIconWrapper, children: _jsx(SignalIcon, {}) }), _jsx("span", { className: classes.restrictionText, children: text })] }, idx))) })] }))] }));
});
