import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import { Accordion } from '@che-ins-ui/accordion';
import { CheckboxGroup } from '@/components/common/checkbox';
import { requestActions } from '@/models/request';
import { requestAssistancesSelector, requestSelector, } from '@/models/request/selectors';
import { apiSelector } from '@/models/api/selectors';
import { Hint } from '@/components/common/Hint';
import FilterLayout from './FilterLayout';
import { FiltersList } from './FiltersList';
import { useStyles } from './styles';
const AssistanceTitle = memo(() => {
    const selectedAssistances = useSelector(requestAssistancesSelector);
    const classes = useStyles();
    return (_jsxs("span", { className: classes.accordionTitleText, children: ["\u0412\u044B\u0431\u043E\u0440 \u0430\u0441\u0441\u0438\u0441\u0442\u0430\u043D\u0441\u0430", selectedAssistances.length ? (_jsxs("span", { className: classes.accordionServicesCount, children: ["(", selectedAssistances.length, ")"] })) : null, _jsx(Hint, { questionMarkTag: "span", id: "assistance", title: "\u0412\u044B\u0431\u043E\u0440 \u0430\u0441\u0441\u0438\u0441\u0442\u0430\u043D\u0441\u0430", actionType: "agree", withServiceInfoImage: true, children: "\u0412\u044B\u0431\u0435\u0440\u0438\u0442\u0435 \u0438\u0437 \u0441\u043F\u0438\u0441\u043A\u0430 \u0441\u0435\u0440\u0432\u0438\u0441\u043D\u0443\u044E \u043A\u043E\u043C\u043F\u0430\u043D\u0438\u044E (\u0430\u0441\u0441\u0438\u0441\u0442\u0430\u043D\u0441), \u043A\u043E\u0442\u043E\u0440\u0443\u044E \u0432\u044B \u043F\u0440\u0435\u0434\u043F\u043E\u0447\u0438\u0442\u0430\u0435\u0442\u0435. \u042D\u0442\u043E \u043D\u0435 \u043E\u0431\u044F\u0437\u0430\u0442\u0435\u043B\u044C\u043D\u043E." })] }));
});
const PopularFilters = () => {
    const dispatch = useDispatch();
    const { assistances } = useSelector(requestSelector);
    const { popularServices, assistances: apiAssistances } = useSelector(apiSelector);
    const options = apiAssistances.map(({ code: value, name: label }) => ({
        label,
        value,
        appearance: 'filters',
    }));
    const onChangeAssistances = useCallback((value) => {
        dispatch(requestActions.setAssistance(value));
    }, []);
    return (_jsxs(FilterLayout, { heading: "\u041F\u043E\u043F\u0443\u043B\u044F\u0440\u043D\u044B\u0435 \u0444\u0438\u043B\u044C\u0442\u0440\u044B", children: [_jsx(FiltersList, { list: popularServices, withServiceInfoImage: true }), _jsx(Accordion, { appearance: "filters", titleButtonProps: {
                    appearance: 'filters-accordion-title-button',
                }, shouldFitContent: true, title: _jsx(AssistanceTitle, {}), children: _jsx(CheckboxGroup, { value: assistances, onChange: onChangeAssistances, options: options }) })] }));
};
export default memo(PopularFilters);
