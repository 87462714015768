import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useDispatch } from 'react-redux';
import { motion } from 'framer-motion';
import { memo, useState } from 'react';
import { IconsBlock } from '@/components/Showcase/components/IconsBlock';
import { getTypes } from '@/libs/static';
import { declOfNum } from '@/libs/helpers';
import { comparingActions } from '@/models/comparing';
import { ComparingListOfferServices } from './ComparingListOfferServices';
import { useListStyles } from './listStyles';
import { ComparingListOfferLimitations } from './ComparingListOfferLimitations';
import { ComparingPriceButton } from './ComparingPriceButton';
export const ComparingListOffer = memo(({ id, name, linkToLogo, price, servicesCount, productId, services, missingServices, missingServicesCount, repeatingServices, comparingClickedProducts, comparingHoveredProduct, limitations, addToBasket, }) => {
    const dispatch = useDispatch();
    const classes = useListStyles();
    const [shouldHighlightMissing, setShouldHighlightMissing] = useState(false);
    return (_jsxs(motion.div, { className: classes.offer, layout: "position", initial: { opacity: 0, scale: 0.6 }, animate: { opacity: 1, scale: 1 }, exit: { opacity: 0, scale: 0.6 }, onClick: () => {
            setTimeout(() => dispatch(comparingActions.resetClickedServices()));
        }, children: [_jsxs("div", { className: classes.offerHeader, children: [_jsx("img", { className: classes.offerImage, src: linkToLogo, alt: "" }), _jsxs("div", { className: classes.offerTitles, children: [_jsx("span", { className: classes.offerTitle, children: name }), _jsx("span", { className: classes.offerDescription, "data-warning": Boolean(missingServicesCount), onMouseEnter: () => {
                                    if (missingServicesCount) {
                                        setShouldHighlightMissing(true);
                                    }
                                }, onMouseLeave: () => setShouldHighlightMissing(false), children: missingServicesCount
                                    ? `Отсутствует ${missingServicesCount} ${declOfNum(missingServicesCount, getTypes('SERVICESMALL'))}`
                                    : `${servicesCount} опций` })] }), _jsxs("div", { className: classes.offerButtons, onClick: (e) => e.stopPropagation(), children: [Boolean(price) && (_jsx(ComparingPriceButton, { price: price, onClick: addToBasket })), _jsx(IconsBlock, { company: {
                                    id,
                                    name,
                                    linkToLogo,
                                }, noPaddingRight: true, productId: productId, checkedComparingTooltipLabel: "\u0423\u0431\u0440\u0430\u0442\u044C" })] })] }), _jsx(ComparingListOfferServices, { services: services, missingServices: missingServices, shouldHighlightMissing: Boolean(missingServicesCount) && shouldHighlightMissing, repeatingServices: repeatingServices, comparingClickedProducts: comparingClickedProducts, comparingHoveredProduct: comparingHoveredProduct }), _jsx(ComparingListOfferLimitations, { limitations: limitations })] }));
});
