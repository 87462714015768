import { createSelector } from '@reduxjs/toolkit';
import { COMPARING_STATE_NAMESPACE } from './consts';
export const comparingSelector = (state) => state[COMPARING_STATE_NAMESPACE];
export const comparingProductsSelector = createSelector(comparingSelector, ({ comparing }) => comparing);
export const comparingDifferencesOnlySelector = createSelector(comparingSelector, ({ isDifferencesOnly }) => isDifferencesOnly);
export const comparingSortingSelector = createSelector(comparingSelector, ({ sorting }) => sorting);
export const comparingClickedProductsSelector = createSelector(comparingSelector, ({ clickedProducts: clickedProduct }) => clickedProduct);
export const comparingHoveredProductSelector = createSelector(comparingSelector, ({ hoveredProduct }) => hoveredProduct);
export const comparingViewSelector = createSelector(comparingSelector, ({ view }) => view);
export const comparingWasAddingUsedSelector = createSelector(comparingSelector, ({ wasAddingUsed }) => wasAddingUsed);
